<section class="confirm-mat-dialog p-t--8 p-b--8 p-l--16 p-r--16">
  <div class="label-wrapper">
    <!-- <img [src]="'./assets/img/logo_blue.svg'"> -->

    <span class="confirm-label">{{
      "confirmationdialog." +
        data.translateCode +
        (".header.label" + (isPlural ? "plural" : ""))
        | translate
        | uppercase
    }}</span>
  </div>
  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="confirm-title" mat-dialog-title>
    {{
      "confirmationdialog." +
        data.translateCode +
        (".title.label" + (isPlural ? "plural" : "")) | translate
    }}
  </div>
  <div class="confirm-text" mat-dialog-content>
    {{
      "confirmationdialog." +
        data.translateCode +
        (".body.label" + (isPlural ? "plural" : "")) | translate
    }}
  </div>

  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="dialog-action-wrapper" mat-dialog-actions>
    <button
      class="w-h-default-button dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      (click)="cancel()"
      tabindex="-1"
      *ngIf="!isOk"
    >
      {{ "app.cancel.label" | translate | uppercase }}
    </button>

    <button
      color="accent"
      data-cy="confirm-btn"
      class="dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      data-cy="confirm-btn"
      (click)="confirm()"
      tabindex="-1"
    >
      {{
        customButton
          ? (customButton | translate | uppercase)
          : isOk
          ? ("app.okay.label" | translate | uppercase)
          : ("app.confirm.label" | translate | uppercase)
      }}
    </button>
  </div>
</section>
