import * as moment from 'moment';

// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

// ANGULAR TRANSLATE MODULE
import { TranslateService } from '@ngx-translate/core';

// API
import {
  FLOW_GATEWAY,
  IWHEquipmentEndpointConfigModel,
  WHDomEvent,
  WHDropdownItemDOM,
  WHEquipmentDataService,
  WHIconENUM
} from '@workheld/workheld-shared-lib';
import { FormReferenceService } from 'src/app/app-services-helper/form-reference.service';

// RxJS
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-mat-dialog-create-report-entry',
  templateUrl: './mat-dialog-create-report-entry.component.html',
  styleUrls: ['./mat-dialog-create-report-entry.component.scss']
})
export class MatDialogCreateReportEntryComponent implements OnInit {

  // VAR
  public reportHTML;
  public payloadFormGroup: FormGroup = new FormBuilder().group({
    equipmentId: [null, [Validators.required]],
    start: [null, [Validators.required]],
    end: [null, [Validators.required]],
    reportTitle: [null, [Validators.required]],
    reportType: [null, [Validators.required]],
  });

  // DATE VALUES
  public tempFormGroup = new FormGroup({
    startForm: new FormControl(),
    endForm: new FormControl()
  });

  public reportTypes: WHDropdownItemDOM[] = [
    {
      id: 'DEFECT_REPORT',
      name: this.translateService.instant('equipmentreport.ui.reporttype.defectreport'),
      top: ''
    } as any,
    {
      id: 'OVERALL_REPORT',
      name: this.translateService.instant('equipmentreport.ui.reporttype.overallreport'),
      top: ''
    } as any,
  ];
  public reportTypeFormControl: FormControl = new FormControl(null, [Validators.required]);

  // GET
  public get typeFormControl(): FormControl {
    return this.payloadFormGroup.controls['reportType'] as FormControl;
  }

  public get startDateFormControl(): string {
    return this.payloadFormGroup.controls['start'].value as string;
  }

  public get endDateFormControl(): string {
    return this.payloadFormGroup.controls['end'].value as string;
  }

  public get createInvalid() {
    return this.payloadFormGroup.invalid;
  }

  // STATE
  public saving: boolean = false;

  // SUBSCRIPTION
  private subscription: Subscription = new Subscription();
  private subscriptions: Subscription[] = [];

  // DOM
  @ViewChild('stepper') stepper: any;

  constructor(
    private equipmentDataService: WHEquipmentDataService,
    public matDialogRef: MatDialogRef<MatDialogCreateReportEntryComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {
      equipmentId: string
    },
    private formReferenceService: FormReferenceService,
  ) { }

  onDomChildEvent($event: WHDomEvent) {
    switch ($event.type) {

      case 'optionSelected':
        this.payloadFormGroup.controls['reportType'].patchValue($event.payload.id);
        this.reportTypeFormControl.patchValue($event.payload.name);
        return;

      case 'onResetValue':
        this.payloadFormGroup.controls['reportType'].reset(null, { emitEvent: false });
        this.reportTypeFormControl.reset();
        return;
    }
  }

  ngOnInit(): void {
    this.payloadFormGroup.controls['equipmentId'].patchValue(this.data.equipmentId);

    this.subscriptions.push(this.tempFormGroup.controls['startForm'].valueChanges.subscribe((value: any) => {
      if(value) {
        this.payloadFormGroup.controls['start'].setValue(moment(value).format(), {emitEvent: false});
      }
    }));

    this.subscriptions.push(this.tempFormGroup.controls['endForm'].valueChanges.subscribe((value: any) => {
      if(value) {
        this.payloadFormGroup.controls['end'].setValue(moment(value).format(), {emitEvent: false});
      }
    }));
  }

  public cancel() {
    if(this.payloadFormGroup.dirty) {
      const ref = this.formReferenceService.createDialog()
      this.subscriptions.push(
        ref.pipe(tap((canDiscard)=>{
          if(canDiscard) {
            this.matDialogRef.close();
          }
        })).subscribe()
        )
    }else {
        this.matDialogRef.close();
    }
  }

  onStepChange($event: StepperSelectionEvent) {
    // this.previewReport();
  }

  public previewReport() {
    const endpointConfig: IWHEquipmentEndpointConfigModel = {
      apiUrl: environment.apiUrl + FLOW_GATEWAY
    }
    const payload = this.payloadFormGroup.value;
    this.saving = true;
    this.subscription.add(this.equipmentDataService.reportPreview(endpointConfig, payload)
      .subscribe((resp: any) => {
        this.reportHTML = resp;
        setTimeout(() => {
          this.stepper.next();
          this.saving = false;
        }, 10);
      }));
  }

  public handleSave() {
    this.createReport();
  }

  public createReport() {
    const endpointConfig: IWHEquipmentEndpointConfigModel = {
      apiUrl: environment.apiUrl + FLOW_GATEWAY
    }
    const payloadParams = this.payloadFormGroup.value;
    this.saving = true;
    this.subscription.add(this.equipmentDataService.createReport(endpointConfig, payloadParams).subscribe((resp: any) => {
      this.formReferenceService.unsavedChanges = false
      this.matDialogRef.close(true);
      this.saving = false;
    }));
  }

  public get reportIcon(): string {
    return WHIconENUM.ReportIcon as string;
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  public get startForm(): string {
    return this.tempFormGroup.controls['startForm'].value as string;
  }

  public get endForm(): string {
    return this.tempFormGroup.controls['endForm'].value as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
