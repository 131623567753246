<!-- MATERIAL_CONSUMPTION Entry / entryTypeId = 5 -->
<div class="row-wrapper">
  <entry-header style="width: 100%" [entryDOM]="entryDOM"></entry-header>
</div>

<div class="row-wrapper entry-detail-row-wrapper">
  <span>
    <div class="html-overflow">
      <ng-bee-material-static-data
        class="entry-detail-material-static-data"
        [withLabel]="true"
        [materialDTO]="materialDTO"
        [nameLabel]="'entry.materialconsumption.model.material'"
      >
      </ng-bee-material-static-data>
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.materialconsumption.model.quantity" | translate }}
    </div>
    <div class="default-value">
      {{
        materialConsumptionEntryDOM?.quantity
          ? materialConsumptionEntryDOM?.quantity
          : "-"
      }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.materialconsumption.model.unit" | translate }}
    </div>
    <div class="default-value">
      {{ UNIT[materialConsumptionEntryDOM?.unit] }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "material.model.type" | translate }}
    </div>
    <div class="default-value">
      <app-w-h-chip-list
        style="width: 100%"
        [data]="[{ inventoryType: INVENTORY_TYPE[materialDTO?.inventoryType] }]"
        [tooltipKeys]="['inventoryType']"
        [matIcon]="'inventory_2'"
        [isStacked]="false"
        [titleKey]="'inventoryType'"
      ></app-w-h-chip-list>
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "equipment.model.serialnumber" | translate }}
    </div>
    <div class="default-value">
      {{
        materialConsumptionEntryDOM?.serialNumber
          ? materialConsumptionEntryDOM?.serialNumber
          : "-"
      }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "inventoryentry.model.name" | translate }}
    </div>
    <div class="default-value">
      {{
        materialConsumptionEntryDOM?.storageLocationName
          ? materialConsumptionEntryDOM?.storageLocationName
          : "-"
      }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "inventoryentry.model.storageBin" | translate }}
    </div>
    <div class="default-value">
      {{
        materialConsumptionEntryDOM?.storageBin
          ? materialConsumptionEntryDOM?.storageBin
          : "-"
      }}
    </div>
  </span>
</div>

<div>
  <div class="w-h-label-no-margine">
    {{ "entry.model.comment" | translate }}
  </div>
  <div>
    <mat-form-field whFlex>
      <textarea
        [disabled]="true"
        rows="2"
        matInput
        [value]="entryDOM?.comment ? entryDOM?.comment : ''"
      >
      </textarea>
    </mat-form-field>
  </div>
</div>

<ng-bee-entry-file-img-gallery
  [entryDOM]="entryDOM"
></ng-bee-entry-file-img-gallery>
