<div class="work-object-reject-mat-dialog">
    <div class="detail-card-header">
        <span whFlex class="w-h-header-icon-span">
            <i mat-card-avatar class="workheld-icons header-icon" [innerHtml]="cancelIcon"></i>
        </span>
        <span whFlex class="w-h-header-span">
            <div class="single-header">
                {{ 'workobject.ui.reject.header.label' | translate }}
            </div>
        </span>
    </div>
    <div class="work-object-reject-mat-dialog-body">
        <form [formGroup]="paylaodFormGroup">
            <div fxLayout fxLayoutAlign="left top" fxLayoutGap="20px">
                <div style="padding-top: 6px;">
                    <mat-form-field whFlex="90">
                        <mat-label>{{ 'material.model.reason' | translate }}</mat-label>
                        <mat-select formControlName="reason" required>
                            <mat-option *ngFor="let rejectReason of REJECT_REASON" [value]="(rejectReason?.translateKey | translate)">
                                {{ (rejectReason?.translateKey | translate) }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div>
                <mat-form-field whFlex>
                    <mat-label>{{ 'workobject.model.reject.comment' | translate }}</mat-label>
                    <textarea 
                        matInput 
                        [rows]="10" 
                        [required]="false"
                        [formControlName]="'comment'"></textarea>
                </mat-form-field>
            </div>
        </form>
        <div class="dialog-action-wrapper" mat-dialog-actions>
            
            <button
                class="w-h-default-button dialog-button mat-elevation-z2"
                mat-flat-button
                type="button"
                tabindex="-1"
                (click)="closeDialog()"
                [disabled]="saving">
                <i class="workheld-icons" [innerHTML]="cancelIcon"></i> 
                {{ ('app.cancel.label') | translate | uppercase }}
            </button>
            <button
                color="accent"
                class="dialog-button mat-elevation-z2"
                mat-flat-button
                type="button"
                tabindex="-1"
                [disabled]="isFormGroupInvalid || saving"
                (click)="handleSubmit()">
                <mat-spinner
                    style="display: inline-block;"
                    *ngIf="saving"
                    [diameter]="18">
                </mat-spinner>
                <i class="workheld-icons" *ngIf="!saving" [innerHTML]="saveIcon"></i> 
                <span>
                    {{ ('app.save.label') | translate | uppercase }}
                </span>
            </button>
        </div>
    </div>
</div>
