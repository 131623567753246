import * as moment from 'moment';

// ANGULAR
import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

// SERICES
import { NgFormBuilderHelperService } from 'src/app/app-services-helper/ng-form-builder-helper.service';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import { WHScheduleAllWorkStepPayload, WHIconENUM } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-mat-dialog-work-step-scheduler-entry',
  templateUrl: './mat-dialog-work-step-scheduler-entry.component.html',
  styleUrls: ['./mat-dialog-work-step-scheduler-entry.component.scss']
})
export class MatDialogWorkStepSchedulerEntryComponent implements OnInit, OnDestroy {

  // DATA VAR
  public scheduleFromGroup: FormGroup;

  // DATE VALUES
  public tempFormGroup = new FormGroup({
    dateSlotForm: new FormControl(),
    timeSlotForm: new FormControl(),
    enddateSlotForm: new FormControl(),
    endtimeSlotForm: new FormControl()
  });

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    private ngFormBuilderHelperService: NgFormBuilderHelperService,
    public matDialogRef: MatDialogRef<MatDialogWorkStepSchedulerEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { workOrderId: string }
  ) { }

  public ngOnInit(): void {
    this.scheduleFromGroup = this.ngFormBuilderHelperService.initScheduleDateFormGroup();
    this.scheduleFromGroup.controls['workOrderId'].patchValue(this.data.workOrderId);

    this.subscriptions.push(this.tempFormGroup.controls['dateSlotForm'].valueChanges.subscribe((value: string) => {
      let time = this.tempFormGroup.controls['timeSlotForm'].value;
      if (!time && value) {
        time = "00:00";
        this.tempFormGroup.controls['timeSlotForm'].patchValue(time);
      }
      const finalVersion = time ? moment(value).set({'hour': time.slice(0, 2), 'minute': time.slice(-2)}).format() : value ? moment(value).format() : null;
      this.scheduleFromGroup.controls['startDate'].patchValue(finalVersion);
    }));

    this.subscriptions.push(this.tempFormGroup.controls['timeSlotForm'].valueChanges.subscribe((value: any) => {
      const date = this.tempFormGroup.controls['dateSlotForm'].value;
      if(!date) {
        this.tempFormGroup.controls['dateSlotForm'].patchValue(moment().format())
      }
      const finalVersion = date ? moment(date).set({'hour': value.slice(0, 2), 'minute': value.slice(-2)}).format() : moment(date).format();
      this.scheduleFromGroup.controls['startDate'].patchValue(finalVersion);
    }));

    this.subscriptions.push(this.tempFormGroup.controls['enddateSlotForm'].valueChanges.subscribe((value: string) => {
      let time = this.tempFormGroup.controls['endtimeSlotForm'].value;
      if (!time && value) {
        time = "23:59";
        this.tempFormGroup.controls['endtimeSlotForm'].patchValue(time);
      }
      const finalVersion = time ? moment(value).set({'hour': time.slice(0, 2), 'minute': time.slice(-2)}).format() :
        value ? moment(value).format() : null;
      this.scheduleFromGroup.controls['endDate'].patchValue(finalVersion);
    }));

    this.subscriptions.push(this.tempFormGroup.controls['endtimeSlotForm'].valueChanges.subscribe((value: any) => {
      const date = this.tempFormGroup.controls['enddateSlotForm'].value;
      if(!date) {
        this.tempFormGroup.controls['enddateSlotForm'].patchValue(moment().format())
      }
      const finalVersion = date ? moment(date).set({'hour': value.slice(0, 2), 'minute': value.slice(-2)}).format() : moment(date).format();
      this.scheduleFromGroup.controls['endDate'].patchValue(finalVersion);
    }));
  }

  public handleSubmit(): void {
    const schedulePayload: WHScheduleAllWorkStepPayload = this.scheduleFromGroup.value;
    console.log('test',schedulePayload);

    this.matDialogRef.close(schedulePayload);
  }

  public closeDialog(): void {
    this.matDialogRef.close();
  }

  public resetDateField($event: any) {
    // console.log($event);
  }

  public toggleScheduleMode($event: MatSlideToggleChange) {
    // console.log($event);
    if ($event.checked) {
      this.scheduleFromGroup.controls['mode'].patchValue('ONLY_UNSCHEDULED');
    } else {
      this.scheduleFromGroup.controls['mode'].patchValue('ALL');
    }
  }

  public get startDateFormControl(): FormControl {
    return this.scheduleFromGroup.controls['startDate'] as FormControl;
  }

  public get endDateFormControl(): FormControl {
    return this.scheduleFromGroup.controls['endDate'] as FormControl;
  }

  public get calendarIcon(): string {
    return WHIconENUM.CalendarIcon as string;
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  public get dateSlotForm(): string {
    return this.tempFormGroup.controls['dateSlotForm'].value as string;
  }

  public get timeSlotForm(): string {
    return this.tempFormGroup.controls['timeSlotForm'].value as string;
  }

  public get enddateSlotForm(): string {
    return this.tempFormGroup.controls['enddateSlotForm'].value as string;
  }

  public get endtimeSlotForm(): string {
    return this.tempFormGroup.controls['endtimeSlotForm'].value as string;
  }

  public get dateSet(): boolean {
    return !!this.dateSlotForm || !!this.enddateSlotForm;
  }

  public ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
  }

}
