<div class="comment-mat-dialog">
  <div class="comment-mat-dialog-header flex--r-c-sb">
    <span style="align-self: center">
      {{ "checklistinstance.model.comment" | translate }}
    </span>

    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div>
    <mat-divider class="wh-dialog-divider"></mat-divider>
  </div>
  <div class="comment-mat-dialog-body">
    <mat-form-field whFlex>
      <textarea matInput [rows]="6" [value]="data?.comment" [disabled]="true">
      </textarea>
    </mat-form-field>
  </div>
</div>
