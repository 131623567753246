<div class="check-definition-create-edit-card">
  <form
    [formGroup]="checkDefinitionFormGroup"
    class="flex--r-c gap--12 flex--wrap"
  >
    <span class="check-icon-span">
      <div class="sort-position">
        {{ sortPosition }}
      </div>
    </span>
    <span class="flex-3">
      <mat-form-field>
        <mat-label>{{
          "checklistdefinition.check.model.description" | translate
        }}</mat-label>
        <textarea
          matInput
          [formControlName]="'description'"
          required
          [cdkTextareaAutosize]="isExpanded"
          rows="1"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </span>
    <span class="flex-3">
      <mat-form-field>
        <mat-label>{{
          "checklistdefinition.check.model.additionalinformation" | translate
        }}</mat-label>
        <textarea
          matInput
          [formControlName]="'additionalInformation'"
          [cdkTextareaAutosize]="isExpanded"
          rows="1"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </span>
    <span class="flex-2">
      <mat-form-field>
        <mat-label>{{
          "checklistdefinition.check.model.measurementtype" | translate
        }}</mat-label>
        <mat-select formControlName="measurementType" required>
          <mat-option
            *ngFor="let measurmentTypeOption of MEASUREMENT_TYPE"
            [value]="measurmentTypeOption.featureKey"
          >
            {{ measurmentTypeOption.translateKey | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <span class="flex-1">
      <mat-form-field>
        <mat-label>{{
          "checklistdefinition.check.model.resulttype" | translate
        }}</mat-label>
        <mat-select formControlName="checkResultTypeId" required>
          <mat-option
            *ngFor="let resultType of resultTypes"
            [value]="resultType"
          >
            {{
              "checklistdefinition.check.enum.resulttype." + resultType
                | translate
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <span class="flex-1">
      <mat-form-field>
        <mat-label>{{
          "checklistdefinition.check.model.unit" | translate
        }}</mat-label>
        <mat-select formControlName="unit">
          <mat-option
            *ngFor="let unitOption of UNIT"
            [value]="unitOption?.featureKey"
          >
            {{ unitOption?.translateKey | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <div class="mandatory-span flex-1">
        <mat-checkbox
          formControlName="mandatory"
          [labelPosition]="labelPosition"
        >
          {{ "checklistdefinition.check.model.mandatory" | translate }}
        </mat-checkbox>
    </div>
  </form>
</div>
