// HELPER
import {
  IWHFlowMetadataTranslationModel,
  updateObject,
  WHMetadataDataService,
} from '@workheld/workheld-shared-lib';

// ANGULAR
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';

// SERVICES
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import {
  WHEntryDOM,
  IWHMaterialConsumptionEntryDTO,
  WHMaterialConsumptionEntryDOM,
  WHDataService,
} from '@workheld/workheld-shared-lib';
import {
  INVENTORY_TYPE,
  MaterialDTO,
} from 'src/app/app-services-async/material-async.service';
import { sortEnumObject } from 'src/app/app-shared/helpers/enumSort.helper';

@Component({
  selector: 'ng-bee-type5-material-consumption-entry-static-data',
  templateUrl: './type5-material-consumption-entry-static-data.component.html',
  styleUrls: ['./type5-material-consumption-entry-static-data.component.scss'],
})
export class Type5MaterialConsumptionEntryStaticDataComponent
  implements OnInit, OnDestroy
{
  // ENUMS
  public UNIT: any;

  // DATA VAR
  @Input() public entryDOM: WHEntryDOM;
  public materialConsumptionEntryDOM: WHMaterialConsumptionEntryDOM = {
    loading: true,
    loaded: false,
  } as any;
  public materialDTO: MaterialDTO;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  INVENTORY_TYPE = INVENTORY_TYPE;

  constructor(
    private metadataDataService: WHMetadataDataService,
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // ENUMS
    this.subscriptions.push(
      this.metadataDataService.metadataTranslation$.subscribe(
        (metadataTranslation: IWHFlowMetadataTranslationModel) => {
          this.UNIT = sortEnumObject(metadataTranslation.UNIT);
          this.changeDetectorRef.detectChanges();
        }
      )
    );

    this.subscriptions.push(
      this.dataService
        .getByUrl(this.entryDOM.entryDetailURL)
        .subscribe(
          (materialConsumptionEntryDTO: IWHMaterialConsumptionEntryDTO) => {
            this.materialConsumptionEntryDOM = updateObject(
              this.materialConsumptionEntryDOM,
              this.dataModelHelperService.initMaterialConsumptionEntryDOM(
                materialConsumptionEntryDTO
              )
            );
            this.fetchMaterialConsumptionEntry(
              materialConsumptionEntryDTO?.materialDetailURL
            );
            this.changeDetectorRef.detectChanges();
          }
        )
    );
  }

  private fetchMaterialConsumptionEntry(detailURL: string) {
    this.subscriptions.push(
      this.dataService.getByUrl(detailURL).subscribe((materialDTO: any) => {
        this.materialDTO = materialDTO;
        this.dataModelHelperService.materialInventoryType.set(
          materialDTO?.inventoryType
        );
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
