// APP
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

// RxJS 6
import { BehaviorSubject } from 'rxjs';

// API
import {
  WHLayoutConfigDOM,
  WHLayoutNavItemDOM,
  WHRegistredLayoutConfigDOM,
  WHIconENUM,
  WHRouteZeroENUM,
  WHFeatureKeyENUM,
  WHLayoutConfigTypeENUM,
  WHPermissionKeyENUM,
} from '@workheld/workheld-shared-lib';

// CONNECT W_H_CALL
export const workheldCallConnect$: BehaviorSubject<boolean> =
  new BehaviorSubject(false);

// QUICK NAV
export const quickNavDOM: WHLayoutNavItemDOM = {
  enabled: true,
  type: 'quicknav',
  icon: WHIconENUM.WorkheldIcon,
  children: [
    {
      enabled: false,
      type: 'quicknav',
      translateKey: 'app.whsense.label',
      svgIcon: 'workheld-sense-icon',
      routeUrl: environment.whSenseUrl,
    },
    {
      enabled: true,
      type: 'quicknav',
      translateKey: 'app.call.label',
      // icon: WHIconENUM.WorkheldIcon,
      svgIcon: 'workheld-call-icon',
      routeUrl: environment.callUrl,
    },
    {
      enabled: true,
      type: 'quicknav',
      translateKey: 'app.mgmt.label',
      // icon: WHIconENUM.WorkheldIcon,
      icon: WHIconENUM.SettingsIcon,
      routeUrl: environment.mgmtUrl,
    },
    {
      enabled: true,
      type: 'quicknav',
      translateKey: 'app.help.label',
      icon: WHIconENUM.InfoIcon,
      routeUrl: 'https://tabletsolutions.atlassian.net/wiki/spaces/WD/overview',
    },
  ],
};
// HELP NAV
export const helpNavDOM: WHLayoutNavItemDOM = {
  enabled: true,
  type: 'helpnav',
  icon: WHIconENUM.WorkheldIcon,
  children: [
    {
      enabled: true,
      type: 'helpnav',
      translateKey: 'nav.help.helpandsupport',
    },
    {
      enabled: true,
      type: 'helpnav',
      translateKey: 'nav.help.termsofuse',
    },
    {
      enabled: true,
      type: 'helpnav',
      translateKey: 'nav.help.imprint',
    },
    {
      enabled: true,
      type: 'helpnav',
      translateKey: `<a>v${environment.version}</a>`,
    },
  ],
};

// LAYOUTS
export const registredLayoutConfigDOMList: WHRegistredLayoutConfigDOM[] = [
  {
    enabled: false,
    pictureUrl: '',
    translateKey: 'layout.alpha.label',
    layoutConfigDOM: new WHLayoutConfigDOM().initConfigType(
      WHLayoutConfigTypeENUM.ALPHA
    ),
  },
  {
    enabled: false,
    pictureUrl: '',
    translateKey: 'layout.beta.label',
    layoutConfigDOM: new WHLayoutConfigDOM().initConfigType(
      WHLayoutConfigTypeENUM.BETA
    ),
  },
  {
    enabled: false,
    pictureUrl: '',
    translateKey: 'layout.gamma.label',
    layoutConfigDOM: new WHLayoutConfigDOM().initConfigType(
      WHLayoutConfigTypeENUM.GAMMA
    ),
  },
  {
    enabled: false,
    pictureUrl: '',
    translateKey: 'layout.delta.label',
    layoutConfigDOM: new WHLayoutConfigDOM().initConfigType(
      WHLayoutConfigTypeENUM.DELTA
    ),
  },
];

// USER ITEMS
export const layoutLoggedInUserNavItemDOMList: WHLayoutNavItemDOM[] = [
  {
    id: 101,
    enabled: false,
    translateKey: 'nav.userprofile.root',
    featureKey: WHFeatureKeyENUM.PROFILE,
    type: 'navigate',
    routeUrl: WHRouteZeroENUM.PROFILE,
    icon: WHIconENUM.UserIcon,
  },
  {
    id: 106,
    enabled: true,
    translateKey: 'nav.userprofile.logout',
    type: 'logout',
    icon: WHIconENUM.LogoutIcon,
  },
];

// NAVIGATION
export const layoutNavItemDOMList: WHLayoutNavItemDOM[] = [
  {
    id: 1,
    featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW,
    enabled: false,
    translateKey: 'nav.overview.root',
    type: 'navigate',
    icon: WHIconENUM.PerformanceDashboardIcon,
    children: [
      {
        id: 11,
        parentId: 1,
        featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW,
        enabled: false,
        translateKey: 'nav.overview.statusdashboard',
        routeUrl: WHRouteZeroENUM.STATUS_OVERVIEW,
        icon: WHIconENUM.StatusOverviewIcon,
        type: 'navigate',
      },
      // {
      //   id: 12,
      //   parentId: 1,
      //   featureKey: WHFeatureKeyENUM.SUBCONTRACTOR_BOARD,
      //   enabled: false,
      //   translateKey: 'nav.overview.subcontractorboard',
      //   routeUrl: WHRouteZeroENUM.SUBCONTACTOR_BOARD,
      //   icon: WHIconENUM.StatusOverviewIcon,
      //   type: 'navigate',
      // },
      {
        id: 13,
        parentId: 1,
        featureKey: WHFeatureKeyENUM.ADVANCED_TEAM_PLANNER,
        enabled: false,
        translateKey: 'nav.overview.teamplanner',
        routeUrl: WHRouteZeroENUM.TEAM_PLANNER,
        icon: WHIconENUM.PlanningBoardIcon,
        type: 'navigate',
      },
      {
        id: 65,
        parentId: 1,
        featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW_PROJECT_OVERVIEW,
        overruleEnabled: true,
        enabled: false,
        translateKey: 'nav.overview.projectoverview',
        routeUrl: WHRouteZeroENUM.PROJECT_OVERVIEW,
        icon: WHIconENUM.ProjectIcon,
        type: 'navigate',
      },
      {
        id: 15,
        parentId: 1,
        featureKey: WHFeatureKeyENUM.EQUIPMENT_PLANNER,
        enabled: false,
        translateKey: 'nav.equipment.planner',
        routeUrl: WHRouteZeroENUM.EQUIPMENT_PLANNER,
        matIcon: 'view_in_ar',
        type: 'navigate',
      },
      {
        id: 64,
        parentId: 1,
        featureKey: WHFeatureKeyENUM.SHIFT_PLANNER,
        enabled: false,
        translateKey: 'nav.overview.shiftplanner',
        routeUrl: WHRouteZeroENUM.SHIFT_PLANNER,
        matIcon: 'free_cancellation',
        type: 'navigate',
      },
      {
        id: 66,
        parentId: 1,
        featureKey: WHFeatureKeyENUM.DAILY_DASHBOARD,
        enabled: false,
        translateKey: 'nav.overview.dailydashboard',
        routeUrl: WHRouteZeroENUM.DAILY_DASHBOARD,
        matIcon: 'space_dashboard',
        type: 'navigate',
      },
      {
        id: 14,
        parentId: 1,
        featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW_UNASSIGNED_ITEMS,
        enabled: false,
        translateKey: 'nav.overview.unassignedentries',
        routeUrl: WHRouteZeroENUM.ENTRIES_UNASSIGNED,
        icon: WHIconENUM.UnassignedEntriesIcon,
        type: 'navigate',
        permissionCheckConfig: {
          permission: [
            WHPermissionKeyENUM.FLOW_TECHNICIAN_ENTRY_READ,
            WHPermissionKeyENUM.FLOW_REPORT_READ,
          ],
          operator: 'OR',
        },
      },
      {
        id: 16,
        parentId: 1,
        featureKey: WHFeatureKeyENUM.MAINTENANCE_NOTIFICATIONS,
        enabled: false,
        translateKey: 'ui.maintenancenotifications.label',
        routeUrl: WHRouteZeroENUM.MAINTENANCE_NOTIFICATIONS,
        icon: WHIconENUM.DefectIcon,
        type: 'navigate',
        permissionCheckConfig: {
          permission: WHPermissionKeyENUM.FLOW_MAINTENANCE_NOTIFICATION_READ,
        },
      },
      {
        id: 18,
        parentId: 1,
        featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW_REPORTS_OVERVIEW,
        enabled: true,
        translateKey: 'nav.overview.reportsoverview',
        routeUrl: WHRouteZeroENUM.WORK_OBJECT_REPORTS_OVERVIEW,
        icon: WHIconENUM.ReportIcon,
        type: 'navigate',
      },
    ],
  },
  {
    id: 2,
    featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW,
    enabled: false,
    translateKey: 'nav.work.root',
    icon: WHIconENUM.WorkStepIcon,
    type: 'navigate',
    permissionCheckConfig: {
      permission: [
        WHPermissionKeyENUM.FLOW_TECHNICIAN_PROJECT_READ,
        WHPermissionKeyENUM.FLOW_TECHNICIAN_CASE_READ,
        WHPermissionKeyENUM.FLOW_TECHNICIAN_STANDING_ORDER_READ,
      ],
      operator: 'OR',
    },
    children: [
      {
        id: 21,
        parentId: 2,
        featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW_PROJECT,
        enabled: false,
        translateKey: 'nav.work.project',
        routeUrl: WHRouteZeroENUM.PROJECTS,
        icon: WHIconENUM.ProjectIcon,
        type: 'navigate',
        permissionCheckConfig: {
          permission: WHPermissionKeyENUM.FLOW_TECHNICIAN_PROJECT_READ,
        },
      },
      {
        id: 22,
        parentId: 2,
        featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW_CASE,
        enabled: false,
        translateKey: 'nav.work.case',
        routeUrl: WHRouteZeroENUM.CASES,
        icon: WHIconENUM.CaseIcon,
        type: 'navigate',
        permissionCheckConfig: {
          permission: WHPermissionKeyENUM.FLOW_TECHNICIAN_CASE_READ,
        },
      },
      {
        id: 23,
        parentId: 2,
        featureKey: WHFeatureKeyENUM.EXPERIMENTAL_CASE_QUICK_CREATE,
        enabled: false,
        translateKey: 'nav.work.casecreationwidget',
        routeUrl: WHRouteZeroENUM.CASES_CATEGORY,
        icon: WHIconENUM.CaseIcon,
        type: 'navigate',
        permissionCheckConfig: {
          permission: WHPermissionKeyENUM.FLOW_TECHNICIAN_CASE_READ,
        },
      },
      {
        id: 24,
        parentId: 2,
        featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW_STANDING_ORDER,
        enabled: false,
        translateKey: 'nav.work.standingorder',
        routeUrl: WHRouteZeroENUM.STANDING_ORDER,
        icon: WHIconENUM.StandingOrdersIcon,
        type: 'navigate',
        permissionCheckConfig: {
          permission: WHPermissionKeyENUM.FLOW_TECHNICIAN_STANDING_ORDER_READ,
        },
      },
    ],
  },
  {
    id: 3,
    featureKey: WHFeatureKeyENUM.INSTALLED_BASE,
    enabled: false,
    translateKey: 'nav.equipment.root',
    icon: WHIconENUM.EquipmentIcon,
    type: 'navigate',
    permissionCheckConfig: {
      permission: WHPermissionKeyENUM.FLOW_EQUIPMENT_READ,
    },
    children: [
      {
        id: 31,
        parentId: 3,
        featureKey: WHFeatureKeyENUM.INSTALLED_BASE,
        enabled: false,
        translateKey: 'nav.equipment.management',
        routeUrl: WHRouteZeroENUM.EQUIPMENTS,
        icon: WHIconENUM.EquipmentIcon,
        type: 'navigate',
        permissionCheckConfig: {
          permission: WHPermissionKeyENUM.FLOW_EQUIPMENT_READ,
        },
      },
      {
        id: 32,
        parentId: 3,
        featureKey: WHFeatureKeyENUM.FIX_MAINTENANCE_PLAN,
        enabled: false,
        translateKey: 'nav.equipment.maintenanceplan',
        routeUrl: WHRouteZeroENUM.MAINTENANCE_PLAN_FIX,
        icon: WHIconENUM.MaintenancePlanIcon,
        type: 'navigate',
      },
      {
        id: 33,
        parentId: 3,
        featureKey: WHFeatureKeyENUM.MAINTENANCE_PLAN,
        enabled: false,
        translateKey: 'nav.equipment.maintenanceplan',
        routeUrl: WHRouteZeroENUM.MAINTENANCE_PLAN,
        icon: WHIconENUM.MaintenancePlanIcon,
        type: 'navigate',
      },
    ],
  },
  {
    id: 4,
    featureKey: WHFeatureKeyENUM.MATERIAL,
    enabled: false,
    translateKey: 'nav.material.root',
    icon: WHIconENUM.MaterialConsumptionIcon,
    type: 'navigate',
    permissionCheckConfig: {
      permission: WHPermissionKeyENUM.FLOW_MATERIAL_READ,
    },
    children: [
      {
        id: 41,
        parentId: 4,
        featureKey: WHFeatureKeyENUM.MATERIAL,
        enabled: false,
        translateKey: 'nav.material.management',
        routeUrl: WHRouteZeroENUM.MATERIALS,
        icon: WHIconENUM.MaterialConsumptionIcon,
        type: 'navigate',
        permissionCheckConfig: {
          permission: WHPermissionKeyENUM.FLOW_MATERIAL_READ,
        },
      },
      {
        id: 43,
        parentId: 4,
        featureKey: WHFeatureKeyENUM.MATERIA_INVENTORY,
        enabled: false,
        translateKey: 'nav.humanresource.carstock',
        routeUrl: WHRouteZeroENUM.MATERIALINVENTORY,
        icon: WHIconENUM.InventoryIcon,
        type: 'navigate',
      },
    ],
  },
  {
    id: 5,
    featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW,
    enabled: false,
    translateKey: 'nav.masterdata.root',
    icon: WHIconENUM.ServerIcon,
    type: 'navigate',
    children: [
      {
        id: 51,
        parentId: 5,
        featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW,
        enabled: false,
        translateKey: 'nav.masterdata.customer',
        routeUrl: WHRouteZeroENUM.CUSTOMERS,
        icon: WHIconENUM.CustomerIcon,
        type: 'navigate',
      },
      {
        id: 52,
        parentId: 5,
        featureKey: WHFeatureKeyENUM.CORE_WORK_FLOW,
        enabled: false,
        translateKey: 'nav.masterdata.checklistdefinition',
        routeUrl: WHRouteZeroENUM.CHECKLIST_DEFINITION,
        icon: WHIconENUM.ChecklistDefinitionIcon,
        type: 'navigate',
        permissionCheckConfig: {
          permission:
            WHPermissionKeyENUM.FLOW_TECHNICIAN_CHECKLIST_TEMPLATE_READ,
        },
      },
      {
        id: 53,
        parentId: 5,
        featureKey: WHFeatureKeyENUM.TOOL,
        enabled: false,
        translateKey: 'nav.masterdata.tool',
        routeUrl: WHRouteZeroENUM.TOOLS,
        icon: WHIconENUM.ToolsIcon,
        type: 'navigate',
      },
      {
        id: 54,
        parentId: 5,
        featureKey: WHFeatureKeyENUM.TEMPLATE,
        enabled: false,
        translateKey: 'nav.masterdata.template',
        routeUrl: WHRouteZeroENUM.WORK_OBJECT_TEMPLATES,
        icon: WHIconENUM.ProjectTemplateIcon,
        type: 'navigate',
      },
      {
        id: 55,
        parentId: 5,
        featureKey: WHFeatureKeyENUM.TECHNICIAN_MANAGEMENT,
        permissionKey: WHPermissionKeyENUM.MANAGEMENT_USER_READ,
        enabled: false,
        translateKey: 'nav.masterdata.technicianonboarding',
        routeUrl: WHRouteZeroENUM.WORKERS,
        icon: WHIconENUM.UsersIcon,
        type: 'navigate',
      },
    ],
  },
  {
    id: 7,
    featureKey: WHFeatureKeyENUM.HUMAN_RESOURCES,
    enabled: false,
    translateKey: 'nav.humanresources.root',
    matIcon: 'groups',
    type: 'navigate',
    children: [
      {
        id: 59,
        parentId: 7,
        featureKey: WHFeatureKeyENUM.SKILL_MANAGEMENT,
        enabled: false,
        translateKey: 'nav.hrworker.root',
        routeUrl: WHRouteZeroENUM.HR_WORKERS,
        matIcon: 'groups',
        type: 'navigate',
      },
      {
        id: 63,
        parentId: 7,
        featureKey: WHFeatureKeyENUM.ABSENCE_REQUESTS,
        enabled: false,
        translateKey: 'nav.absencerequest.root',
        routeUrl: WHRouteZeroENUM.ABSENCE_REQUESTS,
        matIcon: 'event_busy',
        type: 'navigate',
        permissionCheckConfig: {
          permission: WHPermissionKeyENUM.FLOW_ABSENCE_REQUEST_READ,
        },
      },
      {
        id: 67,
        parentId: 7,
        featureKey: WHFeatureKeyENUM.SKILL_MANAGEMENT,
        enabled: false,
        translateKey: 'nav.skillmanagement.root',
        matIcon: 'badge',
        type: 'navigate',
        children: [
          {
            id: 56,
            parentId: 67,
            featureKey: WHFeatureKeyENUM.SKILL_MANAGEMENT,
            enabled: false,
            translateKey: 'nav.skills.root',
            routeUrl: WHRouteZeroENUM.SKILLS,
            matIcon: 'bolt',
            type: 'navigate',
          },
          {
            id: 57,
            parentId: 67,
            featureKey: WHFeatureKeyENUM.SKILL_MANAGEMENT,
            enabled: false,
            translateKey: 'nav.qualifications.root',
            routeUrl: WHRouteZeroENUM.QUALIFICATIONS,
            matIcon: 'workspace_premium',
            type: 'navigate',
          },
          {
            id: 58,
            parentId: 67,
            featureKey: WHFeatureKeyENUM.SKILL_MANAGEMENT,
            enabled: false,
            translateKey: 'nav.jobrequirements.root',
            routeUrl: WHRouteZeroENUM.JOB_REQUIREMENTS,
            matIcon: 'business_center',
            type: 'navigate',
          },
        ],
      },
      {
        id: 68,
        parentId: 7,
        featureKey: WHFeatureKeyENUM.SHIFT_MANAGEMENT,
        enabled: false,
        translateKey: 'nav.shiftmanagement.root',
        matIcon: 'free_cancellation',
        type: 'navigate',
        children: [
          {
            id: 60,
            parentId: 7,
            enabled: false,
            featureKey: WHFeatureKeyENUM.SHIFT_MANAGEMENT,
            translateKey: 'nav.shiftgroup.root',
            routeUrl: WHRouteZeroENUM.SHIFT_GROUPS,
            matIcon: 'schedule',
            type: 'navigate',
          },
          {
            id: 61,
            parentId: 7,
            featureKey: WHFeatureKeyENUM.SHIFT_MANAGEMENT,
            enabled: false,
            translateKey: 'nav.shiftdefinition.root',
            routeUrl: WHRouteZeroENUM.SHIFT_DEFINITIONS,
            matIcon: 'manage_history',
            type: 'navigate',
          },
          {
            id: 62,
            parentId: 7,
            featureKey: WHFeatureKeyENUM.SHIFT_MANAGEMENT,
            enabled: false,
            translateKey: 'nav.shiftcalendar.root',
            routeUrl: WHRouteZeroENUM.SHIFT_CALENDAR,
            matIcon: 'event',
            type: 'navigate',
          },
        ],
      },
    ],
  },
  {
    id: 6,
    featureKey: 'WHFeatureKeyENUM.W_H_CALL',
    enabled: false,
    translateKey: 'nav.workheldcall.root',
    // icon: WHIconENUM.WorkheldIcon,
    svgIcon: 'workheld-call-icon',
    routeUrl: WHRouteZeroENUM.WORKHELD_CALL,
    type: 'navigate',
    children: [],
  },
];

export function localeFactory(translate: TranslateService): string {
  // Here you can add more logic if needed to determine the locale
  return translate.currentLang || 'de-AT'; // Default to 'en-US' if no currentLang is set
}
