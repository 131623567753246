// THIRD
import { ImageCropperModule } from 'ngx-image-cropper';

// ANGULAR
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// APP
import { IndexDialogRoutingModule } from './index-dialog-routing.module';
import { WHWorkStepAssignmentChipComponent } from './mat-dialog-work-step-create-edit-form-entry/w-h-work-step-assignment-chip/w-h-work-step-assignment-chip.component';

// MODULE
import { AppSharedModule } from '../app-shared/app-shared.module';
import { WHStatusBarUtilModule } from '../app-modules-pages/w-h-status-bar-util/w-h-status-bar-util.module';
import { WHMatDatePickerModule } from '../app-modules-pages/w-h-date-util/w-h-date-picker/w-h-date-picker.module';
import { WHMatDatePickerUtilModule } from '../app-modules-pages/w-h-date-util/w-h-mat-date-picker-util/w-h-mat-date-picker-util.module';
import { WHAgmCoreLocationFormModule } from '../app-modules-pages/w-h-agm-core-util/w-h-agm-core-location-form/w-h-agm-core-location-form.module';
import { ChecklistDefinitionDetailModule } from '../app-modules-pages/checklist-definition/checklist-definition-detail/checklist-definition-detail.module';
import { EntryStaticDataModule } from '../app-modules-pages/entry/entry-static-data/entry-static-data.module';
import { EntryProcessFormModule } from '../app-modules-pages/entry/entry-process-form/entry-process-form.module';
import { ChecklistInstanceFileImgGalleryModule } from '../app-modules-pages/checklist-instance-file/checklist-instance-file-img-gallery/checklist-instance-file-img-gallery.module';
import { ListModule } from '../app-modules-layout/list/list.module';
import { WHMaterialPreviewModule } from '../app-modules-pages/material/w-h-material-preview/w-h-material-preview.module';
import { CustomerStaticDataModule } from '../app-modules-pages/customer/customer-static-data/customer-static-data.module';
import { MatIconModule } from '@angular/material/icon';

// DIALOGS
import { MatDialogConfirmEntryComponent } from './mat-dialog-confirm-entry/mat-dialog-confirm-entry.component';
import { MatDialogNgxImageCropperEntryComponent } from './mat-dialog-ngx-image-cropper-entry/mat-dialog-ngx-image-cropper-entry.component';
import { MatDialogImagePreviewEntryComponent } from './mat-dialog-image-preview-entry/mat-dialog-image-preview-entry.component';
import { MatDialogWorkStepCreateEditFormEntryComponent } from './mat-dialog-work-step-create-edit-form-entry/mat-dialog-work-step-create-edit-form-entry.component';
import { MatDialogChecklistDefinitionAssignmentEntryComponent } from './mat-dialog-checklist-definition-assignment-entry/mat-dialog-checklist-definition-assignment-entry.component';
import { MatDialogChecklistInstanceDetailEntryComponent } from './mat-dialog-checklist-instance-detail-entry/mat-dialog-checklist-instance-detail-entry.component';
import { MatDialogCreateWorkObjectTemplateComponent } from './mat-dialog-create-work-object-template/mat-dialog-create-work-object-template.component';
import { MatDialogUseWorkObjectTemplateEntryComponent } from './mat-dialog-use-work-object-template-entry/mat-dialog-use-work-object-template-entry.component';
import { MatDialogEntryDetailEntryComponent } from './mat-dialog-entry-detail-entry/mat-dialog-entry-detail-entry.component';
import { MatDialogWorkStepSchedulerEntryComponent } from './mat-dialog-work-step-scheduler-entry/mat-dialog-work-step-scheduler-entry.component';
import { MatDialogCommentEntryComponent } from './mat-dialog-comment-entry/mat-dialog-comment-entry.component';
import { MatDialogPreviewEntryComponent } from './mat-dialog-preview-entry/mat-dialog-preview-entry.component';
import { MatDialogCreateReportEntryComponent } from './mat-dialog-create-report-entry/mat-dialog-create-report-entry.component';
import { MatDialogContactEntryComponent } from './mat-dialog-contact-entry/mat-dialog-contact-entry.component';
import { MatDialogContactCustomerEntryComponent } from './mat-dialog-contact-customer-entry/mat-dialog-contact-customer-entry.component';
import { MatDialogContactPrincipalEntryComponent } from './mat-dialog-contact-principal-entry/mat-dialog-contact-principal-entry.component';

// API
import {
  WHAbsenceRequestsDataService,
  WHDropdownModule,
  WHImgAuthHeaderModule,
  WHMatDateTimePickerModule,
  WHShiftDefinitionsService,
  WHShiftGroupDataService,
} from '@workheld/workheld-shared-lib';
import { EventEditorComponent } from './event-editor/event-editor.component';
import { MatDialogWorkObjectRejectEntryComponent } from './mat-dialog-workobject-reject-entry/mat-dialog-workobject-reject-entry.component';
import { MatDialogWorkObjectPauseResumeEntryComponent } from './mat-dialog-workobject-pause-resume-entry/mat-dialog-workobject-pause-resume-entry.component';
import { MatDialogConfirmUnsavedChangesComponent } from './mat-dialog-confirm-unsaved-changes/mat-dialog-confirm-unsaved-changes.component';
import { MatDialogAddCertificateComponent } from './mat-dialog-add-certificate/mat-dialog-add-certificate.component';
import { MatDialogAddSkillComponent } from './mat-dialog-add-skill/mat-dialog-add-skill.component';
import { MatDialogAddShiftGroupComponent } from './mat-dialog-add-shift-group/mat-dialog-add-shift-group.component';
import { MatDialogAddShiftModelsComponent } from './mat-dialog-add-shift-models/mat-dialog-add-shift-models.component';
import { MatDialogAddShiftModelComponent } from './mat-dialog-add-shift-model/mat-dialog-add-shift-model.component';
import { MatDialogAddShiftDefinitionComponent } from './mat-dialog-add-shift-definition/mat-dialog-add-shift-definition.component';
import { MatDialogUpdateAbsenceRequestComponent } from './mat-dialog-update-absence-request/mat-dialog-update-absence-request.component';
import { MatDialogAddEquipmentNodeComponent } from './mat-dialog-add-equipment-node/mat-dialog-add-equipment-node.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CheckBodyStandaloneComponent } from './mat-dialog-checklist-instance-detail-entry/standalone-check-body.component';
import { ResumeReasonModalComponent } from './resume-reason-modal/resume-reason-modal.component';

export const registredEntryComponents: Array<any> = [
  EventEditorComponent,
  MatDialogConfirmEntryComponent,
  MatDialogNgxImageCropperEntryComponent,
  MatDialogImagePreviewEntryComponent,
  MatDialogWorkStepCreateEditFormEntryComponent,
  MatDialogWorkStepSchedulerEntryComponent,
  MatDialogChecklistDefinitionAssignmentEntryComponent,
  MatDialogChecklistInstanceDetailEntryComponent,
  MatDialogCreateWorkObjectTemplateComponent,
  MatDialogUseWorkObjectTemplateEntryComponent,
  MatDialogEntryDetailEntryComponent,
  MatDialogCommentEntryComponent,
  MatDialogPreviewEntryComponent,
  MatDialogContactEntryComponent,
  MatDialogCreateReportEntryComponent,
  MatDialogContactCustomerEntryComponent,
  MatDialogWorkObjectRejectEntryComponent,
  MatDialogContactPrincipalEntryComponent,
  MatDialogWorkObjectPauseResumeEntryComponent,
  MatDialogConfirmUnsavedChangesComponent,
  MatDialogAddCertificateComponent,
  MatDialogAddSkillComponent,
  MatDialogAddShiftGroupComponent,
  MatDialogAddShiftModelsComponent,
  MatDialogAddShiftModelComponent,
  MatDialogAddShiftDefinitionComponent,
  MatDialogUpdateAbsenceRequestComponent,
  MatDialogAddEquipmentNodeComponent,
];

@NgModule({
  declarations: [
    ...registredEntryComponents,
    WHWorkStepAssignmentChipComponent,
  ],
  providers: [
    WHShiftGroupDataService,
    WHShiftDefinitionsService,
    WHAbsenceRequestsDataService,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AppSharedModule,
    ImageCropperModule,
    WHStatusBarUtilModule,
    WHMatDatePickerModule,
    WHMatDateTimePickerModule,
    WHMatDatePickerUtilModule,
    WHAgmCoreLocationFormModule,
    ChecklistDefinitionDetailModule,
    EntryStaticDataModule,
    EntryProcessFormModule,
    ChecklistInstanceFileImgGalleryModule,
    ListModule,
    WHDropdownModule,
    WHImgAuthHeaderModule,
    WHMaterialPreviewModule,
    IndexDialogRoutingModule,
    CustomerStaticDataModule,
    InfiniteScrollModule,
    MatIconModule,
    CheckBodyStandaloneComponent,
    ResumeReasonModalComponent,
  ],
})
export class IndexDialogModule {}
