import {
  IWHFlowMetadataTranslationModel,
  updateObject,
  WHMetadataDataService,
  WHDataService,
  WHEntryDOM,
  IWHTravelEntryDTO,
  WHTravelEntryDOM,
} from '@workheld/workheld-shared-lib';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  effect,
} from '@angular/core';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { Subscription } from 'rxjs';
import { sortEnumObject } from 'src/app/app-shared/helpers/enumSort.helper';
import { UIService } from 'src/app/app-services/ui.service';

@Component({
  selector: 'ng-bee-type1-travel-entry-static-data',
  templateUrl: './type1-travel-entry-static-data.component.html',
  styleUrls: ['./type1-travel-entry-static-data.component.scss'],
})
export class Type1TravelEntryStaticDataComponent implements OnInit, OnDestroy {
  // ENUMS
  public MEANS_OF_TRAVEL: any;
  public COUNTRY: any;

  // DATA VAR
  @Input() public entryDOM: WHEntryDOM;
  public travelEntryDOM: WHTravelEntryDOM = {
    loading: true,
    loaded: false,
  } as any;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];
  currentLocale: string;

  constructor(
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private metadataDataService: WHMetadataDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private uiService: UIService,
  ) {
    effect(() => {
      this.currentLocale = uiService.$currentLocale();
    });
  }

  ngOnInit() {
    // ENUMS
    this.subscriptions.push(
      this.metadataDataService.metadataTranslation$.subscribe(
        (metadataTranslation: IWHFlowMetadataTranslationModel) => {
          this.MEANS_OF_TRAVEL = sortEnumObject(
            metadataTranslation.MEANS_OF_TRAVEL,
          );
          this.COUNTRY = sortEnumObject(metadataTranslation.COUNTRY);
          this.changeDetectorRef.detectChanges();
        },
      ),
    );

    this.subscriptions.push(
      this.dataService
        .getByUrl(this.entryDOM.entryDetailURL)
        .subscribe((travelEntryDTO: IWHTravelEntryDTO) => {
          this.travelEntryDOM = updateObject(
            this.travelEntryDOM,
            this.dataModelHelperService.initTravelEntryDOM(travelEntryDTO),
          );
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
