<div class="checklist-definition-mat-dialog">
  <div class="detail-card-header">
    <span whFlex class="w-h-header-icon-span">
      <i
        mat-card-avatar
        class="workheld-icons header-icon"
        [innerHtml]="checklistDefinitionIcon"
      ></i>
    </span>
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ "checklistdefinition.ui.assignment.header" | translate }}
      </div>
    </span>
  </div>
  <div class="checklist-definition-mat-dialog-body">
    <div>
      <span whFlex="20" style="min-width: 0; border-right: 1px solid #e8e8e8">
        <div>
          <ng-bee-list
            [focusElement]="true"
            class="checklist-definition-mat-dialog-ng-bee-list"
            [isMaterialIcon]="true"
            [icon]="checklistDefinitionIcon"
            name="CHECKLIST_DEFINITION"
            [columns]="columns"
            (filterChange)="onFilterChange($event)"
          >
            <div class="actions" fxFlexAlign="center"></div>

            <mat-table
              class="data-table"
              #table
              [dataSource]="dataSource"
              matSort
            >
              <!--- Note that these columns can be defined in any order.
                                    The actual rendered columns are set as a property on the row definition" -->

              <!-- ID Column -->
              <ng-container matColumnDef="id">
                <mat-header-cell
                  class="image-cell"
                  *matHeaderCellDef
                ></mat-header-cell>
                <mat-cell class="image-cell" *matCellDef="let row">
                  <mat-icon [innerHTML]="checklistDefinitionIcon"></mat-icon>
                  <!-- <i class="workheld-icons" [innerHTML]="checklistDefinitionIcon"></i> -->
                </mat-cell>
              </ng-container>

              <!-- fullName Column -->
              <ng-container *ngFor="let column of columns">
                <ng-container
                  *ngIf="column.isModelProperty"
                  [matColumnDef]="column.property"
                >
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ column.name | translate }}</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row">
                    <div class="html-overflow">
                      {{ row[column.property] }}
                    </div>
                  </mat-cell>
                </ng-container>
              </ng-container>

              <mat-row
                *matRowDef="let row; columns: visibleColumns"
                (click)="selectChecklistDefinition(row)"
                [ngClass]="{
                  'selected-checklist-definition':
                    checklistDefinitionId && row.id === checklistDefinitionId
                }"
              >
              </mat-row>
            </mat-table>

            <mat-paginator
              class="paginator"
              [length]="resultsLength"
              [pageSizeOptions]="[10]"
              [pageSize]="10"
            >
            </mat-paginator>
          </ng-bee-list>
        </div>
      </span>
      <span whFlex="80" style="min-width: 0">
        <div *ngIf="checklistDefinitionId">
          <ng-bee-checklist-definition-detail
            class="checklist-definition-mat-dialog-checklist-definition-detail"
            [descriptionRows]="2"
            [displayHeader]="false"
            [displayHeaderInBody]="true"
            [displayActionButtons]="false"
            [MEASUREMENT_TYPE]="MEASUREMENT_TYPE"
            [UNIT]="UNIT"
          >
          </ng-bee-checklist-definition-detail>
        </div>
        <div
          *ngIf="!checklistDefinitionId"
          class="select-checklist-definition-msg"
        >
          <span whFlex style="align-self: center">
            {{ "workstep.ui.selectchecklistdefinition.helpertext" | translate }}
          </span>
        </div>
        <div class="detail-actions-wrapper">
          <button
            mat-flat-button
            class="w-h-default-button mat-elevation-z2"
            (click)="cancel()"
          >
            <i class="workheld-icons" [innerHTML]="cancelIcon"></i>
            {{ "app.cancel.label" | translate | uppercase }}
          </button>
          <button
            *ngIf="isAssignable"
            mat-flat-button
            color="accent"
            class="action-button mat-elevation-z2"
            (click)="assignChecklistDefinition()"
            [disabled]="
              !checklistDefinitionId || data?.calculatedWorkStepStatusId > 0
            "
          >
            <i class="workheld-icons" [innerHTML]="saveIcon"></i>
            {{ "app.assign.label" | translate | uppercase }}
          </button>
        </div>
      </span>
    </div>
  </div>
</div>
