// 3 LIBS
import * as moment from 'moment';

// ENVIRONMENT
import { environment } from 'src/environments/environment';
import { clientLoggerConfig, i18nJargon } from 'src/environments/env.config';

// ANGULAR
import { DOCUMENT } from '@angular/common';
import { AnimationBuilder } from '@angular/animations';
import {
  HttpClient,
  HttpBackend,
  HttpErrorResponse,
} from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';

// @ngx-translate/core
import { TranslateService } from '@ngx-translate/core';

// AUTH0
import { AuthClientConfig, AuthService } from '@auth0/auth0-angular';

// RxJS 6
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';

// API
import {
  WHLayoutConfigService,
  WHLayoutNavItemDOM,
  WHLayoutLoggedInUserDOM,
  WHLayoutLanguageDOM,
  WHThemeConfigService,
  WHLayoutConfigDOM,
  IWHLanguageDTO,
  IWHTenantDTO,
  parseTokenToObject,
  RequestTranslationCommand,
  WHI18nDataService,
  WHIconENUM,
  initSplashScreen,
  WHSplashScreenTypeENUM,
  SplashScreenConfigDOM,
  primaryColor,
  WHLoginDataService,
  WHFeatureConfigDataService,
  FLOW_GATEWAY,
  MGMT_GATEWAY,
  IWHFeatureConfigDTO,
  WHMetadataDataService,
  IWHFlowMetadataDTO,
  setRegisterLocaleData,
  IWHEnumWithTranslationsDTO,
  WHFlowMetadataDOM,
  IWHUserDTO,
  WHActiveUserDOM,
  WHActiveTenantDOM,
  themeConfig,
  sanitizeFeatureConfig,
  sanitizePagesUi,
  WHLayoutConfigTypeENUM,
  WHLogMessage,
  WHNgxLoggerService,
  WHRegisterLocaleDataENUM,
  WHRouteZeroENUM,
  getAccessTokenFromLocalStorage,
  WHFeatureKeyENUM,
  MGMT_v1_GATEWAY,
} from '@workheld/workheld-shared-lib';

// CONFIG
import {
  layoutNavItemDOMList,
  layoutLoggedInUserNavItemDOMList,
  registredLayoutConfigDOMList,
} from './app.config';

// APP ROUTER
import { Route, Router } from '@angular/router';
import { routes } from './app-routing.module';

// SCHEDULER LOCALE
import {
  applyBryntumLocale,
  extendBryntumLocale,
} from 'src/app/app-pages/w-h-team-planner-page/team-planner-configs/team-planner-locale.config';

interface ENV_SETTINGS {
  PRODUCTION: string;
  API_URL: string;
  STAGE: string;
  MGMT_URL: string;
  FLOW_URL: string;
  CALL_URL: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  GOOGLE_MAPS_API_KEY: string;
  GOOGLE_ANALYTICS_ID: string;
  INSTRUMENTATION_KEY: string;
}

const ENV_SETTINGS_URL = `./assets/environment_settings.json`;

enum LANG_CHECK {
  'de' = WHFeatureKeyENUM.LANGUAGE_DE,
  'en' = WHFeatureKeyENUM.LANGUAGE_EN,
  'fr' = WHFeatureKeyENUM.LANGUAGE_FR,
  'pr' = WHFeatureKeyENUM.LANGUAGE_PR,
  'es' = WHFeatureKeyENUM.LANGUAGE_ES,
}
@Injectable()
export class AppInitializerService {
  // DOM
  private splashScreenElem: HTMLElement;

  // CONF
  private httpClient: HttpClient;
  private handler: HttpBackend;
  private authClientConfig: AuthClientConfig;
  private ngxLoggerService: WHNgxLoggerService;

  // VAR
  private clientLoggerConfig: WHLogMessage = clientLoggerConfig;

  constructor(
    http: HttpClient,
    handler: HttpBackend,
    authClientConfig: AuthClientConfig,
    @Inject(DOCUMENT) private document: Document,
    private animationBuilder: AnimationBuilder,
    private router: Router,
    private injector: Injector
  ) {
    // CONF
    this.httpClient = http;
    this.handler = handler;
    this.authClientConfig = authClientConfig;
  }

  public initializeApp(): Promise<void> {
    // SPLASH SCREEN PLAY
    this.playSplashScreen(WHSplashScreenTypeENUM.WORKHELD_FLOW, {
      fillColor: primaryColor,
      backgroundImageUrl: './assets/img/loading.gif',
    });

    return new Promise<void>((resolveInit, rejectInit) => {
      // INIT CORE SERVICES
      this.ngxLoggerService = this.injector.get(WHNgxLoggerService);
      const translateService: TranslateService =
        this.injector.get(TranslateService);
      const loginDataService: WHLoginDataService =
        this.injector.get(WHLoginDataService);
      const i18nDataService: WHI18nDataService =
        this.injector.get(WHI18nDataService);
      const featureConfigDataService: WHFeatureConfigDataService =
        this.injector.get(WHFeatureConfigDataService);
      const themeConfigService: WHThemeConfigService =
        this.injector.get(WHThemeConfigService);
      const layoutConfigService = this.injector.get(WHLayoutConfigService);
      const metadataDataService: WHMetadataDataService = this.injector.get(
        WHMetadataDataService
      );

      // Don't initiate authService before the authClientConfig.set
      // Because the environment has to be fully configured
      let authService: AuthService;

      // HTTP CLIENT
      this.httpClient = new HttpClient(this.handler);

      // SET AUTH CONFIG - environment
      const auth0Config: any = {};

      this.httpClient
        .get(ENV_SETTINGS_URL)
        .pipe(
          switchMap((environmentSettings: any) => {
            this.initializeEnvironment(environmentSettings);
            this.clientLoggerConfig.stage = environment.stage;
            // ENV SETTINGS
            return of(environment.apiUrl);
          }),
          switchMap((apiUrl: string) => {
            if (apiUrl) {
              routes.forEach((route: Route) => {
                // console.log(route);
                if (route.children) {
                  route.children.forEach((childRoute: Route) => {
                    // console.log(childRoute);
                    if (
                      childRoute['data'] &&
                      childRoute['path'] !== WHRouteZeroENUM.WORKHELD_CALL
                    ) {
                      Object.assign(childRoute['data'], {
                        apiUrl,
                      });
                    }
                    // console.log(childRoute['data']);
                  });
                }
              });
              environment.auth0Config.httpInterceptor.allowedList = [
                `${apiUrl}*`,
                `${'https://workheld-callservice-dev.azurewebsites.net/'}*`,
              ];
            }
            this.router.resetConfig(routes);

            // Populate auth0Config with environment.auth0Config
            Object.entries(environment.auth0Config).forEach(
              (property: any[]) => {
                auth0Config[property[0]] = property[1];
              }
            );

            // init authClientConfig and service
            this.authClientConfig.set(auth0Config);
            authService = this.injector.get(AuthService);

            return authService.isLoading$.pipe(take(1));
          }),
          switchMap((loading: boolean) => {
            // console.log(loading);
            return this.loading(authService, loading);
          }),
          switchMap((loading: boolean) => {
            const auth0Token: any = getAccessTokenFromLocalStorage(
              this.authClientConfig.get().clientId
            );
            if (!loading && !auth0Token) {
              this.logout(authService);
              return of().pipe(take(0));
            }
            if (loading) {
              return of().pipe(take(0));
            }
            return of(auth0Token.body?.access_token);
          }),
          switchMap((token: string) => {
            if (token) {
              this.setTokenData(
                parseTokenToObject(token),
                loginDataService,
                layoutConfigService
              );
            }

            console.log('token', token);

            return loginDataService
              .getMe({
                apiUrl: environment.apiUrl + MGMT_GATEWAY,
              })
              .pipe(
                catchError((error: HttpErrorResponse) => {
                  console.log('getMe errors', error);
                  this.logout(authService);
                  return of(null);
                })
              );
          }),
          switchMap((userDTO: IWHUserDTO) => {
            // SET USER DATA
            this.setLoginData(userDTO, loginDataService, layoutConfigService);

            return loginDataService.getMyTenant({
              apiUrl: environment.apiUrl + MGMT_GATEWAY,
            });
          }),
          switchMap((tenantDTO: IWHTenantDTO) => {
            // SET TENANT DATA
            this.setTenantData(
              tenantDTO,
              loginDataService,
              layoutConfigService,
              themeConfigService
            );

            return featureConfigDataService.getFlowFeatureConfigDTOList({
              apiUrl: environment.apiUrl + MGMT_GATEWAY,
            });
          }),
          switchMap((featureConfigDTOList: IWHFeatureConfigDTO[]) => {
            // SET FEATURE CONFIGS
            this.setFeatureConfigData(
              featureConfigDTOList,
              loginDataService,
              layoutConfigService
            );

            return metadataDataService
              .getFlowMetadataDTO({
                apiUrl: environment.apiUrl + FLOW_GATEWAY,
              })
              .pipe(
                catchError((error: HttpErrorResponse) => {
                  console.log('getFlowMetadataDTO errors', error);
                  this.logout(authService);
                  return of(null);
                })
              );
          }),
          switchMap((metadataDTO: IWHFlowMetadataDTO) => {
            // SET METADATA
            if (metadataDTO) {
              metadataDataService.setMetadataDOM(
                new WHFlowMetadataDOM(metadataDTO)
              );
            }

            const activeUserDOM: WHActiveUserDOM =
              loginDataService.activeUserDOM$.value;
            let browserLang: string = translateService.getBrowserLang();
            if (
              !Object.values(WHRegisterLocaleDataENUM)?.includes(
                translateService.getBrowserLang() as any
              )
            ) {
              browserLang = 'de';
            }
            const languageKey: string =
              activeUserDOM.language && activeUserDOM.language.key
                ? activeUserDOM.language.key
                : browserLang;

            return metadataDataService
              .getFlowEnumTranslations(
                {
                  apiUrl: environment.apiUrl + MGMT_v1_GATEWAY,
                },
                {
                  language: languageKey,
                  type: undefined,
                  tenantToken: loginDataService.activeTenantDOM$.value.token,
                }
              )
              .pipe(
                catchError((error: HttpErrorResponse) => {
                  console.log('getFlowEnumTranslations errors', error);
                  this.logout(authService);
                  return of(null);
                })
              );
          }),
          switchMap((enumTranslations: IWHEnumWithTranslationsDTO[]) => {
            // SET ENUM TRANSLATIONS
            if (enumTranslations) {
              metadataDataService.setMetadataTranslation(enumTranslations);
            }

            return i18nDataService.getLanguageDTOList({
              apiUrl: environment.apiUrl + MGMT_GATEWAY,
            });
          }),
          switchMap((languages: IWHLanguageDTO[]) => {
            // SET LANGUAGES
            const featureConfigMap: Map<string, boolean> =
              loginDataService.featureConfigMap$.value;

            const langs: string[] = [];
            const languageDOMList: WHLayoutLanguageDOM[] = [];
            // SANITIZE
            for (let langIndex = 0; langIndex < languages.length; langIndex++) {
              const language: IWHLanguageDTO = languages[langIndex];

              const featureKey: string = `language.${language.key}.enabled`;
              const enabled: boolean = featureConfigMap.has(featureKey)
                ? featureConfigMap.get(featureKey)
                : false;

              const translateKey: string = `app.language.${language.key}.label`;
              const layoutLanguage: WHLayoutLanguageDOM =
                new WHLayoutLanguageDOM({
                  featureKey,
                  enabled,
                  translateKey,
                  icon: WHIconENUM.LanguageIcon,
                }).fromLanguageDTO(language);
              langs.push(language.key);
              languageDOMList.push(layoutLanguage);
            }

            // SET ACTIVE LANGUAGES - TODO
            loginDataService.setActiveLanguages(languageDOMList as any);
            // REGISTER LANGUAGES
            const jargonToAdd: string[] = i18nJargon.filter((jargonKey) => {
              return jargonKey.includes(
                loginDataService.activeTenantDOM$.value.token
              );
            });
            // console.log(jargonToAdd);
            translateService.addLangs(langs.concat(jargonToAdd));
            setRegisterLocaleData(langs);

            // SET LAYOUT LANGUAGE LIST
            layoutConfigService.setLayoutLanguageDOMList(languageDOMList);

            // GET TRANSLATION OBJECTS
            const reqArray$: Observable<any>[] = [];
            langs.concat(jargonToAdd).forEach((languageKey: string) => {
              const req: Observable<any> =
                i18nDataService.getClientTranslations(
                  {
                    apiUrl: environment.apiUrl + MGMT_GATEWAY,
                  },
                  new RequestTranslationCommand({
                    languageKey,
                    tenantToken: loginDataService.activeTenantDOM$.value.token,
                    removePrefix: true,
                  }).initFlowClientTranslationKeys()
                );
              reqArray$.push(req);
            });

            return forkJoin(reqArray$);
          }),
          map((respArray: any[]) => {
            respArray.forEach((translations) => {
              Object.entries(translations).forEach((property: any[]) => {
                const lang = property[0];
                const translation = property[1];

                // Apply translation to TranslateService
                translateService.setTranslation(lang, translation, true);

                // Apply translation to Bryntum locale
                extendBryntumLocale(lang, translation);
              });
            });

            this.setLanguageData(loginDataService, translateService);
            return true;
          })
        )
        .toPromise()
        .then((value: any) => {
          this.ngxLoggerService.setClientConfig(this.clientLoggerConfig);
          resolveInit();
          this.splashScreenElem.remove();
        });
    });
  }

  private loading(authService: AuthService, loading: boolean): any {
    return authService.isLoading$.pipe(
      switchMap((innerLoading: boolean) => {
        if (loading) {
          return this.loading(authService, innerLoading);
        }
        return of(innerLoading);
      }),
      take(1)
    );
  }

  private setLanguageData(
    loginDataService: WHLoginDataService,
    translateService: TranslateService
  ) {
    const activeUserDOM: WHActiveUserDOM =
      loginDataService.activeUserDOM$.value;
    const activeTenantDOM: WHActiveTenantDOM =
      loginDataService.activeTenantDOM$.value;
    const browserLang: string = translateService.getBrowserLang();
    let userPrefLang: string = localStorage.getItem('whlang');

    loginDataService.featureConfigMap$.subscribe((s) => {
      if (
        !s.get(LANG_CHECK[userPrefLang]) &&
        !s.get(LANG_CHECK[activeUserDOM.language.key])
      ) {
        userPrefLang = 'en';
      }
    });

    if (userPrefLang) {
      translateService.setDefaultLang(userPrefLang);
      translateService.use(userPrefLang);
      applyBryntumLocale(userPrefLang);
      moment.locale(userPrefLang);
    } else if (activeUserDOM.language && activeUserDOM.language.key) {
      translateService.setDefaultLang(activeUserDOM.language.key);
      const lngKey: string = translateService.langs?.includes(
        activeUserDOM.language.key + '_' + activeTenantDOM.token
      )
        ? activeUserDOM.language.key + '_' + activeTenantDOM.token
        : activeUserDOM.language.key;

      translateService.use(lngKey);
      applyBryntumLocale(activeUserDOM.language.key);
      moment.locale(activeUserDOM.language.key);
    } else {
      if (translateService.langs?.includes(browserLang)) {
        translateService.setDefaultLang(browserLang);
        const lngKey: string = translateService.langs.includes(
          browserLang + '_' + activeTenantDOM.token
        )
          ? browserLang + '_' + activeTenantDOM.token
          : browserLang;
        translateService.use(lngKey);
        applyBryntumLocale(browserLang);
        moment.locale(browserLang);
      } else {
        translateService.setDefaultLang('en');
        translateService.use('en');
        applyBryntumLocale('en');
        moment.locale('en');
      }
    }
  }

  private setTokenData(
    tokenObj: any,
    loginDataService: WHLoginDataService,
    layoutConfigService: WHLayoutConfigService
  ): void {
    // SAVE PERMISSIONS STATE
    const permissions: string[] = tokenObj.permissions;
    loginDataService.setPermissions(permissions);
    // console.log(loginDataService.permissions$.value);
  }

  private setLoginData(
    userDTO: IWHUserDTO,
    loginDataService: WHLoginDataService,
    layoutConfigService: WHLayoutConfigService
  ): void {
    this.clientLoggerConfig.loggedIn = userDTO.id;
    this.clientLoggerConfig.loggerName =
      userDTO.firstName + ' ' + userDTO.lastName;

    // SAVE USER STATE
    loginDataService.platformAdmin = userDTO.platformAdmin;
    loginDataService.partnerAdmin = userDTO.partnerAdmin;
    loginDataService.setActiveUser(new WHActiveUserDOM(userDTO));

    // SET LOGGED USER
    layoutConfigService.setLayoutLoggedInUserDOM(
      new WHLayoutLoggedInUserDOM(userDTO)
    );
  }

  private logout(authService: AuthService) {
    localStorage.removeItem('leftOffPath');
    authService.logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }

  private setTenantData(
    tenantDTO: IWHTenantDTO,
    loginDataService: WHLoginDataService,
    layoutConfigService: WHLayoutConfigService,
    themeConfigService: WHThemeConfigService
  ): void {
    this.clientLoggerConfig.tenant = tenantDTO.id;

    // SAVE TENANT STATE
    loginDataService.setActiveTenant(new WHActiveTenantDOM(tenantDTO));

    // INIT LAYOUT
    layoutConfigService.setRegistredLayoutConfigDOMList(
      registredLayoutConfigDOMList
    );
    layoutConfigService.setActiveLayoutConfigDOM(
      new WHLayoutConfigDOM().initConfigType(WHLayoutConfigTypeENUM.ALPHA)
    );

    // INIT THEME
    layoutConfigService.tenantCustomization(tenantDTO.customization);
    themeConfigService.tenantCustomization(
      tenantDTO.customization,
      themeConfig
    );
  }

  private setFeatureConfigData(
    featureConfigDTOList: IWHFeatureConfigDTO[],
    loginDataService: WHLoginDataService,
    layoutConfigService: WHLayoutConfigService
  ): void {
    // SET FEATURE CONFIG DATA
    loginDataService.setFeatureConfigMap(featureConfigDTOList);

    // FEATURE CONFIG MAP
    const featureConfigMap: Map<string, boolean> =
      loginDataService.featureConfigMap$.value;
    const devFeatureConfigMap: Map<string, boolean> =
      environment.devFeatureConfigMap;
    // console.log(featureConfigMap);
    // console.log(devFeatureConfigMap);

    Array.from(devFeatureConfigMap.keys()).forEach((key: string) => {
      const value: boolean = devFeatureConfigMap.get(key);
      featureConfigMap.set(key, value);
    });
    loginDataService.featureConfigMap$.next(featureConfigMap);

    // NAVIGATION
    const sanitizedNavItems: WHLayoutNavItemDOM[] = sanitizeFeatureConfig(
      layoutNavItemDOMList,
      featureConfigMap,
      loginDataService.activeUserDOM$.value.department?.subcontractor
    ) as WHLayoutNavItemDOM[];

    // CHECK PERMISSIONS
    // const permissions = loginDataService.permissions$.value;
    // sanitizedNavItems.forEach((navItem: WHLayoutNavItemDOM) => {
    //   if (navItem.enabled && navItem.permissionKey) {
    //     if ((permissions as string[]).includes(navItem.permissionKey)) {
    //       navItem.enabled = true;
    //     } else {
    //       navItem.enabled = false;
    //     }
    //   }
    //   if (navItem.children && navItem.children.length > 0) {
    //     navItem.children.forEach((childNavItem: WHLayoutNavItemDOM) => {
    //       if (childNavItem.enabled && childNavItem.permissionKey) {
    //         if ((permissions as string[]).includes(childNavItem.permissionKey)) {
    //           childNavItem.enabled = true;
    //         } else {
    //           childNavItem.enabled = false;
    //         }
    //       }
    //     })
    //   }
    // });

    layoutConfigService.setLayoutNavItemDOMList(sanitizedNavItems);
    // SET LOGGED USER NAV ITEMS - TODO ADD PERMISIONS
    const sanitizedUserNavItems: WHLayoutNavItemDOM[] = sanitizeFeatureConfig(
      layoutLoggedInUserNavItemDOMList,
      environment.devFeatureConfigMap
    ) as WHLayoutNavItemDOM[];
    layoutConfigService.setLayoutLoggedInUserNavItemDOMList(
      sanitizedUserNavItems
    );

    // UI ELEMENTS
    sanitizePagesUi(featureConfigMap);
  }

  private initializeEnvironment(environmentModel: ENV_SETTINGS) {
    if (environmentModel.STAGE) {
      environment.production = true;
      environment.stage = environmentModel.STAGE;
    }

    if (environmentModel.API_URL) environment.apiUrl = environmentModel.API_URL;

    if (environmentModel.MGMT_URL)
      environment.mgmtUrl = environmentModel.MGMT_URL;
    if (environmentModel.FLOW_URL)
      environment.flowUrl = environmentModel.FLOW_URL;
    if (environmentModel.CALL_URL)
      environment.callUrl = environmentModel.CALL_URL;

    if (environmentModel.AUTH0_DOMAIN && environmentModel.AUTH0_CLIENT_ID) {
      environment.auth0Config.domain = environmentModel.AUTH0_DOMAIN;
      environment.auth0Config.clientId = environmentModel.AUTH0_CLIENT_ID;
    }
    if (environmentModel.GOOGLE_ANALYTICS_ID)
      environment.googleAnalyticsKey = environmentModel.GOOGLE_ANALYTICS_ID;
    if (environmentModel.INSTRUMENTATION_KEY)
      environment.instrumentationKey = environmentModel.INSTRUMENTATION_KEY;
    if (environmentModel.GOOGLE_MAPS_API_KEY)
      environment.googleMapsApiKey = environmentModel.GOOGLE_MAPS_API_KEY;

    console.log('env', environment);
  }

  checkIfNullValues(environmentModel: ENV_SETTINGS): boolean {
    let isNull = false;
    //Check if any values are null or empty return true if so
    Object.entries(environmentModel).forEach((property: any[]) => {
      if (property[1] === null || property[1] === '') {
        isNull = true;
      }
    });
    return isNull;
  }

  private playSplashScreen(type: string, config?: SplashScreenConfigDOM): void {
    // ADD Splash Screen Element
    this.splashScreenElem = this.document.body.querySelector(
      '#workheld-splash-screen'
    );

    if (!this.splashScreenElem) return;
    this.splashScreenElem.innerHTML = initSplashScreen(type, config);

    // CREATE LOADING SPINNER
    const player = this.animationBuilder
      .build([
        // style({
        //   // opacity: 1
        // }),
        // animate('2000ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
        //   // opacity: 0
        // }))
      ])
      .create(this.splashScreenElem);

    // player.onDone(() => this.splashScreenElem.remove());
    player.play();
  }
}
