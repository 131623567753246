// DEV DEP
import * as moment from 'moment';

// HELPERS
import {
  IWHFlowMetadataTranslationModel,
  IWHSkillDTO,
  removeObjectNullFields,
  setResetFields,
  WHFeatureKeyENUM,
  WHIconENUM,
  WHLoginDataService,
  WHMetadataDataService,
  WHNgxToastrENUM,
} from '@workheld/workheld-shared-lib';

// ANGULAR
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, inject } from '@angular/core';

// ANGULAR MATERIAL
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';

// SERVICES
import { WorkStepAsyncService } from 'src/app/app-services-async/work-step-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { WHNgxToastrService } from '@workheld/workheld-shared-lib';
import { WHWorkStepCreateEditDialogActionData } from '../app-dialog.service';
import { FormReferenceService } from 'src/app/app-services-helper/form-reference.service';

// RxJS 6
import { forkJoin, Observable, Subscription, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

// DIALOGS
import { MatDialogConfirmEntryComponent } from '../mat-dialog-confirm-entry/mat-dialog-confirm-entry.component';

// MODELS
import { IWHUIConfigModel } from '@workheld/workheld-shared-lib';
import { WHWorkStepDOM, IWHWorkStepDTO } from '@workheld/workheld-shared-lib';
import { WHAssignedChip } from './w-h-work-step-assignment-chip/w-h-work-step-assignment-chip.component';
import { workItemUiConfig$ } from '@workheld/workheld-shared-lib';
import { SkillDataService } from 'src/app/app-services-async/skill-data.service';
import { sortEnumObject } from 'src/app/app-shared/helpers/enumSort.helper';

@Component({
  selector: 'ng-bee-mat-dialog-work-step-create-edit-form-entry',
  templateUrl: './mat-dialog-work-step-create-edit-form-entry.component.html',
  styleUrls: ['./mat-dialog-work-step-create-edit-form-entry.component.scss'],
})
export class MatDialogWorkStepCreateEditFormEntryComponent
  implements OnInit, OnDestroy
{
  // DATA
  public workStepDOM: WHWorkStepDOM;
  public workStepFormGroup: FormGroup;
  public workStepStatusArray: number[] = [];
  isUrgentOrderEnabled: boolean;

  // STATE
  private workerUnassignPayload: string[] = [];
  private toolUnassignPayload: string[] = [];
  private skillUnassignPayload: string[] = [];

  public saving: boolean = false;

  private initStartDate: boolean = false;
  private initEndDate: boolean = false;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  // DATE VALUES
  public tempFormGroup = new FormGroup({
    dateSlotForm: new FormControl(),
    timeSlotForm: new FormControl(),
    enddateSlotForm: new FormControl(),
    endtimeSlotForm: new FormControl(),
  });
  WORK_STEP_RESOLUTION_STATUSES = {};

  savedStartDate: string;
  savedEndDate: string;

  // FEATURE KEYS
  hasSkillManagementEnabled = inject(
    WHLoginDataService
  ).featureConfigMap$.value.get(WHFeatureKeyENUM.SKILL_MANAGEMENT);

  hasWorkingTimeEnabled = inject(
    WHLoginDataService
  ).featureConfigMap$.value.get(WHFeatureKeyENUM.PLANNED_WORK);

  constructor(
    private workStepAsyncService: WorkStepAsyncService,
    private dataModelHelperService: DataModelHelperService,
    private ngxToastrService: WHNgxToastrService,
    private matDialog: MatDialog,
    private metadataDataService: WHMetadataDataService,
    private skillDataService: SkillDataService,
    public matDialogRef: MatDialogRef<MatDialogWorkStepCreateEditFormEntryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      workOrderId: string;
      workStepDOM: WHWorkStepDOM;
    },
    private loginDataService: WHLoginDataService,
    private formReferenceService: FormReferenceService
  ) {}

  ngOnInit() {
    this.workStepDOM = this.data.workStepDOM;
    this.workStepFormGroup = this.workStepAsyncService.initWorkStepFormGroup();

    this.workStepStatusArray = workItemUiConfig$.value.find(
      (enumModelDOM: IWHUIConfigModel) => {
        return (
          enumModelDOM.featureKey === WHFeatureKeyENUM.CORE_WORK_FLOW_WORK_STEP
        );
      }
    ).calculatedStatusId;
    if (this.data.workStepDOM) {
      this.savedStartDate = this.data.workStepDOM.startDate;
      this.savedEndDate = this.data.workStepDOM.endDate;
      this.workStepFormGroup.patchValue(this.data.workStepDOM);
      this.workStepFormGroup.controls['extId'].disable();
      this.workStepFormGroup.controls['workOrderId'].disable();
      this.workStepFormGroup.controls['workObjectId'].disable();

      //if (this.workStepActive) this.workStepFormGroup.disable();

      this.tempFormGroup.patchValue({
        dateSlotForm: this.initDate(this.data.workStepDOM.startDate),
        timeSlotForm: this.initHourAndMinutes(this.data.workStepDOM.startDate),
        enddateSlotForm: this.initDate(this.data.workStepDOM.endDate),
        endtimeSlotForm: this.initHourAndMinutes(this.data.workStepDOM.endDate),
      });
    } else {
      this.workStepFormGroup.controls['workOrderId'].patchValue(
        this.data.workOrderId
      );
    }

    // FEATURE CONFIG
    this.subscriptions.push(
      this.loginDataService.featureConfigMap$
        .pipe(
          map((features: Map<string, boolean>) => {
            this.isUrgentOrderEnabled = features.get(
              WHFeatureKeyENUM.CORE_WORK_FLOW_WORK_STEP_URGENT_ORDER
            );
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.metadataDataService.metadataTranslation$.subscribe(
        (metadataTranslation: IWHFlowMetadataTranslationModel) => {
          this.WORK_STEP_RESOLUTION_STATUSES = sortEnumObject(metadataTranslation.WORK_STEP_RESOLUTION_STATUS);
        }
      )
    );

    this.subscribeFormControl('dateSlotForm');
    this.subscribeFormControl('timeSlotForm');
    this.subscribeFormControl('enddateSlotForm');
    this.subscribeFormControl('endtimeSlotForm');
  }

  private subscribeFormControl(slotFormName: string) {
    this.subscriptions.push(
      this.tempFormGroup
        .get(slotFormName)
        .valueChanges.pipe(
          distinctUntilChanged(
            (a, b) => JSON.stringify(a) === JSON.stringify(b)
          )
        )
        .subscribe((value: any) => {
          this.dateSwitchControl(slotFormName, value);
        })
    );
  }

  private dateSwitchControl(slotFormName: string, data: any) {
    switch (slotFormName) {
      case 'dateSlotForm':
        this.updateFormControl(
          data,
          '00:00',
          'dateSlotForm',
          'timeSlotForm',
          'startDate'
        );
        break;
      case 'timeSlotForm':
        this.updateFormControl(
          data,
          null,
          'timeSlotForm',
          'dateSlotForm',
          'startDate'
        );
        break;
      case 'enddateSlotForm':
        this.updateFormControl(
          data,
          '23:59',
          'enddateSlotForm',
          'endtimeSlotForm',
          'endDate'
        );
        break;
      case 'endtimeSlotForm':
        this.updateFormControl(
          data,
          null,
          'endtimeSlotForm',
          'enddateSlotForm',
          'endDate'
        );
        break;
      default:
        break;
    }
  }

  private updateFormControl(
    value: any,
    defaultTime: any,
    primaryFormControl: string,
    secondaryFormControl: string,
    finalControl: string
  ) {
    if (value) {
      // Is From Date
      let date: Date, time: any;
      if (defaultTime) {
        date = value;
        time = this.tempFormGroup.controls[secondaryFormControl].value;
        if (!time) {
          this.tempFormGroup.controls[secondaryFormControl].patchValue(
            defaultTime
          );
        }
        this.markFormAsDirty(primaryFormControl);
      } else {
        date = this.tempFormGroup.controls[secondaryFormControl].value;
        time = value;
      }
      const finalVersion = time
        ? moment(date)
            .set({ hour: time.slice(0, 2), minute: time.slice(-2) })
            .format()
        : moment(date)
            .set({
              hour: defaultTime.slice(0, 2),
              minute: defaultTime.slice(-2),
            })
            .format();
      this.workStepFormGroup.controls[finalControl].patchValue(finalVersion);

      setTimeout(() => {
        //Fetch errors from tempFormGroup and set them to workStepFormGroup
        const errors = this.tempFormGroup.get(primaryFormControl).errors;
        this.workStepFormGroup.setErrors(errors);
        //Check if date is before end date and set current input to invalid
        this.checkIfDateIsBeforeEndDate(primaryFormControl);
      }, 50);
    } else {
      this.workStepFormGroup.controls[finalControl].patchValue(undefined);
    }
  }

  private markFormAsDirty(primaryFormControl: string) {
    if (primaryFormControl === 'dateSlotForm') {
      if (this.initStartDate) {
        this.workStepFormGroup.markAsDirty();
      }
      this.initStartDate = true;
    } else if (primaryFormControl === 'enddateSlotForm') {
      if (this.initEndDate) {
        this.workStepFormGroup.markAsDirty();
      }
      this.initEndDate = true;
    }
  }

  public handleSubmit(): void {
    // CHECK STATE
    if (this.workStepFormGroup.invalid) {
      return;
    }
    if (!this.workStepFormGroup.dirty) {
      if (
        this.workStepFormGroup.controls['startDate'].value ===
          this.savedStartDate &&
        this.workStepFormGroup.controls['endDate'].value === this.savedEndDate
      ) {
        this.closeDialog();
        return;
      }
    }
    // TODO - RESET FIELDS
    const workStepPayload: IWHWorkStepDTO | any = this.workStepFormGroup.value;

    // SUBMIT ACTION
    workStepPayload.id
      ? this.updateWorkStep(workStepPayload)
      : this.createWorkStep(workStepPayload);
  }

  private createWorkStep(workStepPayload: IWHWorkStepDTO): void {
    this.saving = true;
    const sanitizedPayload: IWHWorkStepDTO =
      removeObjectNullFields(workStepPayload);

    this.subscriptions.push(
      this.workStepAsyncService
        .createWorkStep(sanitizedPayload)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.saving = false;
            return throwError(error);
          })
        )
        .subscribe((workStepDTO: IWHWorkStepDTO) => {
          this.workStepDOM =
            this.dataModelHelperService.initWorkStepDOM(workStepDTO);
          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey: 'workstep.ui.createsuccess.notification',
          });
          this.formReferenceService.unsavedChanges = false;
          this.matDialogRef.close({
            actionType: 'CREATE',
            workStepDOM: this.workStepDOM,
          } as WHWorkStepCreateEditDialogActionData);
          this.saving = false;
        })
    );
  }

  private updateWorkStep(workStepPayload: IWHWorkStepDTO): void {
    if (this.workStepDOM.skills) {
      const skillIds = this.workStepDOM.skills.map((skill: IWHSkillDTO) => {
        return skill.id;
      });
      workStepPayload.skillIds = skillIds;
    } else {
      workStepPayload.skillIds = [];
    }

    const sanitizedWorkStepPayload: IWHWorkStepDTO =
      setResetFields(workStepPayload);

    this.saving = true;

    // UNASSIGNMENT HANDLING
    const unassignResources: Array<Observable<any>> = [];
    if (this.workerUnassignPayload.length > 0) {
      const unassignWorker: Observable<any> =
        this.workStepAsyncService.unassignWorkerList({
          workStepId: this.workStepDOM.id,
          workerIds: this.workerUnassignPayload,
        });
      unassignResources.push(unassignWorker);
    }

    if (this.toolUnassignPayload.length > 0) {
      const unassignTool: Observable<any> =
        this.workStepAsyncService.unassignToolList({
          workStepId: this.workStepDOM.id,
          toolIds: this.toolUnassignPayload,
        });
      unassignResources.push(unassignTool);
    }

    // tslint:disable-next-line: deprecation
    if (unassignResources.length > 0) {
      this.subscriptions.push(
        forkJoin(unassignResources)
          .pipe(
            switchMap((result: any[]) => {
              return this.workStepAsyncService
                .updateWorkStep(workStepPayload.id, sanitizedWorkStepPayload)
                .pipe(
                  map((workStepDTO: IWHWorkStepDTO) => {
                    return workStepDTO;
                  }),
                  catchError((error: HttpErrorResponse) => {
                    this.saving = false;
                    return throwError(error);
                  })
                );
            })
          )
          .subscribe((workStepDTO: IWHWorkStepDTO) => {
            this.workStepDOM =
              this.dataModelHelperService.initWorkStepDOM(workStepDTO);
            this.ngxToastrService.displayToastr({
              toastrType: WHNgxToastrENUM.SUCCESS,
              messageTranslateKey: 'workstep.ui.updatesuccess.notification',
            });
            this.formReferenceService.unsavedChanges = false;
            // remove skills from
            this.skillDataService.removeAssignedSkills(
              this.skillUnassignPayload
            );
            this.matDialogRef.close({
              actionType: 'EDIT',
              workStepDOM: this.workStepDOM,
            } as WHWorkStepCreateEditDialogActionData);
            this.saving = false;
          })
      );
      return;
    }

    this.subscriptions.push(
      this.workStepAsyncService
        .updateWorkStep(workStepPayload.id, sanitizedWorkStepPayload)
        .pipe(
          map((result) => {
            return result;
          }),
          catchError((error: HttpErrorResponse) => {
            this.saving = false;
            return throwError(error);
          })
        )
        .subscribe((workStepDTO: IWHWorkStepDTO) => {
          this.workStepDOM =
            this.dataModelHelperService.initWorkStepDOM(workStepDTO);
          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey: 'workstep.ui.updatesuccess.notification',
          });
          this.skillDataService.removeAssignedSkills(this.skillUnassignPayload);
          this.matDialogRef.close({
            actionType: 'EDIT',
            workStepDOM: this.workStepDOM,
          } as WHWorkStepCreateEditDialogActionData);
          this.saving = false;
        })
    );
  }

  private initDate(value: any) {
    if (value) {
      return new Date(value);
    } else {
      return null;
    }
  }

  private initHourAndMinutes(value: any) {
    if (value) {
      return moment(value).format('HH:mm');
    } else {
      return null;
    }
  }

  public async deleteWorkStep() {
    const confirmEntryDialog = this.matDialog.open(
      MatDialogConfirmEntryComponent,
      {
        width: '50%',
        data: { translateCode: 'deleteworkstep' },
      } as MatDialogConfig
    );

    const confirm = await confirmEntryDialog
      .afterClosed()
      .toPromise()
      .then((res: any) => {
        return res as any;
      });

    if (!confirm) {
      return;
    }

    this.subscriptions.push(
      this.workStepAsyncService
        .deleteWorkStepById(this.workStepDOM.id)
        .subscribe((res: any) => {
          this.matDialogRef.close({
            actionType: 'DELETE',
            workStepDOM: this.workStepDOM,
          } as WHWorkStepCreateEditDialogActionData);
        })
    );
  }

  public closeDialog() {
    if (this.workStepFormGroup.dirty) {
      const ref = this.formReferenceService.createDialog();
      this.subscriptions.push(
        ref
          .pipe(
            tap((canDiscard) => {
              if (canDiscard) {
                this.matDialogRef.close();
              }
            })
          )
          .subscribe()
      );
    } else {
      this.matDialogRef.close();
    }
  }

  public resetDateField($event: any) {
    if ($event === 'start-date') {
      this.startDateFormControl.reset();
      this.startDateFormControl.markAsDirty();
    } else if ($event === 'end-date') {
      this.endDateFormControl.reset();
      this.endDateFormControl.markAsDirty();
    }
  }

  removeAssignment($event: WHAssignedChip) {
    if (!$event) {
      return;
    }
    switch ($event.targetParent) {
      case 'checklistdefinition':
        this.workStepDOM.checklistDefinitionId = null;
        this.workStepDOM.checklistDefinitionDetailURL = null;
        this.workStepFormGroup.controls['checklistDefinitionId'].reset();
        this.workStepFormGroup.controls['checklistDefinitionId'].markAsDirty();
        return;
      case 'equipment':
        this.workStepDOM.equipmentId = null;
        this.workStepDOM.equipmentDetailURL = null;
        this.workStepFormGroup.controls['equipmentId'].reset();
        this.workStepFormGroup.controls['equipmentId'].markAsDirty();
        return;
      case 'equipmentNode':
        this.workStepDOM.bomNodeId = null;
        this.workStepDOM.bomNodeDetailURL = null;
        this.workStepFormGroup.controls['bomNodeId'].reset();
        this.workStepFormGroup.controls['bomNodeId'].markAsDirty();
        return;
      case 'worker':
        this.workStepDOM.workers = this.workStepDOM.workers.filter(
          (workerId: string) => {
            return $event.id !== workerId;
          }
        );
        this.workStepDOM.employeeDetailURLs =
          this.workStepDOM.employeeDetailURLs.filter(
            (employeeDetailURL: string) => {
              return !employeeDetailURL.includes($event.id);
            }
          );
        this.workerUnassignPayload.push($event.id);
        this.workStepFormGroup.markAsDirty();
        return;
      case 'tool':
        this.workStepDOM.tools = this.workStepDOM.tools.filter(
          (toolId: string) => {
            return $event.id !== toolId;
          }
        );
        this.workStepDOM.toolDetailsURLs =
          this.workStepDOM.toolDetailsURLs.filter((toolDetailsURL: string) => {
            return !toolDetailsURL.includes($event.id);
          });
        this.toolUnassignPayload.push($event.id);
        this.workStepFormGroup.markAsDirty();
        return;
      default:
        return undefined;
    }
  }

  public unassignSkill(skillId: string) {
    if (this.workStepDOM.skills) {
      this.workStepDOM.skills = this.workStepDOM.skills.filter(
        (skill: IWHSkillDTO) => {
          return skill.id !== skillId;
        }
      );
      this.skillUnassignPayload.push(skillId);
      this.workStepFormGroup.markAsDirty();
    }
  }

  private checkIfDateIsBeforeEndDate(form: string) {
    const startDate = this.workStepFormGroup.controls['startDate'].value;
    const endDate = this.workStepFormGroup.controls['endDate'].value;
    if (startDate && endDate) {
      const startDateMoment = moment(startDate);
      const endDateMoment = moment(endDate);
      if (startDateMoment.isAfter(endDateMoment)) {
        this.workStepFormGroup.setErrors({
          invalidDate: true,
        });
        this.tempFormGroup.controls[form].setErrors({
          invalidDate: true,
        });
      } else {
        this.workStepFormGroup.setErrors(null);
        this.tempFormGroup.controls[form].setErrors(null);
      }
    }
  }

  public getResolutionStatus(rawStatus) {
    if (
      this.WORK_STEP_RESOLUTION_STATUSES &&
      !!Object.keys(this.WORK_STEP_RESOLUTION_STATUSES).length &&
      !!this.WORK_STEP_RESOLUTION_STATUSES[rawStatus]
    ) {
      return this.WORK_STEP_RESOLUTION_STATUSES[rawStatus];
    }
    return '-';
  }

  public get workStepActive() {
    return (
      this.workStepDOM &&
      (this.workStepStatusArray.includes(
        this.workStepDOM.calculatedWorkStepStatusId
      ) as boolean)
    );
  }

  public get workStepFormGroupDisabled(): boolean {
    return this.workStepFormGroup.disabled as boolean;
  }

  public get workStepFormGroupInvalid(): boolean {
    return this.workStepFormGroup.invalid as boolean;
  }

  public get workStepTitle(): string {
    return this.workStepFormGroup.controls['title'].value as string;
  }

  public get workStepExtId(): string {
    return this.workStepFormGroup.controls['extId'].value as string;
  }

  public get startDateFormControl(): FormControl {
    return this.workStepFormGroup.controls['startDate'] as FormControl;
  }

  public get endDateFormControl(): FormControl {
    return this.workStepFormGroup.controls['endDate'] as FormControl;
  }

  public get workStepIcon(): string {
    return WHIconENUM.WorkStepIcon as string;
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  public get deleteIcon(): string {
    return WHIconENUM.DeleteIcon as string;
  }

  public get dateSlotForm(): string {
    return this.tempFormGroup.controls['dateSlotForm'].value as string;
  }

  public get resolutionStatus(): string {
    return this.workStepFormGroup.controls['resolutionStatus'].value as string;
  }

  public get timeSlotForm(): string {
    return this.tempFormGroup.controls['timeSlotForm'].value as string;
  }

  public get enddateSlotForm(): string {
    return this.tempFormGroup.controls['enddateSlotForm'].value as string;
  }

  public get endtimeSlotForm(): string {
    return this.tempFormGroup.controls['endtimeSlotForm'].value as string;
  }

  public get teardownTimeMinutes(): boolean {
    const teardownTimeMinutes = this.workStepFormGroup.get(
      'teardownTimeMinutes'
    ).value;
    const type = typeof teardownTimeMinutes;
    return type === 'number';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
