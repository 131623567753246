<div class="work-object-pause-resume-mat-dialog">
    <div class="detail-card-header">
        <span whFlex class="w-h-header-icon-span">
            <i mat-card-avatar class="workheld-icons header-icon" [innerHtml]="cancelIcon"></i>
        </span>
        <span whFlex class="w-h-header-span">
            <div class="single-header">
                {{ (workObjectPaused ? 'workobject.ui.resume.header.label' : 'workobject.ui.pause.header.label') | translate }}
            </div>
        </span>
    </div>
    <div class="work-object-pause-resume-mat-dialog-body">
        <form [formGroup]="paylaodFormGroup">
            <div fxLayout="column" fxLayoutAlign="left stretch" fxLayoutGap="20px">
                <div style="padding-top: 6px;">
                    <div *ngIf="!workObjectPaused">
                        <mat-form-field class="data">
                            <mat-label>{{ (workObjectPaused ? 'workobject.model.resume.reason' : 'workobject.model.pause.reason') | translate }}</mat-label>
                            <mat-select formControlName="reason" required>
                                <mat-option *ngFor="let pauseReason of PAUSE_REASON" [value]="(pauseReason?.translateKey | translate)">
                                    {{ (pauseReason?.translateKey | translate) }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="workObjectPaused">
                        <mat-form-field class="data">
                            <mat-label>{{ (workObjectPaused ? 'workobject.model.resume.reason' : 'workobject.model.pause.reason') | translate }}</mat-label>
                            <mat-select formControlName="reason" required>
                                <mat-option *ngFor="let resumeReason of RESUME_REASON" [value]="(resumeReason?.translateKey | translate)">
                                    {{ (resumeReason?.translateKey | translate) }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div>
                <mat-form-field whFlex>
                    <mat-label>{{ 'workobject.model.pauseresume.comment' | translate }}</mat-label>
                    <textarea 
                        matInput 
                        [rows]="10" 
                        [required]="false"
                        [formControlName]="'comment'"></textarea>
                </mat-form-field>
            </div>
        </form>
        <div class="dialog-action-wrapper" mat-dialog-actions>
            
            <button
                class="w-h-default-button dialog-button mat-elevation-z2"
                mat-flat-button
                type="button"
                tabindex="-1"
                (click)="closeDialog()">
                <i class="workheld-icons" [innerHTML]="cancelIcon"></i> 
                {{ ('app.cancel.label') | translate | uppercase }}
            </button>
            <button
                color="accent"
                class="dialog-button mat-elevation-z2"
                mat-flat-button
                type="button"
                tabindex="-1"
                [disabled]="isFormGroupInvalid"
                (click)="handleSubmit()">
                <i class="workheld-icons" [innerHTML]="saveIcon"></i> 
                <span>
                    {{ ('app.save.label') | translate | uppercase }}
                </span>
            </button>
        </div>
    </div>
</div>
