<div class="contact-mat-dialog">
  <div class="detail-card-header flex--row">
    <span class="w-h-header-icon-span">
      <i
        mat-card-avatar
        class="workheld-icons header-icon"
        [innerHtml]="userIcon"
      ></i>
    </span>
    <span class="w-h-header-span">
      <div class="single-header">
        {{ (firstname ? firstname : "") + " " + (lastname ? lastname : "") }}
      </div>
    </span>
  </div>
  <div class="contact-mat-dialog-body">
    <form [formGroup]="contactFormGroup">
      <div class="flex--row gap--16">
        <span class="flex-1">
          <mat-form-field>
            <mat-label>{{ "contact.model.salutation" | translate }}</mat-label>
            <mat-select formControlName="salutation">
              <mat-option
                *ngFor="let salutation of CONTACT_SALUTATION"
                [value]="salutation.id"
              >
                {{ salutation.translateKey | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span class="flex-1">
          <mat-form-field>
            <mat-label>{{ "contact.model.firstname" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'contact.model.firstname' | translate }}"
              formControlName="firstname"
              required
              autocomplete="off"
            />
          </mat-form-field>
        </span>
        <span class="flex-1">
          <mat-form-field>
            <mat-label>{{ "contact.model.lastname" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'contact.model.lastname' | translate }}"
              formControlName="lastname"
              required
              autocomplete="off"
            />
          </mat-form-field>
        </span>
      </div>
      <div class="flex--row gap--16">
        <span class="flex-1">
          <mat-form-field>
            <mat-label>{{ "contact.model.position" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'contact.model.position' | translate }}"
              formControlName="position"
              autocomplete="off"
            />
          </mat-form-field>
        </span>
        <span class="flex-1">
          <mat-form-field>
            <mat-label>{{ "contact.model.company" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'contact.model.company' | translate }}"
              formControlName="company"
              autocomplete="off"
            />
          </mat-form-field>
        </span>
        <span class="flex-1">
          <mat-form-field>
            <mat-label>{{ "contact.model.mail" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'contact.model.mail' | translate }}"
              formControlName="mail"
              autocomplete="off"
            />
          </mat-form-field>
        </span>
      </div>
      <div class="flex--r-fs gap--16">
        <div class="flex-1">
          <div class="w-h-title">
            {{ "contact.model.additional" | translate }}
          </div>
          <mat-form-field>
            <mat-label>{{ "contact.model.notes" | translate }}</mat-label>
            <textarea
              rows="4"
              matInput
              style="height: 108px"
              placeholder="{{ 'contact.model.notes' | translate }}"
              formControlName="notes"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="flex-1" style="flex-direction: column">
          <div class="w-h-title">{{ "contact.model.mobile" | translate }}</div>
          <div class="flex--r-fs gap--16">
            <w-h-autocomplete-static
              style="width: 130px"
              [label]="'contact.model.countrycode' | translate"
              [valueFormControl]="contactFormGroup.get('countryCodeMobile')"
              [optionsList]="countryCodes"
              [optionLabel]="'dial_code'"
              [optionValue]="'dial_code'"
              [optionIcon]="'flag_code'"
            ></w-h-autocomplete-static>
            <mat-form-field class="flex-2">
              <mat-label>{{ "contact.model.phone" | translate }}</mat-label>
              <input
                matInput
                placeholder="{{ 'contact.model.phone' | translate }}"
                formControlName="mobilePhone"
                autocomplete="off"
              />
              <mat-error
                *ngIf="contactFormGroup.controls['mobilePhone'].errors?.['pattern']"
              >
                {{ "ui.error.numbersOnly" | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="w-h-title">
            {{ "contact.model.landline" | translate }}
          </div>
          <div class="flex--r-fs gap--16">
            <w-h-autocomplete-static
              style="width: 130px"
              [label]="'contact.model.countrycode' | translate"
              [valueFormControl]="contactFormGroup.get('countryCodeLandLine')"
              [optionsList]="countryCodes"
              [optionLabel]="'dial_code'"
              [optionValue]="'dial_code'"
              [optionIcon]="'flag_code'"
            ></w-h-autocomplete-static>
            <mat-form-field>
              <mat-label>{{ "contact.model.phone" | translate }}</mat-label>
              <input
                matInput
                placeholder="{{ 'contact.model.phone' | translate }}"
                formControlName="landLinePhone"
                autocomplete="off"
              />
              <mat-error
                *ngIf="contactFormGroup.controls['landLinePhone'].errors?.['pattern']"
              >
                {{ "ui.error.numbersOnly" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="dialog-action-wrapper" mat-dialog-actions>
    <button
      *ngIf="contactDTO?.id"
      class="w-h-default-button dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      (click)="deleteContact()"
    >
      <i class="workheld-icons" [innerHTML]="deleteIcon"></i>
      {{ "app.delete.label" | translate | uppercase }}
    </button>
    <button
      class="w-h-default-button dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      (click)="closeDialog()"
    >
      <i class="workheld-icons" [innerHTML]="cancelIcon"></i>
      {{ "app.cancel.label" | translate | uppercase }}
    </button>
    <button
      color="accent"
      [disabled]="contactFormGroupInvalid"
      class="dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      (click)="handleSubmit()"
    >
      <i class="workheld-icons" [innerHTML]="saveIcon"></i>
      {{ "app.save.label" | translate | uppercase }}
    </button>
  </div>
</div>
