// ANGULAR
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';

// ANGULAR MATERIAL
import { PageEvent } from '@angular/material/paginator';

// SERVICES
import { CheckInstanceFileAsyncService } from 'src/app/app-services-async/check-instance-file-async.service';
import { WHFileAsyncService } from 'src/app/app-services-async/w-h-file-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { AppDialogService } from 'src/app/app-dialogs/app-dialog.service';

// RxJS 6
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// MODELS
import { IWHFileDTO, WHIconENUM } from '@workheld/workheld-shared-lib';
import { IWHCheckInstanceFileDTO, WHCheckInstanceFileDOM } from '@workheld/workheld-shared-lib';
import { IWHCheckInstanceDTO } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-checklist-instance-file-img-gallery',
  templateUrl: './checklist-instance-file-img-gallery.component.html',
  styleUrls: ['./checklist-instance-file-img-gallery.component.scss']
})
export class ChecklistInstanceFileImgGalleryComponent implements OnInit, OnChanges, OnDestroy {

  // INPUT
  @Input() checklistInstanceId: string;
  @Input() checkDtoList: IWHCheckInstanceDTO[];

  // DATA VAR
  public checkInstanceFileDomArray: WHCheckInstanceFileDOM[] = [];
  public checkInstanceFileDomDisplayPage: WHCheckInstanceFileDOM[] = [];

  // STATE
  public loading: boolean = false;
  public loaded: boolean = false;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    private checkInstanceFileAsyncService: CheckInstanceFileAsyncService,
    private workheldFileAsyncService: WHFileAsyncService,
    private dataModelHelperService: DataModelHelperService,
    private appDialogService: AppDialogService
  ) { }

  ngOnInit() {
    // console.log(this.checkDtoList);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.checklistInstanceId && changes.checklistInstanceId.currentValue) {
      this.loading = true;
      this.subscriptions.push(this.checkInstanceFileAsyncService.getChecklistInstanceFilesByChecklistInstanceId(changes.checklistInstanceId.currentValue)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.loading = false;
            this.loaded = true;
            return throwError(error);
          })
        )
        .subscribe((checkInstanceFileDtoList: IWHCheckInstanceFileDTO[]) => {
          // console.log(this.checkDtoList);
          // console.log(checkInstanceFileDtoList);
          if (checkInstanceFileDtoList) {
            checkInstanceFileDtoList.forEach((checklistInstanceFileDTO: IWHCheckInstanceFileDTO, index: number) => {
              const checkInstanceFileDOM: WHCheckInstanceFileDOM = this.dataModelHelperService.intiCheckInstanceFileDOM(checklistInstanceFileDTO);
              const checkDTO: IWHCheckInstanceDTO = this.checkDtoList.find((checkDTO: IWHCheckInstanceDTO) => {
                return checkDTO.id === checkInstanceFileDOM.checkInstanceId;
              });
              if (checkDTO) {
                checkInstanceFileDOM.checkSortPosition = checkDTO.sortPosition;
              } else {
                checkInstanceFileDOM.checkSortPosition = index;
              }
              this.checkInstanceFileDomArray.push(checkInstanceFileDOM);
            });
            // console.log(this.checkInstanceFileDomArray);
            this.checkInstanceFileDomDisplayPage = this.setDisplayPage(this.checkInstanceFileDomArray, 0);
            // console.log(this.checkInstanceFileDomArray);
            this.loading = false;
            this.loaded = true;
          } else {
            this.loading = false;
            this.loaded = true;
          }
        }));
    }
  }

  public filePreview(data: { file: IWHFileDTO, comment: string }) {
    this.appDialogService.openWHFilePreviewDialog(data);
  }

  public downloadFile(file: IWHFileDTO) {
    this.subscriptions.push(this.workheldFileAsyncService.downloadWHFileBlob(file)
      .subscribe((fileBlob: Blob) => {
        this.workheldFileAsyncService.saveFile(file, fileBlob);
      }));
  }

  public pageEvent($event: PageEvent) {
    this.checkInstanceFileDomDisplayPage = this.setDisplayPage(this.checkInstanceFileDomArray, $event.pageIndex);
  }

  private setDisplayPage(data: any[], pageIndex: number, length: number = 10) {
    const begin = (pageIndex * length);
    const end = begin + length;
    const page: Array<any> = data.slice(begin, end);
    return page;
  }

  public get downloadIcon(): string {
    return WHIconENUM.DownloadIcon as string;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
