import { environment } from 'src/environments/environment';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import {
  FLOW_GATEWAY,
  WHAuthHttpINTERCEPTOR,
  WHCoreModule,
  WHMainLayoutModule,
  WHMatPaginatorItilService,
  WHWebsocketINTERCEPTOR,
} from '@workheld/workheld-shared-lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutComponent } from './app-layout.component';
import { WHContentPageModule } from './w-h-content-page/w-h-content-page.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { AppInitializerService } from './app-initializer.service';
import { SERVICES_CORE, SERVICES_DATA } from './app.index.services';

import { SERVICES_HELPER } from './app-services-helper/index.helper.services';
import { ScrollbarService } from './scrollbar.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { dialogModules, SERVICES_DIALOG } from './app-dialogs/app.index.dialog';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './app-stores/index.stores';
import {
  StoreDevtoolsModule,
  StoreDevtoolsOptions,
} from '@ngrx/store-devtools';
import { IndexStoresModule } from './app-stores/index-stores.module';
import { HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { FormReferenceService } from './app-services-helper/form-reference.service';
import { AppSharedModule } from './app-shared/app-shared.module';
import { Router, RouterModule } from '@angular/router';
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';

import * as Sentry from '@sentry/angular';
import { ErrorHandlerService } from './app-services-async/w-h-error-handler.service';

function initializeApp(appInitializerService: AppInitializerService): any {
  return (): Promise<any> => {
    return appInitializerService.initializeApp();
  };
}

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  subscriptSizing: 'dynamic',
};

@NgModule({
  declarations: [AppComponent, AppLayoutComponent],
  imports: [
    WHCoreModule,
    MatMomentDateModule,
    LoggerModule.forRoot({
      serverLoggingUrl: `${environment.apiUrl + FLOW_GATEWAY}/logging`,
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false,
      enableSourceMaps: true,
    }),
    ...dialogModules,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-left',
      onActivateTick: true,
      progressBar: true,
      enableHtml: true,
      preventDuplicates: true,
      progressAnimation: 'decreasing',
      resetTimeoutOnDuplicate: false,
    }),
    GoogleMapsModule,
    IndexStoresModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    } as StoreDevtoolsOptions),
    WHMainLayoutModule,
    WHContentPageModule,
    AppRoutingModule,
    AppSharedModule,
    RouterModule,
    HttpClientModule,
  ],
  providers: [
    { provide: Window, useValue: window },
    AppInitializerService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializerService, Sentry.TraceService],
      multi: true,
    },
    [WHAuthHttpINTERCEPTOR, WHWebsocketINTERCEPTOR],
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    {
      provide: MatPaginatorIntl,
      useClass: WHMatPaginatorItilService,
    },
    SERVICES_CORE,
    SERVICES_HELPER,
    SERVICES_DATA,
    SERVICES_DIALOG,
    ScrollbarService,
    FormReferenceService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
