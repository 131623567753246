// ANGULAR
import { FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';

// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// RxJS 6
import { Subscription, throwError } from 'rxjs';

// MODELS
import {
  FLOW_GATEWAY,
  WHIconENUM,
  IWHEventLogEndpointConfigModel,
  WHEventLogDataService,
  IWHEventLogDTO,
  WHEventLogTypeENUM,
  WHEventLogSourceENUM,
  WHLoginDataService,
} from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-mat-dialog-contact-principal-entry',
  templateUrl: './mat-dialog-contact-principal-entry.component.html',
  styleUrls: ['./mat-dialog-contact-principal-entry.component.scss'],
})
export class MatDialogContactPrincipalEntryComponent
  implements OnInit, OnDestroy
{
  @Input() endpointConfig: IWHEventLogEndpointConfigModel = {
    apiUrl: environment.apiUrl + FLOW_GATEWAY,
  };

  // FORM
  public contactPrincipalFormGroup: FormGroup;

  public workObjectId: string;

  // STATE
  public saving: boolean = false;

  // MANAGE SUBSCRIPTIONS
  public subscriptions: Subscription[] = [];

  constructor(
    private eventLogDataService: WHEventLogDataService,
    private loginDataService: WHLoginDataService,
    public matDialogRef: MatDialogRef<MatDialogContactPrincipalEntryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      workObjectId: string;
    }
  ) {}

  ngOnInit() {
    // console.log(this.data);
    this.contactPrincipalFormGroup =
      this.eventLogDataService.initContactPrincipalFromGroup();

    this.workObjectId = this.data.workObjectId;
  }

  public createContactLog() {
    if (this.isFormGroupInvalid) return;

    const templatePayload: any = this.contactPrincipalFormGroup.value;

    const payload: IWHEventLogDTO = {
      comment:
        this.loginDataService.activeUserDOM$.value.formattedName +
        ': ' +
        templatePayload.message,
      eventLogType: WHEventLogTypeENUM.CONTACT_PRINCIPAL,
      workObjectId: this.workObjectId,
      eventLogSource: WHEventLogSourceENUM.USER,
      createdAt: null,
      sourceLoginDetailURL: null,
      sourceLoginId: null,
      workObjectTitle: null,
    };

    this.saving = true;

    this.eventLogDataService
      .createEventLog(this.endpointConfig, payload)
      .subscribe({
        next: (response: any) => {
          this.saving = false;
          this.matDialogRef.close(response);
        },
        error: (error: HttpErrorResponse) => {
          this.saving = false;
          throwError(error);
        },
      });
  }

  cancel() {
    this.matDialogRef.close();
  }

  public get isFormGroupInvalid(): boolean {
    return this.contactPrincipalFormGroup.invalid as boolean;
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  public get userIcon() {
    return WHIconENUM.UserIcon;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
