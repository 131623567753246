import { Injectable, signal } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, startWith } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UIService {
  isLargeScreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  disposeDashboardInfiniteScroll$ = new BehaviorSubject<boolean>(false);
  currentLocale$: BehaviorSubject<string> = new BehaviorSubject<string>('en');
  $currentLocale = signal('en');
  $isLargeScreen = signal(false);
  $isXLScreen = signal(false);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService
  ) {
    this.breakpointObserver
      .observe([Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result) => {
        const isLargeScreen = Object.values(result.breakpoints).some(
          (value) => value === true && result.breakpoints[Breakpoints.Large]
        );
        const isXLScreen = Object.values(result.breakpoints).some(
          (value) => value === true && result.breakpoints[Breakpoints.XLarge]
        );

        this.isLargeScreen$.next(isLargeScreen);
        this.$isLargeScreen.set(isLargeScreen);
        this.$isXLScreen.set(isXLScreen);
      });

    this.translateService.onLangChange.pipe(startWith('en')).subscribe(() => {
      this.currentLocale$.next(this.translateService.currentLang);
      this.$currentLocale.set(this.translateService.currentLang);
    });
  }
}
