// ANGULAR
import { FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// SERVICES
import { WorkObjectTemplateAsyncService } from 'src/app/app-services-async/work-object-template-async.service';
import {
  WHNgxToastrENUM,
  WHNgxToastrService,
} from '@workheld/workheld-shared-lib';

// REDUX
import { Store } from '@ngrx/store';
import { IAppStore } from 'src/app/app-stores/index.stores';
import {
  workheldWorkOrderMasterDataStoreFeatureKey,
  IWHWorkOrderMasterDataStore,
} from 'src/app/app-stores/w-h-work-order-stores/w-h-work-order-master-data-store/w-h-work-order-master-data-store.reducer';

// RxJS 6
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// MODELS
import { WHWorkOrderDOM, WHIconENUM } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-mat-dialog-create-work-object-template',
  templateUrl: './mat-dialog-create-work-object-template.component.html',
  styleUrls: ['./mat-dialog-create-work-object-template.component.scss'],
})
export class MatDialogCreateWorkObjectTemplateComponent
  implements OnInit, OnDestroy
{
  // FORM
  public createWorkObjectTemplateFromGroup: FormGroup;

  // DATA
  public workOrderDomList: WHWorkOrderDOM[] = [];

  // STATE
  public saving: boolean = false;

  // MANAGE SUBSCRIPTIONS
  public subscriptions: Subscription[] = [];

  constructor(
    private store: Store<IAppStore>,
    private workObjectTemplateAsyncService: WorkObjectTemplateAsyncService,
    private ngxToastrService: WHNgxToastrService,
    public matDialogRef: MatDialogRef<MatDialogCreateWorkObjectTemplateComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { workObjectId: string; workOrderDomList: WHWorkOrderDOM[] },
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // console.log(this.data);
    // INIT FORM
    this.createWorkObjectTemplateFromGroup =
      this.workObjectTemplateAsyncService.initCreateWorkObjectTemplateFromGroup();
    this.createWorkObjectTemplateFromGroup.controls['workObjectId'].patchValue(
      this.data.workObjectId
    );

    if (this.data.workOrderDomList?.length > 0) {
      this.workOrderDomList = this.data.workOrderDomList;
    } else {
      this.subscriptions.push(
        this.store
          .select(workheldWorkOrderMasterDataStoreFeatureKey)
          .subscribe(
            (workheldWorkOrderMasterDataStore: IWHWorkOrderMasterDataStore) => {
              this.workOrderDomList =
                workheldWorkOrderMasterDataStore.workOrderDomList;
            }
          )
      );
    }
  }

  public createWorkObjectTemplate() {
    if (this.isFormGroupInvalid || this.workOrderDomList.length === 0) {
      return;
    }
    const templatePayload: any = this.createWorkObjectTemplateFromGroup.value;

    this.saving = true;
    this.subscriptions.push(
      this.workObjectTemplateAsyncService
        .createWorkObjectTemplate(templatePayload)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.saving = false;
            this.changeDetectorRef.detectChanges();
            return throwError(error);
          })
        )
        .subscribe((res: any) => {
          this.saving = false;
          // console.log(res);
          this.matDialogRef.close();
          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey: 'template.ui.createsuccess.notification',
          });
        })
    );
  }

  cancel() {
    this.matDialogRef.close();
  }

  public get workOrderIcon(): string {
    return WHIconENUM.WorkOrderIcon as string;
  }

  public get projectTemplateIcon(): string {
    return WHIconENUM.ProjectTemplateIcon as string;
  }

  public get isFormGroupInvalid(): boolean {
    return this.createWorkObjectTemplateFromGroup.invalid as boolean;
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
