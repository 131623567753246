// ANGULAR
import { Component, Input, ChangeDetectorRef, SimpleChanges, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';

// SERVICES
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { IWHBomNodeDTO, WHAssetsENUM, WHDataService, WHIconENUM } from '@workheld/workheld-shared-lib';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import { IWHChecklistDefinitionDTO, WHChecklistDefinitionDOM } from '@workheld/workheld-shared-lib';
import { IWHEquipmentDTO, WHEquipmentDOM } from '@workheld/workheld-shared-lib';
import { WHWorkerDOM, IWHWorkerDTO } from '@workheld/workheld-shared-lib';
import { WHToolDOM, IWHToolDTO } from '@workheld/workheld-shared-lib';

// MODELS
export class WHAssignedChip {
  id: string;
  name: string;
  extId: string;
  avatar: string;
  icon: string;
  count?: number = 0;
  disabled: boolean;
  targetParent: string;
}

@Component({
  selector: 'ng-bee-w-h-work-step-assignment-chip',
  templateUrl: './w-h-work-step-assignment-chip.component.html',
  styleUrls: ['./w-h-work-step-assignment-chip.component.scss']
})
export class WHWorkStepAssignmentChipComponent implements OnChanges, OnDestroy {

  // INPUT STATE
  @Input() public isRemoveable: boolean = false;
  @Input() public targetParent: 'NONE' | 'checklistdefinition' | 'equipment' | 'equipmentNode' | 'worker' | 'tool' = 'NONE';
  @Input() public detailURL: string;
  @Input() public isDisabled: boolean = false;

  // OUTPUT
  @Output() public onRemoveClick: EventEmitter<WHAssignedChip> = new EventEmitter();

  // DATA
  public chipData: WHAssignedChip;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    if (changes && changes.detailURL && changes.detailURL.currentValue) {
      this.subscriptions.push(this.dataService.getByUrl(changes.detailURL.currentValue)
        .subscribe((cachedData: any) => {
          this.chipData = this.initAssignmentChipData(cachedData);
          // console.log(this.chipData);
          this.changeDetectorRef.detectChanges();
        })
      );
    }
  }

  private initAssignmentChipData(cachedData: any): WHAssignedChip {
    switch (this.targetParent) {
      case 'checklistdefinition':
        const checklistDefinitionDOM: WHChecklistDefinitionDOM = this.dataModelHelperService.initChecklistDefinitionDOM(cachedData as IWHChecklistDefinitionDTO);
        return {
          id: checklistDefinitionDOM.id,
          name: checklistDefinitionDOM.title,
          extId: checklistDefinitionDOM.extId,
          icon: WHIconENUM.ChecklistDefinitionIcon,
          targetParent: 'checklistdefinition'
        } as WHAssignedChip;
      case 'equipment':
        const equipmentDOM: WHEquipmentDOM = this.dataModelHelperService.initEquipmentDOM(cachedData as IWHEquipmentDTO);
        return {
          id: equipmentDOM.id,
          name: equipmentDOM.name,
          extId: equipmentDOM.equipmentNumber,
          avatar: equipmentDOM.pictureURL,
          icon: WHIconENUM.EquipmentIcon,
          targetParent: 'equipment'
        } as WHAssignedChip;
      case 'equipmentNode':
        const equipmentNodeDOM = this.dataModelHelperService.initEquipmentNodeDOM(cachedData as IWHBomNodeDTO);
        return {
          id: equipmentNodeDOM.id,
          name: equipmentNodeDOM.name,
          extId: '',
          avatar: null,
          icon: WHIconENUM.BomIcon,
          targetParent: 'equipmentNode'
        } as WHAssignedChip;
      case 'worker':
        const workerDOM: WHWorkerDOM = this.dataModelHelperService.initWorkerDOM(cachedData as IWHWorkerDTO);
        return {
          id: workerDOM.id,
          name: workerDOM.name,
          extId: workerDOM.extId,
          avatar: workerDOM.smallerAvatarURL,
          icon: WHIconENUM.UserIcon,
          targetParent: 'worker'
        } as WHAssignedChip;
      case 'tool':
        const toolDOM: WHToolDOM = this.dataModelHelperService.initToolDOM(cachedData as IWHToolDTO);
        return {
          id: toolDOM.id,
          name: toolDOM.name,
          extId: toolDOM.extId,
          avatar: toolDOM.pictureURL,
          icon: WHIconENUM.ToolsIcon,
          targetParent: 'tool'
        } as WHAssignedChip;
      default:
        return undefined;
    }
  }

  public unassign() {
    this.onRemoveClick.emit(this.chipData);
  }

  public get personImgJPG(): string {
    return WHAssetsENUM.personImgJPG as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
