<form [formGroup]="form" class="absence-request-form">
  <div class="detail-card-header p--16">
    <span class="w-h-header-span">
      <div tabindex="1" class="single-header">
        {{ absenceUpdateRequest.title | translate }} 
      </div>
    </span>
  </div>

  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="p--16">
    <div class="flex--r-fs gap--16">
        <div class="flex-1">
          <div class="w-h-label">
            {{ absenceUpdateRequest.reasonText | translate }}
          </div>
          <mat-form-field style="width: 100%;">
            <mat-label>{{ "absencerequest.model.description" | translate }}</mat-label>
            <textarea tabindex="1" rows="5" matInput formControlName="response">
            </textarea>
          </mat-form-field>
        </div>
    </div>
    <div class="dialog-action-wrapper" mat-dialog-actions>
      <button
        tabindex="7"
        class="w-h-default-button dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="cancel()"
        tabindex="-1"
      >
        {{ "app.cancel.label" | translate | uppercase }}
      </button>
      
      <button
        tabindex="8"
        [ngClass]="{'mat-accent':absenceUpdateRequest.isAccept, 'mat-warn':!absenceUpdateRequest.isAccept}"
        [disabled]="form.invalid || loading"
        class="dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="confirm()"
        tabindex="-1"
      >
      <mat-spinner
              style="display: inline-block"
              *ngIf="loading"
              [diameter]="18"
            >
            </mat-spinner>
        {{ absenceUpdateRequest.buttonText | translate | uppercase }}
      </button>
    </div>
  </div>
</form>

