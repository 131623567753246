// HELPER
import { IWHFlowMetadataTranslationModel, updateObject, WHMetadataDataService } from '@workheld/workheld-shared-lib';

// ANGULAR
import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';

// SERVICES
import { WHDataService } from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import { WHEntryDOM, WHExpenseEntryDOM, IWHExpenseEntryDTO } from '@workheld/workheld-shared-lib';
import { sortEnumObject } from 'src/app/app-shared/helpers/enumSort.helper';

@Component({
  selector: 'ng-bee-type3-expence-entry-static-data',
  templateUrl: './type3-expence-entry-static-data.component.html',
  styleUrls: ['./type3-expence-entry-static-data.component.scss']
})
export class Type3ExpenceEntryStaticDataComponent implements OnInit, OnDestroy {

  // ENUMS
  public CURRENCY: any;

  // DATA VAR
  @Input() public entryDOM: WHEntryDOM;
  public expenceEntryDOM: WHExpenseEntryDOM = {
    loading: true,
    loaded: false
  } as any;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    private metadataDataService: WHMetadataDataService,
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // ENUMS
    this.subscriptions.push(this.metadataDataService.metadataTranslation$.subscribe((metadataTranslation: IWHFlowMetadataTranslationModel) => {
      this.CURRENCY = sortEnumObject(metadataTranslation.CURRENCY);
      this.changeDetectorRef.detectChanges();
    }));

    this.subscriptions.push(this.dataService.getByUrl(this.entryDOM.entryDetailURL)
      .subscribe((expenceEntryDTO: IWHExpenseEntryDTO) => {
        this.expenceEntryDOM = updateObject(this.expenceEntryDOM, this.dataModelHelperService.initExpenceEntryDOM(expenceEntryDTO));
        this.changeDetectorRef.detectChanges();
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
