import { CommonModule } from '@angular/common';
import { Component, effect, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UIService } from 'src/app/app-services/ui.service';

@Component({
  selector: 'check-body',
  standalone: true,
  styleUrls: ['./mat-dialog-checklist-instance-detail-entry.component.scss'],
  imports: [CommonModule, TranslateModule],
  template: `
    <span style="width: 100%" class="check-body">
      <span class="sort-position check-instance-pos">
        {{ data?.sortPosition + 1 }}
      </span>
      <span class="html-overflow">
        <div class="w-h-label html-overflow">
          {{ 'checklistinstance.model.description' | translate }}
        </div>
        <div class="html-overflow check-desc">
          {{ data?.description }}
        </div>
      </span>
      <span>
        <div class="w-h-label html-overflow">
          {{ 'checklistinstance.model.measurementtype' | translate }}
        </div>
        <div class="html-overflow">
          {{ MEASUREMENT_TYPE[data?.measurementType] }}
        </div>
      </span>
      <span>
        <div class="w-h-label html-overflow">
          {{ 'checklistinstance.model.checkresulttype' | translate }}
        </div>
        <div class="html-overflow" [ngSwitch]="data?.checkResultTypeId">
          <div
            *ngSwitchCase="1"
            style="padding-right: 16px;"
            matTooltip="{{ data?.value?.valueString }}"
          >
            <input
              class="scroll-input"
              type="text"
              [value]="data?.value?.valueString ? data?.value?.valueString : ''"
              disabled
            />
          </div>
          <div *ngSwitchCase="2">
            <mat-slide-toggle
              *ngIf="
                data?.value?.valueBoolean !== undefined &&
                data?.value?.valueBoolean !== null
              "
              [color]="'primary'"
              [checked]="data?.value?.valueBoolean"
              [disabled]="true"
            >
              {{
                (data?.value?.valueBoolean
                  ? 'app.boolean.true'
                  : 'app.boolean.false'
                ) | translate
              }}
            </mat-slide-toggle>
          </div>
          <div *ngSwitchCase="3">
            {{
              data?.value?.valueDouble
                ? (data?.value?.valueDouble | number: '0.1-5' : localeValue)
                : ''
            }}
          </div>
          <div *ngSwitchCase="4">
            {{
              data?.value?.valueDate | date: 'mediumDate' : '' : currentLocale
            }}
            {{
              data?.value?.valueDate | date: 'shortTime' : '' : currentLocale
            }}
          </div>
        </div>
      </span>
      <span>
        <div class="w-h-label html-overflow">
          {{ 'checklistinstance.model.unit' | translate }}
        </div>
        <div class="html-overflow">
          {{ UNIT[data?.unit] }}
        </div>
      </span>
    </span>
  `,
})
export class CheckBodyStandaloneComponent {
  @Input() data: any;
  @Input() MEASUREMENT_TYPE: any;
  @Input() UNIT: any;
  currentLocale: string;
  constructor(private uiService: UIService) {
    effect(() => {
      this.currentLocale = uiService.$currentLocale();
    });
  }
}
