// ENVIRONMENT
import { environment } from '../../environments/environment';

// ANGULAR
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable, of } from 'rxjs';

// MODELS
import {
  NumberValidator,
  WHHttpParams,
  noWhitespaceValidator,
} from '@workheld/workheld-shared-lib';
import { IWHWorkStepDTO } from '@workheld/workheld-shared-lib';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';

@Injectable()
export class WorkStepAsyncService {
  constructor(private httpClient: HttpClient) {}

  // WORK STEP
  public initWorkStepFormGroup(): FormGroup {
    return new FormBuilder().group({
      id: [null],
      workOrderId: [null],
      extId: [null],
      title: [null, [Validators.required, noWhitespaceValidator]],
      description: [null],
      sortPosition: [null], // number
      startDate: [null],
      endDate: [null],
      workStepStatusId: [null], // number
      employees: [],
      workers: [],
      tools: [],
      calculatedWorkStepStatusId: [],
      equipmentId: [null],
      bomNodeId: [null],
      checklistDefinitionId: [null],
      workObjectId: [null],
      urgent: [null],
      resolutionStatus: null,
      setupTimeMinutes: [
        null,
        [NumberValidator.number({ min: 0, max: 999999 })],
      ],
      workingTimeMinutes: [
        null,
        [NumberValidator.number({ min: 0, max: 999999 })],
      ],
      teardownTimeMinutes: [
        null,
        [NumberValidator.number({ min: 0, max: 999999 })],
      ],
      skillIds: [[]],
    });
  }

  // WORK STEP
  public initSolutionStepFormGroup(): FormGroup {
    return new FormBuilder().group({
      solutionStepTitle: [null, Validators.required],
      solutionStepDescription: [null],
      solutionStepExtId: [null],
      solutionStepStartDate: [null],
      solutionStepEndDate: [null],
    });
  }

  // CRUD
  public createWorkStep(
    workStepDTO: IWHWorkStepDTO
  ): Observable<IWHWorkStepDTO> {
    return this.httpClient.post<IWHWorkStepDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/workSteps`,
      workStepDTO
    );
  }

  // CRUD
  public updateWorkStep(
    workStepId: string,
    workStepDTO: IWHWorkStepDTO
  ): Observable<IWHWorkStepDTO> {
    return this.httpClient.post<IWHWorkStepDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/workSteps/${workStepId}`,
      workStepDTO
    );
  }

  // CRUD
  public deleteWorkStepById(workStepId: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.apiUrl + FLOW_GATEWAY}/workSteps/${workStepId}`
    );
  }

  public getWorkStepDtoList(workOrderId: string): Observable<IWHWorkStepDTO[]> {
    return this.httpClient.get<IWHWorkStepDTO[]>(
      `${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workOrderId}/workSteps`
    );
  }

  // ASSIGN
  public assignToWorkStep(
    targetParent: string,
    workStepId: string,
    assignmentId: string
  ) {
    switch (targetParent) {
      case 'WORKER':
        return this.assignWorkerToWorkStep(workStepId, assignmentId);
      case 'EQUIPMENT':
        return this.assignEquipmentToWorkStep(workStepId, assignmentId);
      case 'TOOL':
        return this.assignToolToWorkStep(workStepId, assignmentId);
      case 'SKILL':
        return this.assignSkillToWorkStep(workStepId, assignmentId);
      default:
        return of(undefined);
    }
  }

  // WORKER assignment
  private assignWorkerToWorkStep(
    workStepId: string,
    workerId: string | number
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl + FLOW_GATEWAY}/workSteps/${workStepId}/workers`,
      {
        workerId,
      }
    );
  }

  /* WORKER unassignment
  private unassignWorkerFromWorkStep(workStepId: string, workerId: string | number) {
    return this.httpClient.delete(
      `${environment.apiUrl + FLOW_GATEWAY}/workSteps/${workStepId}/workers/${workerId}`
    );
  }*/

  public unassignWorkerList(unassignWorkerListPayload: {
    workStepId: string;
    workerIds: string[];
  }) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: unassignWorkerListPayload,
    };
    return this.httpClient.delete(
      `${environment.apiUrl + FLOW_GATEWAY}/workSteps/${
        unassignWorkerListPayload.workStepId
      }/removeworkers`,
      options
    );
  }

  // SKILL assignment
  private assignSkillToWorkStep(
    workStepId: string,
    skillId: string | number
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workSteps/${workStepId}/skills/${skillId}`,
      null
    );
  }

  // TOOL assignment
  private assignToolToWorkStep(
    workStepId: string,
    toolId: string | number
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl + FLOW_GATEWAY}/workSteps/${workStepId}/tools`,
      { toolId }
    );
  }

  /* TOOL unassignment
  private unassignToolFromWorkStep(workStepId: string, toolId: string) {
    return this.httpClient.delete(`${environment.apiUrl + FLOW_GATEWAY}/workSteps/${workStepId}/tools/${toolId}`);
  }*/

  public unassignToolList(unassignToolListPayload: {
    workStepId: string;
    toolIds: string[];
  }) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: unassignToolListPayload,
    };
    return this.httpClient.delete(
      `${environment.apiUrl + FLOW_GATEWAY}/workSteps/${
        unassignToolListPayload.workStepId
      }/removetools`,
      options
    );
  }

  // EQUIPMENT assignment
  private assignEquipmentToWorkStep(
    workStepId: string,
    equipmentId: string | number
  ): Observable<IWHWorkStepDTO> {
    const payload = {
      id: workStepId,
      equipmentId: equipmentId,
      skillIds: [],
      resetFields: ['bomNodeId'],
    };
    return this.httpClient.post<IWHWorkStepDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/workSteps/${workStepId}`,
      payload
    );
  }

  /* EQUIPMENT unassignment
  private unassignEquipmentFromWorkStep(workStepId: string): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl + FLOW_GATEWAY}/workSteps/${workStepId}`, {
      id: workStepId,
      resetFields: ['equipmentId']
    });
  }*/

  // SORT BY SORT MODE
  public sortWorkStepsBySortMode(params: WHHttpParams) {
    return this.httpClient.post<IWHWorkStepDTO[]>(
      `${environment.apiUrl + FLOW_GATEWAY}/workSteps/sortbysortmode`,
      params
    );
  }

  // SORT BY LIST IDs
  public sortWorkStepsByWorkStepIdList(params: WHHttpParams) {
    return this.httpClient.post<IWHWorkStepDTO[]>(
      `${environment.apiUrl + FLOW_GATEWAY}/workSteps/sortbyidlist`,
      params
    );
  }
}
