<form
  [formGroup]="shiftModelsFormGroup"
  autocomplete="off"
  class="mat-dialog-add-shift-model-container"
>
  <div class="detail-card-header mat-dialog-add-content">
    <span whFlex class="w-h-header-span">
      <div tabindex="1" class="single-header">
        {{ "shift.button.addShiftModels" | translate }}
      </div>
    </span>
  </div>
  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="mat-dialog-add-content">
    <div>
      <ng-container formArrayName="shiftModels">
        <ng-container
          *ngFor="let shiftModel of shiftModels.controls; let i = index"
          class="shift-models-container"
        >
          <div
            [formGroupName]="i"
            class="shift-input-model-container flex--r-c flex--wrap gap--16"
          >
            <mat-form-field class="flex-1">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" required />
            </mat-form-field>

            <mat-form-field class="flex-1">
              <mat-label>{{
                "shift.model.breakDuration" | translate
              }}</mat-label>
              <input matInput formControlName="breakDuration" required />
            </mat-form-field>
            <lib-w-h-dropdown
              [valueLabel]="'shift.model.startTime'"
              [required]="true"
              [valueFormControl]="getModelByIndex(i).get('startTime')"
              (onDomEvent)="onBlur($event, i, 'startTime')"
              [dropdownItemDomList]="timeIntervals"
              [loading]="false"
            >
            </lib-w-h-dropdown>

            <lib-w-h-dropdown
              [valueLabel]="'shift.model.endTime'"
              [required]="true"
              [valueFormControl]="getModelByIndex(i).get('endTime')"
              (onDomEvent)="onBlur($event, i, 'startTime')"
              [dropdownItemDomList]="timeIntervals"
              [loading]="false"
            >
            </lib-w-h-dropdown>

            <mat-form-field class="flex-1">
              <mat-label>{{ "shift.model.status" | translate }}</mat-label>
              <mat-select formControlName="status">
                <mat-option
                  *ngFor="let statusId of statusIdEnumArray"
                  [value]="statusId"
                >
                  {{ "material.enum.status." + statusId | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-icon (click)="deleteShiftModel(i)">delete</mat-icon>
          </div>
        </ng-container>
        <div *ngIf="shiftModels.controls.length === 0">
          {{ "shift.label.noShiftModels" | translate }}
        </div>
        <div class="flex--r-c-r flex-1 p-t--16 p-b--16">
          <button
            class="w-h-default-button dialog-button mat-elevation-z2"
            mat-flat-button
            type="button"
            (click)="addShiftModel()"
          >
            {{ "shift.button.addShiftModel" | translate }}
          </button>
        </div>
        <div *ngIf="shiftModels.errors" class="messages-container warn m--8">
          <div>{{ "error.formValidation.title" | translate }}</div>
          <ul>
            <li *ngIf="shiftModels.errors?.duplicateName">
              <mat-error>
                {{ "error.formValidation.duplicateName" | translate }}
              </mat-error>
            </li>
            <li *ngIf="shiftModels.errors?.overlappingTime">
              <mat-error>
                {{ "error.formValidation.overlappingError" | translate }}
              </mat-error>
            </li>
            <li *ngIf="shiftModels.errors?.isSameHour">
              <mat-error>
                {{ "error.formValidation.sameHourShiftError" | translate }}
              </mat-error>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
    <div class="dialog-action-wrapper" mat-dialog-actions>
      <button
        class="w-h-default-button dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="cancel()"
      >
        {{ "app.cancel.label" | translate | uppercase }}
      </button>

      <button
        color="accent"
        [disabled]="this.shiftModelsFormGroup.invalid"
        class="dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="confirm()"
      >
        <mat-spinner
          style="display: inline-block"
          *ngIf="loading$ | async"
          [diameter]="18"
        >
        </mat-spinner>
        {{ "app.confirm.label" | translate | uppercase }}
      </button>
    </div>
  </div>
</form>
