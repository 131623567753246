// API
import {
  componentDestroyed,
  WHLoginDataService,
  WHIconENUM,
  WHFeatureKeyENUM,
} from '@workheld/workheld-shared-lib';

// ANGULAR
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
  signal,
} from '@angular/core';

// ANGULAR MATERIAL
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatSelectionList } from '@angular/material/list';

// SERVICES
import {
  WorkObjectTemplateAsyncService,
  WHUseWorkObjectTemplatePayloadDTO,
} from 'src/app/app-services-async/work-object-template-async.service';
import { NgFormBuilderHelperService } from 'src/app/app-services-helper/ng-form-builder-helper.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { Subscription, Observable, ReplaySubject, throwError } from 'rxjs';
import { takeUntil, filter, catchError } from 'rxjs/operators';

// MODELS
import {
  WHWorkObjectTemplateDOM,
  IWHWorkObjectTemplateDTO,
  IWHWorkOrderTemplateDTO,
} from '@workheld/workheld-shared-lib';
import { ListColumn } from 'src/app/app-modules-layout/list/list-column.model';
import { ListDataSource } from 'src/app/app-modules-layout/list/list-datasource';
import { ListDatabase } from 'src/app/app-modules-layout/list/list-database';
import { List } from 'src/app/app-modules-layout/list/list.interface';

@Component({
  selector: 'ng-bee-mat-dialog-use-work-object-template-entry',
  templateUrl: './mat-dialog-use-work-object-template-entry.component.html',
  styleUrls: ['./mat-dialog-use-work-object-template-entry.component.scss'],
})
export class MatDialogUseWorkObjectTemplateEntryComponent
  implements List<WHWorkObjectTemplateDOM>, OnInit, OnDestroy
{
  // FORM
  public applyWorkObjectTemplateFromGroup: FormGroup;
  public locationFormGroup: FormGroup;

  // DATA
  public isLocationEnabled: boolean = false;
  public workObjectTemplateDomList: WHWorkObjectTemplateDOM[] = [];
  public selectedWorkObjectTemplateDOM: WHWorkObjectTemplateDOM | null;
  public workOrderTemplateList: IWHWorkOrderTemplateDTO[] = [];

  // DATA TABLE
  public data$: Observable<WHWorkObjectTemplateDOM[]>;
  public subject$: ReplaySubject<WHWorkObjectTemplateDOM[]> = new ReplaySubject<
    WHWorkObjectTemplateDOM[]
  >(1);
  // TABLE COLUMS
  public columns: ListColumn[] = [
    { name: '', property: 'id', visible: true },
    {
      name: 'template.model.extid',
      property: 'workObjectTypeId',
      visible: false,
      isModelProperty: true,
    },
    {
      name: 'template.model.title',
      property: 'templateName',
      visible: false,
      isModelProperty: true,
    },
    {
      name: 'template.model.title',
      property: 'formattedName',
      visible: true,
      isModelProperty: true,
    },
  ] as ListColumn[];
  public pageSize: number = 10;
  public resultsLength: number;
  public dataSource: ListDataSource<WHWorkObjectTemplateDOM> | null;
  public database: ListDatabase<WHWorkObjectTemplateDOM>;

  // DOM
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) public sort: MatSort;
  @ViewChild('workOrderSelectionList', { static: false })
  workOrderSelectionList: MatSelectionList;
  templatesLoading = signal(false);
  // STATE
  public saving: boolean = false;

  // MANAGE SUBSCRIPTIONS
  public subscriptions: Subscription[] = [];

  constructor(
    private workObjectTemplateAsyncService: WorkObjectTemplateAsyncService,
    private ngFormBuilderHelperService: NgFormBuilderHelperService,
    private dataModelHelperService: DataModelHelperService,
    private loginDataService: WHLoginDataService,
    public matDialogRef: MatDialogRef<MatDialogUseWorkObjectTemplateEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { workObjectId: string },
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // FEATURE CONFIG
    this.subscriptions.push(
      this.loginDataService.featureConfigMap$.subscribe(
        (featureConfigMap: Map<string, boolean>) => {
          // console.log(featureConfig.featureConfigMap);
          this.isLocationEnabled = featureConfigMap.get(
            WHFeatureKeyENUM.CORE_WORK_FLOW_WORK_ORDER_LOCATION
          );
        }
      )
    );

    // SET FORM
    this.applyWorkObjectTemplateFromGroup =
      this.workObjectTemplateAsyncService.initApplyWorkObjectTemplateFromGroup();
    this.applyWorkObjectTemplateFromGroup.controls['workObjectId'].patchValue(
      this.data.workObjectId
    );
    this.locationFormGroup =
      this.ngFormBuilderHelperService.initLocationFormGroup(true);

    // GET TEMPLATE LIST
    this.subscriptions.push(
      this.workObjectTemplateAsyncService
        .getWorkObjectTemplateDtoList()
        .subscribe((workObjectTemplateDtoList) => {
          workObjectTemplateDtoList.forEach(
            (workObjectTemplateDTO: IWHWorkObjectTemplateDTO) => {
              const workObjectTemplateDOM: WHWorkObjectTemplateDOM =
                this.dataModelHelperService.initWorkObjectTemplateDOM(
                  workObjectTemplateDTO
                );
              this.workObjectTemplateDomList.push(workObjectTemplateDOM);
            }
          );
          // console.log(this.workObjectTemplateDomList);
          this.subject$.next(this.workObjectTemplateDomList);
        })
    );
    this.data$ = this.subject$.asObservable();

    this.templatesLoading.set(true);

    this.database = new ListDatabase<WHWorkObjectTemplateDOM>();
    this.data$
      .pipe(
        takeUntil(componentDestroyed(this)),
        filter<WHWorkObjectTemplateDOM[]>(Boolean)
      )
      .pipe(
        catchError((err) => {
          this.templatesLoading.set(false);
          return throwError(err);
        })
      )
      .subscribe((workObjectTemplateDomList) => {
        this.workObjectTemplateDomList = workObjectTemplateDomList;
        this.database.dataChange.next(workObjectTemplateDomList);
        this.resultsLength = workObjectTemplateDomList.length;
        this.templatesLoading.set(false);
      });

    this.dataSource = new ListDataSource<WHWorkObjectTemplateDOM>(
      this.database,
      this.sort,
      this.paginator,
      this.columns
    );
  }

  public selectWorkObjectTemplate(
    workObjectTemplateDOM: WHWorkObjectTemplateDOM
  ) {
    // console.log(workObjectTemplateDOM);
    if (
      this.selectedWorkObjectTemplateDOM &&
      this.selectedWorkObjectTemplateDOM.id === workObjectTemplateDOM.id
    ) {
      this.selectedWorkObjectTemplateDOM = null;
      this.workOrderTemplateList = [];
      this.applyWorkObjectTemplateFromGroup.reset();
      return;
    }
    this.workOrderTemplateList = workObjectTemplateDOM.workOrderTemplates;
    this.selectedWorkObjectTemplateDOM = workObjectTemplateDOM;
    this.applyWorkObjectTemplateFromGroup.controls['workObjectId'].patchValue(
      this.data.workObjectId
    );
    this.applyWorkObjectTemplateFromGroup.controls[
      'workObjectTemplateId'
    ].patchValue(workObjectTemplateDOM.id);

    setTimeout(() => {
      this.workOrderSelectionList.selectAll();
    }, 100);
  }

  public handleSubmit() {
    // PATCH PAYLOAD
    if (this.isLocationEnabled) {
      this.applyWorkObjectTemplateFromGroup.controls['address'].patchValue(
        this.locationFormGroup.value
      );
    } else {
      this.applyWorkObjectTemplateFromGroup.controls['address'].disable();
    }
    const selectedWorkOrderTemplates: any[] = [];
    this.workOrderSelectionList._value.forEach(
      (workOrderTemplateId: string) => {
        selectedWorkOrderTemplates.push({
          workOrderTemplateId,
        });
      }
    );
    this.applyWorkObjectTemplateFromGroup.controls[
      'selectedWorkOrderTemplates'
    ].patchValue(selectedWorkOrderTemplates);
    const workObjectTemplatePayload: WHUseWorkObjectTemplatePayloadDTO =
      this.applyWorkObjectTemplateFromGroup.value;

    // TEMPLATE ID
    const templateId: string =
      this.applyWorkObjectTemplateFromGroup.controls['workObjectTemplateId']
        .value;

    this.saving = true;
    this.subscriptions.push(
      this.workObjectTemplateAsyncService
        .useWorkObjectTemplate(templateId, workObjectTemplatePayload)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.saving = false;
            this.changeDetectorRef.detectChanges();
            return throwError(error);
          })
        )
        .subscribe((resp: any) => {
          this.saving = false;
          // console.log(resp);
          this.matDialogRef.close(true);
        })
    );
  }

  public cancel() {
    this.matDialogRef.close();
  }

  public get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  public onFilterChange(value: any) {
    if (!this.dataSource) {
      return;
    }
    this.dataSource.filter = value;
  }

  public get isWorkObjectTemplateSelected(): string {
    return this.applyWorkObjectTemplateFromGroup.controls[
      'workObjectTemplateId'
    ].value as string;
  }

  public get isLocationValid() {
    return this.locationFormGroup.controls['formattedAddress'].valid;
  }

  public get projectTemplateIcon(): string {
    return WHIconENUM.ProjectTemplateIcon as string;
  }

  public get noWorkOrderSelected() {
    return this.workOrderSelectionList &&
      this.workOrderSelectionList._value &&
      this.workOrderSelectionList._value.length === 0
      ? true
      : false;
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
