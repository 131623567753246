// ANGULAR
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

// API
import {
  FLOW_GATEWAY,
  IWHFlowMetadataTranslationModel,
  IWHUIConfigModel,
  IWHWorkObjectEndpointConfigModel,
  WHIconENUM,
  WHMetadataDataService,
  WHWorkObjectDataService,
  WHWorkObjectDOM
} from '@workheld/workheld-shared-lib';
import { sortEnumObject } from 'src/app/app-shared/helpers/enumSort.helper';

@Component({
  selector: 'mat-dialog-workobject-reject-entry',
  templateUrl: './mat-dialog-workobject-reject-entry.component.html',
  styleUrls: ['./mat-dialog-workobject-reject-entry.component.scss']
})
export class MatDialogWorkObjectRejectEntryComponent implements OnInit, OnDestroy {
  // ENUMS
  public REJECT_REASON: IWHUIConfigModel[] = [];

  // VAR
  public paylaodFormGroup: FormGroup = new FormBuilder().group({
    id: [null, Validators.required],
    comment: [null],
    reason: [null, Validators.required],
  });

  // STATE
  public saving: boolean = false;

  // SUBSCRIPTION
  private subscriptions: Subscription[] = [];

  constructor(
    public matDialogRef: MatDialogRef<MatDialogWorkObjectRejectEntryComponent>,
    private metadataDataService: WHMetadataDataService,
    private workObjectDataService: WHWorkObjectDataService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {
      type?: 'accept' | 'reject'
      workObjectDOM: WHWorkObjectDOM
    }
  ) { }

  ngOnInit(): void {
    this.paylaodFormGroup.controls['id'].patchValue(this.data.workObjectDOM.id);

    // WH ENUMS
    this.subscriptions.push(this.metadataDataService.metadataTranslation$.subscribe((metadataTranslation: IWHFlowMetadataTranslationModel) => {
      this.REJECT_REASON = [];
      metadataTranslation.REJECTION_REASON ? Object.entries(sortEnumObject(metadataTranslation.REJECTION_REASON)).forEach((property: any[]) => {
        this.REJECT_REASON.push({
          featureKey: property[0],
          enabled: true,
          translateKey: property[1]
        });
      }) : console.log();
      this.changeDetectorRef.detectChanges();
    }));
  }

  public handleSubmit(): void {
    this.rejectWorkObject();
  }

  private rejectWorkObject() {
    const endpointConfig: IWHWorkObjectEndpointConfigModel = {
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      workObjectId: this.data.workObjectDOM.id
    }

    const payload: any = this.paylaodFormGroup.value;
    payload.comment = payload.reason + (payload.comment ? ', ' + payload.comment : '');

    this.saving = true;
    this.subscriptions.push(this.workObjectDataService.rejectWorkObject(endpointConfig, payload).subscribe(() => {
      this.saving = false;
      this.matDialogRef.close(true);
    }));
  }

  public get isFormGroupInvalid(): boolean {
    return (this.paylaodFormGroup.invalid) as boolean;
  }

  public closeDialog(): void {
    this.matDialogRef.close();
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
