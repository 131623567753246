<form [formGroup]="shiftModelsFormGroup">
  <div class="detail-card-header mat-dialog-add-content">
    <span whFlex class="w-h-header-span">
      <div tabindex="1" class="single-header">
        {{ "shift.label.updateShiftModel" | translate }}
      </div>
    </span>
  </div>

  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="mat-dialog-add-content">
    <div class="flex--r-c flex--wrap gap--16">
      <mat-form-field class="flex-1">
        <mat-label>{{ "shift.model.name" | translate }}</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>

      <mat-form-field class="flex-1">
        <mat-label>{{ "shift.model.breakDuration" | translate }}</mat-label>
        <input matInput formControlName="breakDuration" required />
      </mat-form-field>

      <lib-w-h-dropdown
        [valueLabel]="'shift.model.startTime'"
        [required]="true"
        [valueFormControl]="shiftModelsFormGroup.get('startTime')"
        (onDomEvent)="onBlur($event, 'startTime')"
        [dropdownItemDomList]="timeIntervals"
        [loading]="false"
      >
      </lib-w-h-dropdown>

      <lib-w-h-dropdown
        [valueLabel]="'shift.model.endTime'"
        [required]="true"
        [valueFormControl]="shiftModelsFormGroup.get('endTime')"
        (onDomEvent)="onBlur($event, 'endTime')"
        [dropdownItemDomList]="timeIntervals"
        [loading]="false"
      >
      </lib-w-h-dropdown>

      <mat-form-field class="flex-1">
        <mat-label>{{ "shift.model.status" | translate }}</mat-label>
        <mat-select formControlName="status">
          <mat-option
            *ngFor="let statusId of statusIdEnumArray"
            [value]="statusId"
          >
            {{ "material.enum.status." + statusId | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      *ngIf="shiftModelsFormGroup.errors"
      class="messages-container warn m--8"
    >
      <div>{{ "error.formValidation.title" | translate }}</div>
      <ul>
        <li *ngIf="shiftModelsFormGroup.errors?.overlappingTime">
          <mat-error>
            {{ "error.formValidation.overlappingError" | translate }}
          </mat-error>
        </li>
        <li *ngIf="shiftModelsFormGroup.errors?.isSameHour">
          <mat-error>
            {{ "error.formValidation.sameHourShiftError" | translate }}
          </mat-error>
        </li>
      </ul>
    </div>
    <div class="dialog-action-wrapper" mat-dialog-actions>
      <button
        tabindex="7"
        class="w-h-default-button dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="cancel()"
        tabindex="-1"
      >
        {{ "app.cancel.label" | translate | uppercase }}
      </button>

      <button
        tabindex="8"
        color="accent"
        [disabled]="shiftModelsFormGroup.invalid"
        class="dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="confirm()"
        tabindex="-1"
      >
        <mat-spinner
          style="display: inline-block"
          *ngIf="loading$ | async"
          [diameter]="18"
        >
        </mat-spinner>
        {{ "app.confirm.label" | translate | uppercase }}
      </button>
    </div>
  </div>
</form>
