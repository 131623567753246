<div class="checklist-instance-mat-dialog">
  <div class="detail-card-header">
    <span whFlex class="w-h-header-icon-span">
      <i
        mat-card-avatar
        class="workheld-icons header-icon"
        [innerHtml]="checklistDefinitionIcon"
      ></i>
    </span>
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ checklistInstanceDOM?.title }}
      </div>
    </span>
  </div>
  <div class="checklist-instance-detail-mat-dialog-body">
    <div
      [ngClass]="{
        'check-list-wrapper': checklistInstanceDOM?.allChecks?.length > 6
      }"
    >
      <div
        *ngFor="let checkDTO of checklistInstanceDOM?.checks"
        style="margin-bottom: 8px"
      >
        <check-body
          style="width: 100%"
          [data]="checkDTO"
          [MEASUREMENT_TYPE]="MEASUREMENT_TYPE"
          [UNIT]="UNIT"
        ></check-body>
        <span whFlex class="checklist-instance-action-wrapper">
          <button
            mat-icon-button
            color="accent"
            class="mat-elevation-z2"
            [disabled]="!checkDTO?.comment"
            tabindex="-1"
            style="border: 1px solid"
            (click)="previewComment(checkDTO?.comment)"
          >
            <mat-icon> message </mat-icon>
          </button>
        </span>
      </div>
      <div *ngFor="let section of sections">
        <div class="flex--r-c-sb" style="margin-bottom: 8px">
          <span class="flex-1">
            <div class="w-h-label html-overflow">
              {{ "checklistinstance.model.section.title" | translate }}
            </div>
            <div class="html-overflow">
              {{ section.title }}
            </div>
          </span>
          <span class="flex--c-c-r gap--8 flex-1">
            <div class="w-h-label html-overflow">
              {{ "checklistinstance.model.section.enabled" | translate }}
            </div>
            <mat-slide-toggle
              [checked]="section?.enabled"
              [disabled]="true"
            ></mat-slide-toggle>
          </span>
        </div>
        <div
          *ngFor="let checkDTO of section?.checks"
          style="margin-bottom: 8px"
        >
          <check-body
            style="width: 100%"
            [data]="checkDTO"
            [MEASUREMENT_TYPE]="MEASUREMENT_TYPE"
            [UNIT]="UNIT"
          ></check-body>
          <span whFlex class="checklist-instance-action-wrapper">
            <button
              mat-icon-button
              color="accent"
              class="mat-elevation-z2"
              [disabled]="!checkDTO?.comment"
              tabindex="-1"
              style="border: 1px solid"
              (click)="previewComment(checkDTO?.comment)"
            >
              <mat-icon> message </mat-icon>
            </button>
          </span>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 16px">
      <ng-bee-checklist-instance-file-img-gallery
        [checklistInstanceId]="checklistInstanceDOM?.id"
        [checkDtoList]="checklistInstanceDOM?.checks"
      >
      </ng-bee-checklist-instance-file-img-gallery>
    </div>
    <div>
      <form class="gap--12" [formGroup]="checklistInstanceProcessFormGroup">
        <span whFlex="50">
          <div>
            <mat-form-field whFlex="50">
              <mat-label>{{
                "checklistinstance.model.status" | translate
              }}</mat-label>
              <mat-select
                formControlName="checklistInstanceStatusId"
                required
                [disabled]="
                  !checklistInstanceDOM ||
                  isChecklistInstanceInStatusCreate ||
                  isChecklistInstanceInStatusClosed
                "
                (selectionChange)="updateChecklistInstanceStatus()"
              >
                <mat-option
                  *ngFor="
                    let checklistInstanceStatusId of checklistInstanceStatusIdEnumArray;
                    let i = index
                  "
                  [value]="checklistInstanceStatusId"
                  [disabled]="isDisabled(checklistInstanceStatusId)"
                >
                  {{
                    "checklistinstance.enum.status." + checklistInstanceStatusId
                      | translate
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </span>
        <span whFlex="50">
          <div class="w-h-label manager-comment-label">
            {{ "checklistinstance.model.managercomment" | translate }}
          </div>
          <div>
            <mat-form-field whFlex>
              <textarea
                formControlName="managerComment"
                [disabled]="true"
                rows="3"
                matInput
              >
              </textarea>
            </mat-form-field>
          </div>
        </span>
      </form>
    </div>
  </div>
  <div class="dialog-action-wrapper" mat-dialog-actions>
    <button
      type="button"
      mat-flat-button
      class="w-h-default-button reset-to-draft-button mat-elevation-z2"
      [disabled]="
        isChecklistInstanceInStatusCreate ||
        isChecklistInstanceInStatusClosed ||
        saving ||
        checklistInstanceStatusId === 0
      "
      (click)="resetToDraft()"
      matTooltip="{{ 'checklistinstance.ui.resetdraft.label' | translate }}"
    >
      <!-- <i class="workheld-icons" [innerHTML]="backIcon"></i>  -->
      {{ "checklistinstance.ui.resetdraft.label" | translate | uppercase }}
    </button>
    <button
      class="w-h-default-button dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      (click)="cancel()"
      [disabled]="saving || !checklistInstanceDOM"
    >
      <i class="workheld-icons" [innerHTML]="cancelIcon"></i>
      {{ "app.cancel.label" | translate | uppercase }}
    </button>
    <button
      color="accent"
      class="dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      [disabled]="saving || !checklistInstanceDOM"
      (click)="handleSubmit()"
    >
      <mat-spinner style="display: inline-block" *ngIf="saving" [diameter]="18">
      </mat-spinner>
      <i class="workheld-icons" [innerHTML]="saveIcon" *ngIf="!saving"></i>
      {{ "app.save.label" | translate | uppercase }}
    </button>
  </div>
</div>
