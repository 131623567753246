// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// RxJS 6
import { Subscription } from 'rxjs';

// API
import {
  FLOW_GATEWAY,
  IWHFlowMetadataTranslationModel,
  WHMetadataDataService,
  WHIconENUM,
  WHMaterialDataService,
  WHWorkStepDOM,
} from '@workheld/workheld-shared-lib';
import { sortEnumObject } from 'src/app/app-shared/helpers/enumSort.helper';

@Component({
  selector: 'ng-bee-mat-dialog-preview-entry',
  templateUrl: './mat-dialog-preview-entry.component.html',
  styleUrls: ['./mat-dialog-preview-entry.component.scss'],
})
export class MatDialogPreviewEntryComponent implements OnInit, OnDestroy {
  // ENUMS
  UNIT: any = {};

  // VAR
  public apiUrl: string = environment.apiUrl + FLOW_GATEWAY;
  public params: any;

  public subscription: Subscription = new Subscription();

  constructor(
    private metadataDataService: WHMetadataDataService,
    public matDialogRef: MatDialogRef<MatDialogPreviewEntryComponent>,
    private materialDataService: WHMaterialDataService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      workstep: WHWorkStepDOM;
      type: string;
      targetId: string;
      disableUi: boolean;
    }
  ) {}

  public initParams(): any {
    return {
      page: 0,
      pageSize: 10,
      sort: 'materialNumber',
      sortMode: 'ASC',
      term: undefined,
    } as any;
  }

  ngOnInit() {
    this.params = this.initParams();
    this.subscription.add(
      this.metadataDataService.metadataTranslation$.subscribe(
        (metadataTranslation: IWHFlowMetadataTranslationModel) => {
          this.UNIT = sortEnumObject(metadataTranslation.UNIT);
        }
      )
    );
  }

  payloadEmmiter($event: any) {
    console.log($event);
  }

  public get materialIcon(): string {
    return WHIconENUM.MaterialConsumptionIcon as string;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
