import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { WHCustomMonitoringService } from './w-h-logging.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(
    private translateService: TranslateService,
    private injector: Injector
  ) {
    super();
  }

  handleError(error: Error) {
    super.handleError(error);
    Sentry.captureException(error);

    const toastrService = this.injector.get(ToastrService);

    if (error instanceof HttpErrorResponse) {
      let errorMessage = '';
      const err: any = error.error ? error.error : error;
      const code = err.code ? err.code : 0;

      if (err?.code) {
        errorMessage = this.translateService.instant('ERROR.' + code);
        errorMessage = errorMessage.localeCompare('ERROR.' + code)
          ? this.translateService.instant('ERROR.' + code)
          : err.message;
        if (err.affectedFields) {
          errorMessage = errorMessage.replace(/{{\w+}}/, err.affectedFields[0]);
        }
      } else if (err.message) {
        errorMessage = err.message;
      } else {
        errorMessage = `${error.message}`;
      }
      console.log('ERR MESSAGE ', error);

      toastrService.error(errorMessage, '' + error.status);
      console.log(
        `Server error happened:\nError Code: ${error.status} \nMessage: ${err.message}`
      );
    }
    if (environment.instrumentationKey) {
      // this.WHCustomMonitoringService.logException(error);
    }
  }
}
