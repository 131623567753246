// ANGULAR
import { FormGroup, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  Inject,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// ANGULAR TRANSLATE
import { TranslateService } from '@ngx-translate/core';

// REDUX 6

// RxJS 6
import { firstValueFrom, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

// MODELS
import {
  IWHEventLogDTO,
  IWHFlowContactDTO,
  WHWorkOrderDataService,
  WHScheduleAllWorkStepPayload,
  WHWorkStepDOM,
  IWHWorkStepDTO,
  IWHUIConfigModel,
  WHMetadataDataService,
  IWHFlowMetadataTranslationModel,
  IWHProjectWorkObjectDTO,
} from '@workheld/workheld-shared-lib';

import { environment } from 'src/environments/environment';

// SERVICES
import {
  FLOW_GATEWAY,
  WHIconENUM,
  WHEventLogDataService,
  WHEventLogTypeENUM,
  WHEventLogSourceENUM,
  WHWorkObjectDataService,
  IWHEventLogEndpointConfigModel,
  IWHWorkObjectEndpointConfigModel,
  IWHWorkOrderEndpointConfigModel,
} from '@workheld/workheld-shared-lib';

import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { DateTimeHelperService } from 'src/app/app-services-helper/date-time-helper.service';
import { WorkOrderAsyncService } from 'src/app/app-services-async/work-order-async.service';
import { FormReferenceService } from 'src/app/app-services-helper/form-reference.service';

import * as moment from 'moment';
import { sortEnumObject } from 'src/app/app-shared/helpers/enumSort.helper';

@Component({
  selector: 'ng-bee-mat-dialog-contact-customer-entry',
  templateUrl: './mat-dialog-contact-customer-entry.component.html',
  styleUrls: ['./mat-dialog-contact-customer-entry.component.scss'],
})
export class MatDialogContactCustomerEntryComponent
  implements OnInit, OnDestroy
{
  @Input() eventLogEndpointConfig: IWHEventLogEndpointConfigModel = {
    apiUrl: environment.apiUrl + FLOW_GATEWAY,
  };
  // ENUMS
  public CUSTOMER_CONTACT: IWHUIConfigModel[] = [];

  // FORM
  public contactCustomerFormGroup: FormGroup;

  public workObjectTypeId: number;
  public workObjectId: string;
  public contacts: IWHFlowContactDTO[];

  // STATE
  public saving: boolean = false;

  // MANAGE SUBSCRIPTIONS
  public subscriptions: Subscription[] = [];

  // DATE VALUES
  public tempFormGroup = new FormGroup({
    dateSlotForm: new FormControl(),
    timeSlotForm: new FormControl(),
  });

  constructor(
    private eventLogDataService: WHEventLogDataService,
    private workObjectDataService: WHWorkObjectDataService,
    private workOrderDataService: WHWorkOrderDataService,
    private dataModelHelperService: DataModelHelperService,
    private dateTimeHelperService: DateTimeHelperService,
    private workOrderAsyncService: WorkOrderAsyncService,
    private metadataDataService: WHMetadataDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    public matDialogRef: MatDialogRef<MatDialogContactCustomerEntryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      workObjectTypeId: number;
      workObjectId: string;
      contacts: IWHFlowContactDTO[];
    },
    private formReferenceService: FormReferenceService
  ) {}

  ngOnInit() {
    // console.log(this.data);
    this.contactCustomerFormGroup =
      this.eventLogDataService.initContactCustomerFromGroup();

    this.workObjectTypeId = this.data.workObjectTypeId;
    this.workObjectId = this.data.workObjectId;
    this.contacts = this.data.contacts;

    // WH ENUMS
    this.subscriptions.push(
      this.metadataDataService.metadataTranslation$.subscribe(
        (metadataTranslation: IWHFlowMetadataTranslationModel) => {
          this.CUSTOMER_CONTACT = [];
          metadataTranslation.CUSTOMER_CONTACT
            ? Object.entries(sortEnumObject(metadataTranslation.CUSTOMER_CONTACT)).forEach(
                (property: any[]) => {
                  this.CUSTOMER_CONTACT.push({
                    featureKey: property[0],
                    enabled: true,
                    translateKey: property[1],
                  });
                }
              )
            : console.log();
          this.changeDetectorRef.detectChanges();
        }
      )
    );

    if (this.isContactsEmpty) {
      this.contactCustomerFormGroup.disable();
    } else {
      this.contactCustomerFormGroup.controls[
        'enumReason'
      ].valueChanges.subscribe((val) => {
        if (val === 'NO')
          //Clear time-slot control value to make it valid in case of
          // user selected that they did not contacted the customer after
          // previous set of time-slot to invalid value - so they are able to save the form.
          this.contactCustomerFormGroup.controls['timeSlot'].patchValue(null);
      });
    }

    this.subscriptions.push(
      this.tempFormGroup.controls['dateSlotForm'].valueChanges.subscribe(
        (value: string) => {
          const time = this.tempFormGroup.controls['timeSlotForm'].value;
          const finalVersion = time
            ? moment(value)
                .set({ hour: time.slice(0, 2), minute: time.slice(-2) })
                .format()
            : moment(value).format();
          this.contactCustomerFormGroup.controls['timeSlot'].patchValue(
            finalVersion
          );
        }
      )
    );

    this.subscriptions.push(
      this.tempFormGroup.controls['timeSlotForm'].valueChanges.subscribe(
        (value: any) => {
          const date = this.tempFormGroup.controls['dateSlotForm'].value;
          const finalVersion = date
            ? moment(date)
                .set({ hour: value.slice(0, 2), minute: value.slice(-2) })
                .format()
            : moment(date).format();
          this.contactCustomerFormGroup.controls['timeSlot'].patchValue(
            finalVersion
          );
        }
      )
    );
  }

  public async createLogAndUpdateWorkObject() {
    if (this.isFormGroupInvalid) return;

    const workObjectEndpointConfig: IWHWorkObjectEndpointConfigModel = {
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      workObjectTypeId: this.workObjectTypeId,
      workObjectId: this.workObjectId,
    };

    const templatePayload: any = this.contactCustomerFormGroup.value;
    const workObjectPayload: IWHProjectWorkObjectDTO =
      this.workObjectDataService.initWorkObjectCustomerVisitPlannedPayload(
        this.workObjectId
      );

    const payload: IWHEventLogDTO = {
      comment:
        this.translateService.instant(
          this.CUSTOMER_CONTACT.filter(
            (r) => r.featureKey == templatePayload.enumReason
          )[0]?.translateKey
        ) +
        (templatePayload.timeSlot
          ? ' on ' + moment(templatePayload.timeSlot).format('DD.MM.YYYY HH:mm')
          : '') +
        (templatePayload.comment ? ', ' + templatePayload.comment : ''),
      eventLogType:
        this.enumReason === 'NO'
          ? WHEventLogTypeENUM.CUSTOMER_CALL
          : WHEventLogTypeENUM.APPOINTMENT_SET,
      workObjectId: this.workObjectId,
      eventLogSource: WHEventLogSourceENUM.USER,
      createdAt: null,
      sourceLoginDetailURL: null,
      sourceLoginId: null,
      workObjectTitle: null,
    };

    this.saving = true;

    this.eventLogDataService
      .createEventLog(this.eventLogEndpointConfig, payload)
      .pipe(
        switchMap(async () => {
          if (
            this.contactCustomerFormGroup.controls['enumReason'].value == 'YES'
          ) {
            const workOrders = await firstValueFrom(
              this.workOrderAsyncService.getWorkOrderDtoList(
                this.workObjectTypeId,
                this.workObjectId
              )
            );

            const scheduledStartDate =
              this.dateTimeHelperService.dateToIsoStringOffset(
                moment(templatePayload.timeSlot).toDate()
              );
            const scheduledEndDate =
              this.dateTimeHelperService.dateToIsoStringOffset(
                moment(templatePayload.timeSlot).add(2, 'hours').toDate()
              );

            workObjectPayload.startDate = scheduledStartDate;
            workObjectPayload.endDate = scheduledEndDate;

            for (const workOrder of workOrders) {
              const workSteps = await firstValueFrom(
                this.workOrderDataService.scheduleAllWorkStepsForWorkOrder(
                  {
                    apiUrl: environment.apiUrl + FLOW_GATEWAY,
                    workOrderId: workOrder.id,
                  } as IWHWorkOrderEndpointConfigModel,
                  {
                    workOrderId: workOrder.id,
                    startDate: scheduledStartDate,
                    endDate: scheduledEndDate,
                    mode: 'ALL',
                  } as WHScheduleAllWorkStepPayload
                )
              );

              const workStepDomList: WHWorkStepDOM[] = [];

              workSteps.forEach((workStepDTO: IWHWorkStepDTO) => {
                const workStepDOM: WHWorkStepDOM =
                  this.dataModelHelperService.initWorkStepDOM(workStepDTO);
                workStepDomList.push(workStepDOM);
              });
            }

            return this.workObjectDataService.updateWorkObjectByWorkObjectType(
              workObjectEndpointConfig,
              workObjectPayload
            );
          }
          return of(null);
        }),
        catchError((err) => {
          this.saving = false;
          return throwError(err);
        })
      )
      .subscribe((resp: any) => {
        this.saving = false;
        const workObjectDOM =
          this.dataModelHelperService.initWorkObjectTypeDOM(resp);

        this.formReferenceService.unsavedChanges = false;
        this.saving = false;
        this.matDialogRef.close({ confirm: true, dom: workObjectDOM });
      });
  }

  cancel() {
    if (this.contactCustomerFormGroup.dirty) {
      const ref = this.formReferenceService.createDialog();
      this.subscriptions.push(
        ref
          .pipe(
            tap((canDiscard) => {
              if (canDiscard) {
                this.matDialogRef.close();
              }
            })
          )
          .subscribe()
      );
    } else {
      this.matDialogRef.close(false);
    }
  }

  public get isFormGroupInvalid(): boolean {
    return (
      this.contactCustomerFormGroup.invalid ||
      this.contacts === undefined ||
      this.contacts.length <= 0 ||
      this.isTimeSlotInvalid
    );
  }

  public get isTimeSlotInvalid(): boolean {
    return (
      this.contactCustomerFormGroup.controls['enumReason'].value == 'YES' &&
      this.contactCustomerFormGroup.controls['timeSlot'].value == null
    );
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  public get contacPersonIcon(): string {
    return WHIconENUM.UserIcon as string;
  }

  public get userIcon() {
    return WHIconENUM.UserIcon;
  }

  public get isContactsEmpty(): boolean {
    return this.contacts === undefined || this.contacts.length <= 0;
  }

  public get timeSlotFormControl(): FormControl {
    return this.contactCustomerFormGroup.controls['timeSlot'] as FormControl;
  }

  public get dateSlotForm(): string {
    return this.tempFormGroup.controls['dateSlotForm'].value as string;
  }

  public get timeSlotForm(): string {
    return this.tempFormGroup.controls['timeSlotForm'].value as string;
  }

  public get enumReason(): string {
    return this.contactCustomerFormGroup.controls['enumReason'].value as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
