// ANGULAR
import { Component, Input, ChangeDetectorRef, SimpleChanges, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';

// SERVICES
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { WHAssetsENUM, WHDataService } from '@workheld/workheld-shared-lib';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import { WHWorkerDOM, IWHWorkerDTO } from '@workheld/workheld-shared-lib';
import { Router } from '@angular/router';

@Component({
  selector: 'ng-bee-worker-static-data',
  templateUrl: './worker-static-data.component.html',
  styleUrls: ['./worker-static-data.component.scss']
})
export class WorkerStaticDataComponent implements OnChanges, OnDestroy {

  // INPUT
  // GENERAL STATE
  @Input() public isSimple: boolean = false;
  @Input() public isRemoveable: boolean = false;
  @Output() public onRemoveClick: EventEmitter<boolean> = new EventEmitter();
  @Input() public detailURL: string;
  @Input() public withNameLabel: boolean = false;
  @Input() public nameLabel: string = 'worker.model.root';
  @Input() public withDetailPageNav: boolean = false;
  @Input() public showLoginInfo: boolean = false;
  @Input() public workerId: string = '';
  public workerDOM: WHWorkerDOM;

  // AVATAR
  @Input() public isAvatar: boolean = false;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    console.log("CHANGES IN CARD !!!!!!");
    
    if (changes && changes.detailURL && changes.detailURL.currentValue) {
      this.subscriptions.push(this.dataService.getByUrl(changes.detailURL.currentValue)
        .subscribe((workerDTO: IWHWorkerDTO) => {
          if(!this.showLoginInfo) {
            this.workerDOM = this.dataModelHelperService.initWorkerDOM(workerDTO);
          } else {
            this.workerDOM = {} as any;
            this.workerDOM['smallerAvatarURL'] = (workerDTO as any).avatarUrl;
            this.workerDOM['name'] = (workerDTO as any).firstname + ' ' + (workerDTO as any).lastname;
          }
          this.changeDetectorRef.detectChanges();
        })
      );
    }
  }

  navigate() {
    if(this.workerId){
      this.router.navigate(['/workers', this.workerId])
    }
  }

  public removeChip() {
    this.onRemoveClick.emit(true);
  }

  public get personImgJPG(): string {
    return WHAssetsENUM.personImgJPG as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
