import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Observable,
  Subscription,
  catchError,
  map,
  tap,
  throwError,
} from 'rxjs';
import {
  ShiftGroupDataService,
  createShiftModelFormGroup,
  validateOverlappingTimesArrayGroup,
  updateShiftModelFormGroup,
  validateNamesArrayGroup,
} from 'src/app/app-services-async/shift-group-data.service';
import {
  IWHShiftGroupDTO,
  IWHShiftModelDTO,
  WHNgxToastrENUM,
  WHNgxToastrService,
  materialStatusIdEnumArray,
  DateTimeService,
  noWhitespaceValidator,
} from '@workheld/workheld-shared-lib';
import { FormReferenceService } from 'src/app/app-services-helper/form-reference.service';
import { deriveTimeFormat } from 'time-input-formatter';

@Component({
  selector: 'app-mat-dialog-add-shift-group',
  templateUrl: './mat-dialog-add-shift-group.component.html',
  styleUrls: ['./mat-dialog-add-shift-group.component.scss'],
})
export class MatDialogAddShiftGroupComponent implements OnInit, OnDestroy {
  loading$: Observable<boolean>;

  statusIdEnumArray: string[] = materialStatusIdEnumArray;
  timeIntervals: string[] = [];

  displayedColumns: string[] = ['name', 'status', 'startTime', 'endTime'];
  shiftGroupFormGroup = this._formBuilder.group({
    extId: [''],
    status: ['ACTIVE', Validators.required],
    name: ['', [Validators.required, noWhitespaceValidator]],
  });

  shiftModelsFormGroup: FormGroup = this._formBuilder.group({
    shiftModels: this._formBuilder.array(
      [],
      [validateOverlappingTimesArrayGroup(), validateNamesArrayGroup()]
    ),
  });

  private subscriptions: Subscription[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private formReferenceService: FormReferenceService,
    private shiftGroupService: ShiftGroupDataService,
    private dateTimeHelperService: DateTimeService,

    private ngxToastrService: WHNgxToastrService,
    public matDialogRef: MatDialogRef<MatDialogAddShiftGroupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shiftGroup: IWHShiftGroupDTO;
    }
  ) {}

  ngOnInit(): void {
    this.loading$ = this.shiftGroupService.data.loading$;
    if (this.data.shiftGroup) {
      this.shiftGroupFormGroup
        .get('extId')
        .setValue(this.data.shiftGroup.extId);
      this.shiftGroupFormGroup.get('extId').disable();
      this.shiftGroupFormGroup
        .get('status')
        .setValue(this.data.shiftGroup.status);
      this.shiftGroupFormGroup.get('name').setValue(this.data.shiftGroup.name);

      if (this.data.shiftGroup.shiftModelsList !== undefined) {
        this.data.shiftGroup.shiftModelsList.forEach((shiftModel) => {
          this.shiftModels.push(updateShiftModelFormGroup(shiftModel));
        });
      }
    }

    this.timeIntervals =
      this.dateTimeHelperService.initTimeOptionDropdownArray(30);

    this.subscriptions.push(
      this.shiftGroupFormGroup.valueChanges.subscribe((_) => {
        this.formReferenceService.unsavedChanges =
          this.shiftGroupFormGroup.dirty;
      })
    );
  }

  onBlur(event, index: number, timeType: string) {
    const control = this.shiftModels.at(index).get(timeType);
    if (control) {
      const formattedTime = deriveTimeFormat(control.value, '24hm');
      if (formattedTime.valid) {
        control.setValue(formattedTime.value);
      }
    }
  }

  confirm() {
    const status = this.shiftGroupFormGroup.get('status').value as
      | 'ACTIVE'
      | 'INACTIVE';

    //add shiftGroupId to shiftModels
    const shiftModelList = structuredClone(
      this.shiftModelsFormGroup.get('shiftModels').value as IWHShiftModelDTO[]
    );

    const shiftGroup: IWHShiftGroupDTO = {
      id: this.data.shiftGroup ? this.data.shiftGroup.id : '',
      extId: this.shiftGroupFormGroup.get('extId').value,
      status: status,
      name: this.shiftGroupFormGroup.get('name').value,
      shiftModelsList: shiftModelList,
    };

    this.shiftGroupService
      .createOrUpdateShiftGroup(shiftGroup)
      .pipe(
        map((data: IWHShiftGroupDTO) => {
          this.matDialogRef.close(shiftGroup);

          const successMessage = this.data.shiftGroup
            ? 'shiftgroup.ui.updatesuccess.notification'
            : 'shiftgroup.ui.createsuccess.notification';

          this.shiftGroupService.data._loading.next(false);
          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey: successMessage,
          });
        }),
        catchError((err) => {
          this.shiftGroupService.data._loading.next(false);
          return throwError(() => err);
        })
      )
      .subscribe();
  }

  cancel() {
    if (this.shiftGroupFormGroup.dirty || this.shiftModelsFormGroup.dirty) {
      const ref = this.formReferenceService.createDialog();
      ref
        .pipe(
          tap((canDiscard) => {
            if (canDiscard) {
              this.matDialogRef.close(false);
              this.formReferenceService.unsavedChanges = false;
            }
          })
        )
        .subscribe();
    } else {
      this.matDialogRef.close(false);
    }
  }

  addShiftModel() {
    this.shiftModels.push(createShiftModelFormGroup());
  }

  deleteShiftModel(modelIndex: number) {
    this.shiftModels.removeAt(modelIndex);
  }

  formsAreValid(): boolean {
    return this.shiftGroupFormGroup.valid && this.shiftModelsFormGroup.valid;
  }

  get shiftModels(): FormArray {
    return this.shiftModelsFormGroup.get('shiftModels') as FormArray;
  }

  getModelByIndex(index: number): FormControl {
    return this.shiftModels.at(index) as FormControl;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
