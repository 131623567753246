// ANGULAR
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// RxJS 6
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

// API
import {
  IWHFlowContactDTO,
  WHIconENUM,
  WHMetadataDataService,
  IWHFlowMetadataTranslationModel,
  IWHConfigModel,
} from '@workheld/workheld-shared-lib';
import { FormReferenceService } from 'src/app/app-services-helper/form-reference.service';
import {
  Country,
  countryCodes,
} from 'src/assets/countrycodes/country-data-store';
import { initContactFormGroup } from 'src/app/app-services/form.service';
import { sortEnumObject } from 'src/app/app-shared/helpers/enumSort.helper';

@Component({
  selector: 'mat-dialog-contact-entry',
  templateUrl: './mat-dialog-contact-entry.component.html',
  styleUrls: ['./mat-dialog-contact-entry.component.scss'],
})
export class MatDialogContactEntryComponent implements OnInit, OnDestroy {
  contactDTO: IWHFlowContactDTO;
  contactFormGroup: FormGroup;
  countryCodes: Country[] = countryCodes.sort((a, b) => {
    const A = parseInt(a.dial_code.replace(/\D/g, ''));
    const B = parseInt(b.dial_code.replace(/\D/g, ''));
    return A - B;
  });
  CONTACT_SALUTATION: IWHConfigModel[] = [];
  private subscription: Subscription = new Subscription();

  constructor(
    public matDialogRef: MatDialogRef<MatDialogContactEntryComponent>,
    private metadataDataService: WHMetadataDataService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contactDTO: IWHFlowContactDTO;
    },
    private formReferenceService: FormReferenceService
  ) {}

  ngOnInit(): void {
    this.contactFormGroup = initContactFormGroup();
    if (this.data.contactDTO) {
      this.contactDTO = this.data.contactDTO;
      this.contactFormGroup.patchValue(this.data.contactDTO);
      this.contactFormGroup.controls['landLinePhone'].markAllAsTouched();
      this.contactFormGroup.controls['mobilePhone'].markAllAsTouched();
    }
    this.subscription.add(
      this.metadataDataService.metadataTranslation$.subscribe(
        (metadataTranslation: IWHFlowMetadataTranslationModel) => {
          this.CONTACT_SALUTATION = [];
          if (metadataTranslation.CONTACT_SALUTATION) {
            Object.entries(sortEnumObject(metadataTranslation.CONTACT_SALUTATION)).forEach(
              (property: any[]) => {
                this.CONTACT_SALUTATION.push({
                  id: property[0],
                  translateKey: property[1],
                });
              }
            );
          }
        }
      )
    );

    this.contactFormGroup.valueChanges.subscribe((value) => {
      this.validatePhone(value);
      this.validateMobilePhone(value);
    });
  }

  private validatePhone(value: any) {
    if (value.countryCodeLandLine || value.landLinePhone) {
      if (!value.countryCodeLandLine || !value.landLinePhone) {
        this.contactFormGroup.setErrors({ invalidPhone: true });
      } else if (!/^\+(\d+)$/g.test(value.countryCodeLandLine)) {
        this.contactFormGroup.setErrors({ invalidMobilePhone: true });
      } else if (
        value.countryCodeLandLine &&
        value.landLinePhone &&
        this.contactFormGroup.errors?.invalidPhone
      ) {
        this.contactFormGroup.setErrors({ invalidPhone: null });
        this.contactFormGroup.updateValueAndValidity();
      }
    }
  }

  private validateMobilePhone(value) {
    if (value.countryCodeMobile || value.mobilePhone) {
      if (!value.countryCodeMobile || !value.mobilePhone) {
        this.contactFormGroup.setErrors({ invalidMobilePhone: true });
      } else if (!/^\+(\d+)$/g.test(value.countryCodeMobile)) {
        this.contactFormGroup.setErrors({ invalidMobilePhone: true });
      } else if (
        value.countryCodeMobile &&
        value.mobilePhone &&
        this.contactFormGroup.errors?.mobilePhone
      ) {
        this.contactFormGroup.setErrors({ invalidMobilePhone: null });
        this.contactFormGroup.updateValueAndValidity();
      }
    }
  }

  public handleSubmit(): void {
    if (this.contactFormGroup.invalid) {
      return;
    }
    this.matDialogRef.close(this.contactFormGroup.value);
  }

  public deleteContact(): void {
    const contactId = this.contactFormGroup.controls['id'].value;
    this.matDialogRef.close(contactId);
  }

  public closeDialog() {
    if (this.contactFormGroup.dirty) {
      const ref = this.formReferenceService.createDialog();
      this.subscription.add(
        ref
          .pipe(
            tap((canDiscard) => {
              if (canDiscard) {
                this.matDialogRef.close();
              }
            })
          )
          .subscribe()
      );
    } else {
      this.matDialogRef.close();
    }
  }

  public get firstname(): string {
    return this.contactFormGroup.controls['firstname'].value as string;
  }
  public get lastname(): string {
    return this.contactFormGroup.controls['lastname'].value as string;
  }

  public get contactFormGroupDisabled(): boolean {
    return this.contactFormGroup.disabled as boolean;
  }

  public get contactFormGroupInvalid(): boolean {
    return this.contactFormGroup.invalid as boolean;
  }

  public get userIcon() {
    return WHIconENUM.UserIcon;
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  public get deleteIcon(): string {
    return WHIconENUM.DeleteIcon as string;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
