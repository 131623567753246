// HELPER
import {
  sortBySortPosition,
  WHRouteZeroENUM,
  WHIconENUM,
  IWHChecklistSectionWithCheckDTO
} from '@workheld/workheld-shared-lib';

// ANGULAR
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';

// ANGULAR MATERIAL

// SERVICES
import { ChecklistDefinitionAsyncService } from 'src/app/app-services-async/checklist-definition-async.service';
import { AppDialogService } from 'src/app/app-dialogs/app-dialog.service';

// RxJS 6
import { Store } from '@ngrx/store';
import { IAppStore } from 'src/app/app-stores/index.stores';
import { workheldChecklistDefinitionCrudStoreFeatureKey } from 'src/app/app-stores/w-h-checklist-definition-stores/w-h-checklist-definition-crud-store/w-h-checklist-definition-crud-store.reducer';

// RxJS 6
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// MODELS
import { IWHUIConfigModel } from '@workheld/workheld-shared-lib';
import { WHChecklistDefinitionDOM, IWHCheckDefinitionDTO } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-checklist-definition-detail',
  templateUrl: './checklist-definition-detail.component.html',
  styleUrls: ['./checklist-definition-detail.component.scss']
})
export class ChecklistDefinitionDetailComponent implements OnInit, OnDestroy {

  // ENUMS
  @Input() public MEASUREMENT_TYPE: IWHUIConfigModel[] = [];
  @Input() public UNIT: IWHUIConfigModel[] = [];

  // STATE VAR
  public isCreateOrEdit: boolean = false;
  public deleting: boolean = false;
  @Input() public descriptionRows: number = 4;

  // DOM STATE
  @Input() public displayHeader: boolean = true;
  @Input() public displayHeaderInBody: boolean = false;
  @Input() public displayActionButtons: boolean = true;
  @Input() public isExpanded: boolean;

  // OUTPUT
  @Output() public onCrudModeChange: EventEmitter<'create' | 'update' | 'duplicate'> = new EventEmitter<'create' | 'update' | 'duplicate'>();
  @Output() public onEditClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public onExpandedViewChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // DATA VAR
  public checklistDefinitionDOM: WHChecklistDefinitionDOM;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private store: Store<IAppStore>,
    private checklistDefinitionAsyncService: ChecklistDefinitionAsyncService,
    private appDialogService: AppDialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  toggleExpand() {
    this.isExpanded = !this.isExpanded
    this.onExpandedViewChange.emit(this.isExpanded)
  }

  ngOnInit() {
    this.subscriptions.push(this.store.select(workheldChecklistDefinitionCrudStoreFeatureKey).subscribe((checklistDefinitionDOM: WHChecklistDefinitionDOM) => {
      this.checklistDefinitionDOM = checklistDefinitionDOM;
      if (checklistDefinitionDOM && checklistDefinitionDOM.checkDefinitions) {
        this.checklistDefinitionDOM.checkDefinitions = sortBySortPosition(checklistDefinitionDOM.checkDefinitions);
      }
      this.changeDetectorRef.detectChanges();
    }));
  }

  public editChecklistDefinition(): void {
    this.onCrudModeChange.emit('update');
    this.onEditClick.emit(true);
  }

  public async deleteChecklistDefinition(): Promise<void> {
    const confirm = await this.appDialogService.openConfirmMatDialog(this.checklistDefinitionDOM.status === 'ACTIVE' ? 'deactivatechecklistdefinition' : 'activatechecklistdefinition')
      .afterClosed()
      .toPromise()
      .then((res) => {
        return res;
      });

    if (!confirm) { return; }

    this.deleting = true;
    this.changeDetectorRef.detectChanges();
    if (this.checklistDefinitionDOM.status === 'ACTIVE') {
      this.subscriptions.push(this.checklistDefinitionAsyncService.deleteChecklistDefinitionByChecklistDefinitionId(this.checklistDefinitionDOM.id)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.deleting = false;
            this.changeDetectorRef.detectChanges();
            return throwError(error);
          })
        )
        .subscribe((res: any) => {
          // console.log(res);
          this.deleting = false;
          this.checklistDefinitionDOM.status = 'INACTIVE';
          this.changeDetectorRef.detectChanges();
          this.backToOverview();
        }));
    } else {
      const payload = {
        status: 'ACTIVE'
      };
      this.deleting = true;
      this.subscriptions.push(this.checklistDefinitionAsyncService.updateChecklistDefinitionByChecklistDefinitionDTO(this.checklistDefinitionDOM.id, payload as any)
        .subscribe(() => {
          this.checklistDefinitionDOM.status = 'ACTIVE';
          this.deleting = false;
          this.changeDetectorRef.detectChanges();
        }));
    }
  }

  public backToOverview() {
    if (history.state.data && !!history.state.data.page) {
      this.router.navigate(['/', WHRouteZeroENUM.CHECKLIST_DEFINITION], { queryParams: { page: history.state.data.page } });
    } else {
      this.router.navigate(['/', WHRouteZeroENUM.CHECKLIST_DEFINITION]);
    }
  }

  public async copyChecklistDefinition() {
    const confirm = await this.appDialogService.openConfirmMatDialog('duplicatechecklistdefinition')
      .afterClosed()
      .toPromise()
      .then((res) => {
        return res;
      });

    if (!confirm) { return; }
    this.onCrudModeChange.emit('duplicate');
    this.onEditClick.emit(true);
  }

  public get checkDefinitionArray(): Array<IWHCheckDefinitionDTO> {
    return this.checklistDefinitionDOM && this.checklistDefinitionDOM.checkDefinitions ? this.checklistDefinitionDOM.checkDefinitions : [];
  }

  public get sectionsArray(): Array<IWHChecklistSectionWithCheckDTO> {
    return this.checklistDefinitionDOM && this.checklistDefinitionDOM.sections ? this.checklistDefinitionDOM.sections : [];
  }

  public getSectionChecks(sectionDOM: IWHChecklistSectionWithCheckDTO): IWHCheckDefinitionDTO[] {
    return sectionDOM.checkDefinitions ? sectionDOM.checkDefinitions : [];
  }

  public get checklistDefinitionIcon(): string {
    return WHIconENUM.ChecklistDefinitionIcon as string;
  }

  public get editIcon(): string {
    return WHIconENUM.EditIcon as string;
  }

  public get backIcon(): string {
    return WHIconENUM.BackIcon as string;
  }

  public get copyChecklistDefinitionIcon(): string {
    return WHIconENUM.CopyChecklistDefinitionIcon as string;
  }

  public get deleteIcon(): string {
    return WHIconENUM.DeleteIcon as string;
  }

  public get activateIcon(): string {
    return 'activate' as string;
  }
  public get deactivateIcon(): string {
    return 'deactivate' as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
