// ANGULAR
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

// API
import {
  IWHFlowMetadataTranslationModel,
  IWHUIConfigModel,
  WHIconENUM,
  WHLoginDataService,
  WHMetadataDataService
} from '@workheld/workheld-shared-lib';
import { sortEnumObject } from 'src/app/app-shared/helpers/enumSort.helper';

@Component({
  selector: 'mat-dialog-workobject-pause-resume-entry',
  templateUrl: './mat-dialog-workobject-pause-resume-entry.component.html',
  styleUrls: ['./mat-dialog-workobject-pause-resume-entry.component.scss']
})
export class MatDialogWorkObjectPauseResumeEntryComponent implements OnInit, OnDestroy {
  // ENUMS
  public PAUSE_REASON: IWHUIConfigModel[] = [];
  public RESUME_REASON: IWHUIConfigModel[] = [];

  // VAR
  public paylaodFormGroup: FormGroup = new FormBuilder().group({
    comment: [null],
    reason: [null, Validators.required]
  });

  // STATE
  public isWorkObjectPaused: boolean;

  // SUBSCRIPTION
  private subscriptions: Subscription[] = [];

  constructor(
    public matDialogRef: MatDialogRef<MatDialogWorkObjectPauseResumeEntryComponent>,
    private metadataDataService: WHMetadataDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private loginDataService: WHLoginDataService,
    @Inject(MAT_DIALOG_DATA) public data: {
      isWorkObjectPaused: boolean
      },
  ) { }

  ngOnInit(): void {
    this.isWorkObjectPaused = this.data.isWorkObjectPaused;

    // WH ENUMS
    this.subscriptions.push(this.metadataDataService.metadataTranslation$.subscribe((metadataTranslation: IWHFlowMetadataTranslationModel) => {
      this.PAUSE_REASON = [];
      metadataTranslation.STOP_CAUSE ? Object.entries(sortEnumObject(metadataTranslation.STOP_CAUSE)).forEach((property: any[]) => {
        this.PAUSE_REASON.push({
          featureKey: property[0],
          enabled: true,
          translateKey: property[1]
        });
      }) : console.log();
      this.changeDetectorRef.detectChanges();
    }));
    this.subscriptions.push(this.metadataDataService.metadataTranslation$.subscribe((metadataTranslation: IWHFlowMetadataTranslationModel) => {
      this.RESUME_REASON = [];
      metadataTranslation.RESUME_CAUSE ? Object.entries(sortEnumObject(metadataTranslation.RESUME_CAUSE)).forEach((property: any[]) => {
        this.RESUME_REASON.push({
          featureKey: property[0],
          enabled: true,
          translateKey: property[1]
        });
      }) : console.log();
      this.changeDetectorRef.detectChanges();
    }));
  }

  public handleSubmit(): void {
    this.confirmNewStateWorkObject();
  }

  private confirmNewStateWorkObject() {
    const userName: string = this.loginDataService.activeUserDOM$.value.formattedName + ': ';

    const payload: any = this.paylaodFormGroup.value;
    payload.comment = userName + payload.reason + (payload.comment ? ', ' + payload.comment : '');

    this.matDialogRef.close(payload.comment);
  }

  public get isFormGroupInvalid(): boolean {
    return (this.paylaodFormGroup.invalid) as boolean;
  }

  public get workObjectPaused(): boolean {
    return this.isWorkObjectPaused;
  }

  public closeDialog(): void {
    this.matDialogRef.close();
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
