<section class="work-step-mat-dialog">
  <div class="new_detail-card-header">
    <span>
      <div class="header-title-holder">
        <i
          mat-card-avatar
          class="workheld-icons"
          [innerHtml]="workStepIcon"
        ></i>
        <h2>
          <b>{{ workStepExtId }} - </b>{{ workStepTitle }}
        </h2>
      </div>
    </span>
    <span class="text-right">
      <ng-bee-w-h-status-bar-util
        [statusLabel]="'workstep.enum.status.'"
        [isActive]="false"
        [statusArray]="workStepStatusArray"
        [workObjectStatusId]="workStepDOM?.calculatedWorkStepStatusId"
      >
      </ng-bee-w-h-status-bar-util>
    </span>
  </div>

  <div class="work-step-mat-dialog-body">
    <form
      [formGroup]="workStepFormGroup"
      class="flex--r-fs-c gap--8 flex--wrap"
    >
      <div class="flex--col flex-1">
        <mat-form-field>
          <mat-label>{{ "workstep.model.title" | translate }}</mat-label>
          <input
            data-cy="title"
            matInput
            formControlName="title"
            required
            autocomplete="off"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "workstep.model.extid" | translate }}</mat-label>
          <input
            style="margin-top: 2px"
            matInput
            formControlName="extId"
            autocomplete="off"
          />
        </mat-form-field>
        <mat-form-field *ngIf="workStepDOM">
          <mat-label>{{
            "workstep.model.resolutionStatus" | translate
          }}</mat-label>
          <input
            matInput
            [value]="getResolutionStatus(resolutionStatus)"
            [disabled]="true"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "workstep.model.description" | translate }}</mat-label>
          <textarea [rows]="3" matInput formControlName="description">
          </textarea>
        </mat-form-field>
        <div *ngIf="workStepDOM">
          <div class="w-h-label assignment-label c-h-label">
            {{ "checklistdefinition.model.root" | translate }}
          </div>
          <div class="assignment-column checklistdefinition-column">
            <ng-container
              *ngIf="workStepDOM?.checklistDefinitionId; else noChecklist"
            >
              <ng-bee-w-h-work-step-assignment-chip
                [targetParent]="'checklistdefinition'"
                [detailURL]="workStepDOM?.checklistDefinitionDetailURL"
                (onRemoveClick)="removeAssignment($event)"
                [isDisabled]="
                  workStepDOM.calculatedWorkStepStatusId > 0 || !workStepActive
                "
              >
              </ng-bee-w-h-work-step-assignment-chip>
            </ng-container>
            <ng-template #noChecklist>
              {{
                "workstep.ui.selectchecklistdefinition.helpertext" | translate
              }}
            </ng-template>
          </div>
        </div>
      </div>

      <div class="flex-1" [ngClass]="{ 'flex--c-c-fs': workStepDOM }">
        <div class="flex--col flex-1">
          <div *ngIf="tempFormGroup" style="max-width: 420px">
            <!-- Start and End Date -->
            <lib-w-h-datetime-picker
              [parentFormGroup]="tempFormGroup"
              startDateForm="dateSlotForm"
              startTimeForm="timeSlotForm"
              endDateForm="enddateSlotForm"
              endTimeForm="endtimeSlotForm"
              [currentStartDate]="dateSlotForm"
              [currentEndDate]="enddateSlotForm"
              [hasTimer]="true"
              [hasEndDate]="true"
              [futureDate]="true"
              [isDisabled]="workStepFormGroupDisabled && !workStepActive"
              [startDateLabel]="'workstep.model.startdate'"
              [endDateLabel]="'workstep.model.enddate'"
              (onResetDate)="resetDateField($event)"
            ></lib-w-h-datetime-picker>
          </div>
          <!-- Planned Working Time -->
          <div class="planned-work-container" *ngIf="hasWorkingTimeEnabled">
            <mat-label class="w-h-label" style="margin-bottom: 0px">{{
              "workstep.model.plannedworkingtime" | translate
            }}</mat-label>
            <div class="flex--row flex-1 gap--8">
              <mat-form-field>
                <mat-label>{{
                  "workstep.model.setuptime" | translate
                }}</mat-label>
                <input
                  type="number"
                  matInput
                  class="text-right"
                  formControlName="setupTimeMinutes"
                  autocomplete="off"
                />
                <div
                  *ngIf="workStepFormGroup.get('setupTimeMinutes').value > 0"
                  matSuffix
                  class="p-r--8"
                >
                  min
                </div>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{
                  "workstep.model.workingtime" | translate
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  class="text-right"
                  formControlName="workingTimeMinutes"
                  autocomplete="off"
                />
                <div
                  *ngIf="workStepFormGroup.get('workingTimeMinutes').value > 0"
                  matSuffix
                  class="p-r--8"
                >
                  min
                </div>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{
                  "workstep.model.teardowntime" | translate
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  class="text-right"
                  formControlName="teardownTimeMinutes"
                  autocomplete="off"
                />
                <div
                  *ngIf="workStepFormGroup.get('teardownTimeMinutes').value > 0"
                  matSuffix
                  class="p-r--8"
                >
                  min
                </div>
              </mat-form-field>
            </div>
          </div>
          <!-- Urgent Order -->
          <div *ngIf="isUrgentOrderEnabled">
            <mat-checkbox formControlName="urgent">
              <mat-label class="w-h-label" style="margin-bottom: 0px">{{
                "workstep.model.urgent" | translate
              }}</mat-label>
            </mat-checkbox>
          </div>
        </div>
      </div>
      <!-- Assignment List for Equipment, Technician, Tools and Skills -->
      <div class="assigned-items-container" *ngIf="workStepDOM">
        <span>
          <div class="w-h-label assignment-label p-t--8">
            {{ "equipment.model.root" | translate }}
          </div>
          <ng-container
            *ngIf="
              workStepDOM?.equipmentId || workStepDOM?.bomNodeId;
              else noEquipment
            "
          >
            <ng-bee-w-h-work-step-assignment-chip
              *ngIf="workStepDOM?.bomNodeId"
              [targetParent]="'equipmentNode'"
              [detailURL]="workStepDOM?.bomNodeDetailURL"
              (onRemoveClick)="removeAssignment($event)"
              [isDisabled]="!workStepActive"
            >
            </ng-bee-w-h-work-step-assignment-chip>
            <ng-bee-w-h-work-step-assignment-chip
              *ngIf="workStepDOM?.equipmentId"
              [targetParent]="'equipment'"
              [detailURL]="workStepDOM?.equipmentDetailURL"
              (onRemoveClick)="removeAssignment($event)"
              [isDisabled]="!workStepActive"
            >
            </ng-bee-w-h-work-step-assignment-chip>
          </ng-container>
          <ng-template #noEquipment>
            {{ "equipment.model.noequipment" | translate }}
          </ng-template>
        </span>
        <span>
          <div class="w-h-label assignment-label p-t--8">
            {{ "worker.model.root" | translate }}
          </div>
          <div>
            <ng-container
              *ngIf="workStepDOM?.workers.length > 0; else noWorkers"
            >
              <ng-bee-w-h-work-step-assignment-chip
                *ngFor="
                  let employeeDetailURL of workStepDOM?.employeeDetailURLs
                "
                [detailURL]="employeeDetailURL"
                [targetParent]="'worker'"
                (onRemoveClick)="removeAssignment($event)"
                [isDisabled]="!workStepActive"
              >
              </ng-bee-w-h-work-step-assignment-chip>
            </ng-container>
            <ng-template #noWorkers>
              {{ "worker.model.noworkers" | translate }}
            </ng-template>
          </div>
        </span>
        <span>
          <div class="w-h-label assignment-label p-t--8">
            {{ "tool.model.root" | translate }}
          </div>
          <div>
            <ng-container *ngIf="workStepDOM?.tools.length > 0; else noTools">
              <ng-bee-w-h-work-step-assignment-chip
                *ngFor="let toolDetailsURL of workStepDOM?.toolDetailsURLs"
                [targetParent]="'tool'"
                [detailURL]="toolDetailsURL"
                (onRemoveClick)="removeAssignment($event)"
                [isDisabled]="!workStepActive"
              >
              </ng-bee-w-h-work-step-assignment-chip>
            </ng-container>
            <ng-template #noTools>
              {{ "tool.model.notools" | translate }}
            </ng-template>
          </div>
        </span>
        <span *ngIf="hasSkillManagementEnabled">
          <div class="w-h-label assignment-label p-t--8">
            {{ "nav.skills.root" | translate }}
          </div>
          <div>
            <ng-container
              *ngIf="workStepDOM?.skills?.length > 0; else noSkills"
            >
              <app-w-h-chip-list
                *ngFor="let skill of workStepDOM?.skills"
                [data]="[skill]"
                [tooltipKeys]="['name']"
                [titleKey]="'name'"
                [isRemoveButtonVisible]="true"
                (handleRemoveChip)="unassignSkill(skill.id)"
              ></app-w-h-chip-list>
            </ng-container>
            <ng-template #noSkills>
              {{ "skills.model.noskills" | translate }}
            </ng-template>
          </div>
        </span>
      </div>
    </form>
  </div>

  <div class="dialog-action-wrapper" mat-dialog-actions>
    <button
      *ngIf="workStepDOM?.id"
      class="w-h-default-button dialog-button mat-elevation-z2"
      [disabled]="
        workStepDOM?.calculatedWorkStepStatusId > 0 ||
        (workStepFormGroupDisabled && !workStepActive) ||
        saving
      "
      mat-flat-button
      type="button"
      tabindex="-1"
      (click)="deleteWorkStep()"
    >
      <i class="workheld-icons" [innerHTML]="deleteIcon"></i>
      {{ "app.delete.label" | translate | uppercase }}
    </button>
    <button
      class="w-h-default-button dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      (click)="closeDialog()"
      [disabled]="saving"
    >
      <i class="workheld-icons" [innerHTML]="cancelIcon"></i>
      {{ "app.cancel.label" | translate | uppercase }}
    </button>
    <button
      data-cy="save-btn"
      color="accent"
      [disabled]="
        workStepFormGroupInvalid ||
        (workStepFormGroupDisabled && !workStepActive) ||
        saving
      "
      class="dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      (click)="handleSubmit()"
    >
      <mat-spinner style="display: inline-block" *ngIf="saving" [diameter]="18">
      </mat-spinner>
      <i class="workheld-icons" *ngIf="!saving" [innerHTML]="saveIcon"></i>
      {{ "app.save.label" | translate | uppercase }}
    </button>
  </div>
</section>
