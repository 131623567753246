// HELPER
import {
  IWHFlowMetadataTranslationModel,
  updateObject,
  WHMetadataDataService,
} from '@workheld/workheld-shared-lib';

// ANGULAR
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';

// SERVICES
import { WHDataService } from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import {
  WHEntryDOM,
  IWHMeasurementEntryDTO,
  WHMeasurementEntryDOM,
} from '@workheld/workheld-shared-lib';
import { sortEnumObject } from 'src/app/app-shared/helpers/enumSort.helper';

@Component({
  selector: 'ng-bee-type6-measurment-entry-static-data',
  templateUrl: './type6-measurment-entry-static-data.component.html',
  styleUrls: ['./type6-measurment-entry-static-data.component.scss'],
})
export class Type6MeasurmentEntryStaticDataComponent
  implements OnInit, OnDestroy
{
  // ENUMS
  public MEASUREMENT_TYPE: any;
  public UNIT: any;

  // DATA VAR
  @Input() public entryDOM: WHEntryDOM;
  public measurmentEntryDOM: WHMeasurementEntryDOM = {
    loading: true,
    loaded: false,
  } as any;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];
  measurmentValue: any;

  constructor(
    private metadataDataService: WHMetadataDataService,
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // ENUMS
    this.subscriptions.push(
      this.metadataDataService.metadataTranslation$.subscribe(
        (metadataTranslation: IWHFlowMetadataTranslationModel) => {
          this.MEASUREMENT_TYPE = sortEnumObject(
            metadataTranslation.MEASUREMENT_TYPE
          );
          this.UNIT = sortEnumObject(metadataTranslation.UNIT);
          this.changeDetectorRef.detectChanges();
        }
      )
    );

    this.subscriptions.push(
      this.dataService
        .getByUrl(this.entryDOM.entryDetailURL)
        .subscribe((measurmentEntryDTO: IWHMeasurementEntryDTO) => {
          this.measurmentEntryDOM = updateObject(
            this.measurmentEntryDOM,
            this.dataModelHelperService.initMeasurmentEntryDOM(
              measurmentEntryDTO
            )
          );

          this.measurmentValue = this._getValue();

          this.changeDetectorRef.detectChanges();
        })
    );
  }

  private _getValue(): any {
    const key = Object.keys(this.measurmentEntryDOM?.measurementValue)[0];
    return this.measurmentEntryDOM?.measurementValue[key];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
